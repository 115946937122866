export const commonLocalization = {
  'Project management': {
    eng: 'Project management',
    ru: 'Управление проектами',
    cn: '项目管理',
  },
  'Project overview': {
    eng: 'Project overview',
    ru: 'Обзор проекта',
    cn: '项目概括',
  },
  'TokensPage:TokensAndVariables': {
    eng: 'Secrets and variables',
    ru: 'Токены и переменные',
  },
  BotScorerPage: {
    eng: 'Bot quality evaluation',
    ru: 'Оценка качества бота',
  },
  'Project features': {
    eng: 'Project features',
    ru: 'Проектные фичи',
    cn: '项目功能',
  },
  'Project editing': {
    eng: 'Edit project',
    ru: 'Редактирование проекта',
    cn: '编辑项目',
  },
  'Project creation': {
    eng: 'New project',
    ru: 'Новый проект',
    cn: '创建项目',
  },
  'Project $[1] review': {
    eng: 'Project $[1] overview',
    ru: 'Обзор проекта $[1]',
    cn: '项目 $[1] 概况',
  },
  'Project template': {
    eng: 'Template',
    ru: 'Шаблон бота',
    cn: '模板',
  },
  'Project ID': {
    eng: 'Project ID',
    ru: 'ID проекта',
    cn: '项目ID',
  },
  'Project $[1] dialogs': {
    eng: 'Project $[1] dialogs',
    ru: 'Диалоги проекта $[1]',
    cn: '项目$[1]对话框',
  },
  'Project $[1] tasks': {
    eng: 'Project $[1] tasks',
    ru: 'Задачи проекта $[1]',
    cn: '项目$[1]任务',
  },
  'Project Description': {
    eng: 'Description',
    ru: 'Описание',
    cn: '描述',
  },
  'Project Properties': {
    eng: 'Project properties',
    ru: 'Свойства проекта',
    cn: '项目属性',
  },
  'project initial code': {
    eng: 'Initial code',
    ru: 'Код проекта',
  },
  'Project location': {
    eng: 'Project location',
    ru: 'Размещение проекта',
  },
  Title: {
    eng: 'Title',
    ru: 'Название',
    cn: '标题',
  },
  'Symbol code': {
    eng: 'Character code',
    ru: 'Символьный код',
    cn: '符号码',
  },
  'Add project': {
    eng: 'Create project',
    ru: 'Создать проект',
    cn: '创建项目',
  },
  'Add account': {
    eng: 'Add account',
    ru: 'Добавить аккаунт',
    cn: '添加账户',
  },
  'Add locale': {
    eng: 'Add locale',
    ru: 'Добавить локализацию',
    cn: '添加定位',
  },
  'Add comment': {
    eng: 'Add comment',
    ru: 'Добавить комментарий',
    cn: '添加评价',
  },
  'Add tags': {
    eng: 'Add labels',
    ru: 'Добавить метки',
    cn: '添加标签',
  },
  'Add a tag group': {
    eng: 'Add label group',
    ru: 'Добавить группу меток',
    cn: '添加标签组',
  },
  'Add user': {
    eng: 'Add user',
    ru: 'Добавить пользователя',
    cn: '添加用户',
  },
  'Add class': {
    eng: 'Add class',
    ru: 'Добавить класс',
    cn: '添加类',
  },
  'add more': {
    eng: 'add more',
    ru: 'еще поле',
    cn: '添加更多',
  },
  'add key': {
    eng: 'Add key',
    ru: 'Добавить ключ',
    cn: '添加密钥',
  },
  'add trigger': {
    eng: 'Add trigger',
    ru: 'Добавить триггер',
    cn: '添加触发器',
  },
  'Add group': {
    eng: 'Add group',
    ru: 'Добавить группу',
    cn: '添加组',
  },
  'Add folder': {
    eng: 'Add folder',
    ru: 'Добавить папку',
    cn: '添加文件夹',
  },
  'Add file': {
    eng: 'Add file',
    ru: 'Добавить файл',
    cn: '添加文件',
  },
  'Add call list': {
    eng: 'Add a list of phone numbers',
    ru: 'Создать список номеров',
    cn: '添加电话号码列表',
  },
  'Add numbers': {
    eng: 'Add phone numbers',
    ru: 'Добавить номера',
    cn: '添加电话号码',
  },
  'Add heading field': {
    eng: 'Add header',
    ru: 'Добавить поле заголовка',
    cn: '添加标题',
  },
  'Add call task': {
    eng: 'Create call campaign',
    ru: 'Создать обзвон',
    cn: '创建一个活动',
  },
  'Add users': {
    eng: 'Add users',
    ru: 'Добавить пользователей',
    cn: '添加用户',
  },
  'Add package': {
    eng: 'Activate package',
    ru: 'Подключить пакет',
    cn: '激活包',
  },
  'Add minutes': {
    eng: 'Add minutes',
    ru: 'Добавить минуты',
    cn: '增加通话分钟',
  },
  'Add user pack': {
    eng: 'Add user package',
    ru: 'Добавление пакета пользователей',
    cn: '添加用户包',
  },
  'Add telephony minutes': {
    eng: 'Add telephony minutes',
    ru: 'Добавление минут телефонии',
    cn: '添加电话分钟数',
  },
  'Add client': {
    eng: 'Add client',
    ru: 'Добавить клиента',
    cn: '添加客户',
  },
  'Add field': {
    eng: 'Add field',
    ru: 'Добавить поле',
    cn: '添加字段',
  },
  'Add customer': {
    eng: 'Add customer',
    ru: 'Добавление клиента',
    cn: '添加客户',
  },
  'Feature type': {
    eng: 'Feature type',
    ru: 'Типы фич',
    cn: '功能类型',
  },
  'Feature name': {
    eng: 'Feature name',
    ru: 'Названия фич',
    cn: '功能名称',
  },
  'Partner status': {
    eng: 'Partner status',
    ru: 'Статус партнера',
    cn: '合作伙伴状态',
  },
  'Partner creation': {
    eng: 'Partner creation',
    ru: 'Создание партнера',
    cn: '创建合作伙伴',
  },
  'Partner type': {
    eng: 'Partner type',
    ru: 'Тип партнера',
  },
  'Partner type JAICP_AND_AIMYLOGIC': {
    eng: 'JAICP and Aimylogic',
    ru: 'JAICP и Aimylogic',
  },
  'Partner type AIMYLOGIC': {
    eng: 'Aimylogic',
    ru: 'Aimylogic',
  },
  'Partner type JAICP': {
    eng: 'JAICP',
    ru: 'JAICP',
  },
  'Partner type UNDEFINED': {
    eng: 'Undefined',
    ru: 'Не определен',
  },
  'Partner payments': {
    eng: 'Partner payments',
    ru: 'Партнерские выплаты',
  },
  'Partner type undefined': {
    eng: 'Undefined',
    ru: 'Не определен',
  },
  'Users filter': {
    eng: 'User filter',
    ru: 'Фильтр по пользователю',
    cn: '用户筛选',
  },
  'Users list': {
    eng: 'User list',
    ru: 'Список пользователей',
    cn: '用户列表',
  },
  'Owner name': {
    eng: 'Owner name',
    ru: 'Имя владельца',
    cn: '所有者名称',
  },
  'Owner email': {
    eng: 'Owner email',
    ru: 'Email владельца',
    cn: '所有者Email',
  },
  'Owner login': {
    eng: 'Owner login',
    ru: 'Логин владельца',
    cn: '所有者登录',
  },
  Apply: {
    eng: 'Apply',
    ru: 'Применить',
    cn: '应用',
  },
  Drop: {
    eng: 'Discard',
    ru: 'Сбросить',
    cn: '丢弃',
  },
  User: {
    eng: 'User',
    ru: 'Пользователь',
    cn: '用户',
  },
  Actions: {
    eng: 'Actions',
    ru: 'Действия',
    cn: '操作',
  },
  Name: {
    eng: 'First name',
    ru: 'Имя',
    cn: '名字',
  },
  ProjectName: {
    eng: 'Name',
    ru: 'Имя',
    cn: '名字',
  },
  Surname: {
    eng: 'Last name',
    ru: 'Фамилия',
    cn: '姓',
  },
  'Is active': {
    eng: 'Active',
    ru: 'Активен',
    cn: '有效',
  },
  'Is open lines': {
    eng: 'Is open lines',
    ru: 'Открытые линии',
    cn: '是空行',
  },
  Partner: {
    eng: 'Partner',
    ru: 'Партнер',
    cn: '合作伙伴',
  },
  Next: {
    eng: 'Next',
    ru: 'След.',
    cn: '下一个',
  },
  'Prev.': {
    eng: 'Prev.',
    ru: 'Пред.',
    cn: '上一个',
  },
  'Adding an account': {
    eng: 'Add account',
    ru: 'Добавление аккаунта',
    cn: '添加账户',
  },
  'Adding class': {
    eng: 'Add class',
    ru: 'Добавление класса',
    cn: '添加类',
  },
  'Adding a key': {
    eng: 'Adding a key',
    ru: 'Добавить ключ',
    cn: '添加一个密钥',
  },
  'Adding a folder to a directory': {
    eng: 'Adding a folder to a directory ',
    ru: 'Добавление папки в раздел ',
    cn: '添加文件夹到目录',
  },
  'Adding a file to a directory': {
    eng: 'Adding a file to a directory ',
    ru: 'Добавление файла в раздел ',
    cn: '添加文件到目录',
  },
  System: {
    eng: 'System',
    ru: 'Система',
    cn: '系统',
  },
  Status: {
    eng: 'Status',
    ru: 'Статус',
    cn: '状态',
  },
  Monitoring: {
    eng: 'Monitoring',
    ru: 'Мониторинг',
    cn: '监控',
  },
  Accounts: {
    eng: 'Accounts',
    ru: 'Аккаунты',
    cn: '账户',
  },
  Account: {
    eng: 'Account',
    ru: 'Аккаунт',
    cn: '账户',
  },
  Projects: {
    eng: 'Projects',
    ru: 'Проекты',
    cn: '项目',
  },
  Project: {
    eng: 'Project',
    ru: 'Проект',
    cn: '项目',
  },
  Users: {
    eng: 'Users',
    ru: 'Пользователи',
    cn: '用户',
  },
  'Directories of tags': {
    eng: 'Label catalog',
    ru: 'Справочник меток',
    cn: '标签目录',
  },
  'Directories with names matching existing filenames are not allowed': {
    eng: 'Directories with names matching existing filenames are not allowed',
    ru: 'Нельзя создать директорию с совпадением имени с файлом',
    cn: '不允许与现有文件名匹配的目录名',
  },
  Channels: {
    eng: 'Channels',
    ru: 'Каналы',
    cn: '通道',
  },
  'Channels:noChannelsNotificationTitle': {
    eng: 'Deploy',
    ru: 'Публикация',
  },
  'Channels:noChannelsNotificationMessage': {
    eng: 'There are no channels to deploy into. Create one on the Channels page.',
    ru: 'Нет каналов для публикации ботов. Создайте хотя бы один канал на странице Каналы.',
  },
  Channel: {
    eng: 'Channel',
    ru: 'Канал',
    cn: '通道',
  },
  'Incoming channels': {
    eng: 'Inbound',
    ru: 'Входящие',
    cn: '呼入',
  },
  'Incoming channel': {
    eng: 'Inbound channel',
    ru: 'Входящий канал',
    cn: '呼入通道',
  },
  'Incoming secret': {
    eng: 'Incoming secret',
    ru: 'Входящий ключ',
    cn: '呼入密钥',
  },
  'Incoming calls': {
    eng: 'Inbound calls',
    ru: 'Входящие звонки',
    cn: '呼入',
  },
  'Incoming calls allowed': {
    eng: 'Inbound calls allowed',
    ru: 'Разрешены',
    cn: '允许的呼入',
  },
  'Incoming calls disallowed': {
    eng: 'Inbound calls not allowed',
    ru: 'Запрещены',
    cn: '禁止的呼入',
  },
  'Operators channels': {
    eng: 'Customer engagement platforms',
    ru: 'Операторы',
    cn: '客户服务平台',
  },
  'Operators channel': {
    eng: 'Agent',
    ru: 'Оператор',
    cn: '人工客服',
  },
  'Operators channel bot': {
    eng: 'Agent bot',
    ru: 'Бот-оператор',
  },
  'Operators channel bot group': {
    eng: 'Agent group',
    ru: 'Группа операторов',
  },
  'Operators group': {
    eng: 'Agent group',
    ru: 'Группа операторов',
    cn: '人工客服组',
  },
  'Operators channel bot operator': {
    eng: 'Agent',
    ru: 'Оператор',
  },
  'C2D agent-bot not selected': {
    eng: 'Select an agent bot',
    ru: 'Выберите бот-оператора',
  },
  'C2D agent-bot group not selected': {
    eng: 'Agents without a group',
    ru: 'Операторы без группы',
  },
  'Analytics tags': {
    eng: 'Metrics configuration',
    ru: 'Настройка метрик',
  },
  Reports: {
    eng: 'Reports',
    ru: 'Отчеты',
  },
  Clients: {
    eng: 'Clients',
    ru: 'Клиенты',
    cn: '客户',
  },
  'Deploy-info': {
    eng: 'Deploy Info',
    ru: 'Ботсерверы/боты',
    cn: '部署信息',
  },
  Client: {
    eng: 'Client',
    ru: 'Клиент',
    cn: '客户端',
  },
  'Without client': {
    eng: 'Without client',
    ru: 'Без клиента',
    cn: '无客户端',
  },
  'Script editor': {
    eng: 'Script editor',
    ru: 'Сценарии',
    cn: '脚本编辑器',
  },
  'Script visual editor': {
    eng: 'Visual script editor',
    ru: 'Визуальный редактор сценариев',
    cn: '可视化脚本编辑器',
  },
  News: {
    eng: 'News',
    ru: 'Новости',
    cn: '消息',
  },
  Analytics: {
    eng: 'Analytics',
    ru: 'Аналитика',
    cn: '分析',
  },
  'Page flow': {
    eng: 'User flow',
    ru: 'Переходы',
    cn: '用户流',
  },
  'Page not found': {
    eng: 'Oops, page not found',
    ru: 'Упс, страница не найдена',
    cn: '哎呀！页面找不到了',
  },
  Dialogs: {
    eng: 'Dialogs',
    ru: 'Диалоги',
    cn: '对话',
  },
  Tasks: {
    eng: 'Tasks',
    ru: 'Задачи',
    cn: '任务',
  },
  TelephonyPage: {
    eng: 'Telephony',
    ru: 'Телефония',
    cn: '电话',
  },
  Task: {
    eng: 'Task',
    ru: 'Задача',
    cn: '任务',
  },
  Help: {
    eng: 'Help',
    ru: 'Помощь',
    cn: '帮助',
  },
  'Technical description': {
    eng: 'Technical description',
    ru: 'Техническое описание',
    cn: '技术描述',
  },
  'Technical difficulties': {
    eng: 'We have some technical problems',
    ru: 'Возникли технические трудности',
    cn: '我们碰到了技术问题',
  },
  Profile: {
    eng: 'Profile',
    ru: 'Профиль',
    cn: '简介',
  },
  Exit: {
    eng: 'Sign out',
    ru: 'Выход',
    cn: '退出',
  },
  'Client $[1] detail page': {
    eng: 'Client $[1] detail page',
    ru: 'Детальная страница клиента $[1]',
    cn: '用户 $[1] 详情页',
  },
  'Client inactivity time': {
    eng: 'Client inactivity time',
    ru: 'Время неактивности клиента',
    cn: '客户闲置时间',
  },
  'Client name': {
    eng: 'Client name',
    ru: 'Имя клиента',
    cn: '客户名',
  },
  "Client didn't sent any message yet": {
    eng: 'Client hasn’t sent any message yet',
    ru: 'Клиент еще не отправил ни одного сообщения',
    cn: '客户端暂未发送消息',
  },
  'Client list': {
    eng: 'Client list',
    ru: 'Список клиентов',
    cn: '客户列表',
  },
  'Client e-mail': {
    eng: 'Client email',
    ru: 'email клиента',
    cn: '客户邮箱',
  },
  'Client status': {
    eng: 'Client status',
    ru: 'Статус клиента',
    cn: '客户状态',
  },
  'Client email': {
    eng: 'Client email',
    ru: 'Email клиента',
    cn: '客户邮件',
  },
  'Client login': {
    eng: 'Client login',
    ru: 'Логин клиента',
    cn: '客户登录',
  },
  'Type $[1]': {
    eng: 'Type $[1]',
    ru: 'Тип $[1]',
    cn: '类型 $[1]',
  },
  'Bot $[1]': {
    eng: 'Bot $[1]',
    ru: 'Бот $[1]',
    cn: '机器人 $[1]',
  },
  'Bot name': {
    eng: 'Bot',
    ru: 'Бот',
    cn: '机器人名称',
  },
  'Bot avatar': {
    eng: 'Bot avatar',
    ru: 'Аватар бота',
    cn: '机器人头像',
  },
  'Bot first name': {
    eng: 'First name',
    ru: 'Имя',
    cn: '机器人名',
  },
  'Bot last name': {
    eng: 'Last name',
    ru: 'Фамилия',
    cn: '机器人姓',
  },
  'Bot email': {
    eng: 'Email',
    ru: 'Email',
    cn: '机器人邮件',
  },
  'Bot first name is required': {
    eng: 'First name is required',
    ru: 'Имя бота — обязательное поле',
    cn: '机器人名必填',
  },
  'Bot ID': {
    eng: 'Bot ID',
    ru: 'Бот ID',
    cn: '机器人ID',
  },
  'Bot deploy success': {
    eng: 'Bot $[1] successfully deployed',
    ru: 'Бот $[1] успешно опубликован',
  },
  'Users-superadmin list': {
    eng: 'User list',
    ru: 'Список пользователей',
    cn: '用户超级管理员列表',
  },
  'News list': {
    eng: 'News list',
    ru: 'Список новостей',
    cn: '消息列表',
  },
  'News creation': {
    eng: 'Create news',
    ru: 'Создание новости',
    cn: '创建消息',
  },
  'News $[1] detail page': {
    eng: 'News $[1] detail page',
    ru: 'Детальная страница новости $[1]',
    cn: '消息$[1]详情页',
  },
  'News publishing settings': {
    eng: 'News publishing settings',
    ru: 'Настройки публикации новостей',
    cn: '消息发布设置',
  },
  'News count per page': {
    eng: 'News per page',
    ru: 'Количество новостей на странице',
    cn: '每页的消息数',
  },
  'Deploy history and results': {
    eng: 'Deployment history and results',
    ru: 'История и результаты публикации сценариев',
    cn: '部署历史和结果',
  },
  Settings: {
    eng: 'Settings',
    ru: 'Настройки',
    cn: '设置',
  },
  Tags: {
    eng: 'Labels',
    ru: 'Метки',
    cn: '标签',
  },
  Type: {
    eng: 'Type',
    ru: 'Тип',
    cn: '类型',
  },
  Description: {
    eng: 'Name',
    ru: 'Название',
    cn: '名称',
  },
  Branch: {
    eng: 'Branch',
    ru: 'Ветка',
    cn: '分支',
  },
  'Publication/Refs': {
    eng: 'Deployment / Branch',
    ru: 'Публикация / Ветка',
    cn: '发布/分支',
  },
  'GitSource:tip': {
    eng: 'You can also select a tag or enter a commit hash',
    ru: 'Вы также можете выбрать тег или ввести хеш коммита',
  },
  'GitSource:branch': {
    eng: 'Branch',
    ru: 'Ветка',
  },
  'GitSource:tag': {
    eng: 'Tag',
    ru: 'Тег',
  },
  'GitSource:revision': {
    eng: 'Revision',
    ru: 'Ревизия',
  },
  Rollout: {
    eng: 'Deploy',
    ru: 'Публикация',
    cn: '发布',
  },
  checkStatus: {
    eng: 'Monitoring',
    ru: 'Мониторинг',
    cn: '监控',
  },
  'Create channel': {
    eng: 'Connect channel',
    ru: 'Подключить канал',
    cn: '创建通道',
  },
  'Create news': {
    eng: 'Create news',
    ru: 'Создать новость',
    cn: '创建消息',
  },
  'Create an account. All fields are required.': {
    eng: 'Create an account. All fields are required.',
    ru: 'Создайте аккаунт. Все поля обязательны.',
    cn: '创建账户，所有字段均为必填项。',
  },
  'Create an account': {
    eng: 'Create an account',
    ru: 'Создать аккаунт',
    cn: '创建账户',
  },
  'create channel': {
    eng: 'connect channel',
    ru: 'подключить канал',
    cn: '创建通道',
  },
  'Create task': {
    eng: 'Add',
    ru: 'Добавить',
  },
  'Create auto': {
    eng: 'Create automatically',
    ru: 'Создать автоматически',
    cn: '自动创建',
  },
  'Create manual': {
    eng: 'Make manually',
    ru: 'Ввести вручную',
    cn: '手动创建',
  },
  'Create new connection': {
    eng: 'Add new connection',
    ru: 'Создать новое подключение',
    cn: '添加新连接',
  },
  'Create connection': {
    eng: 'Add a connection',
    ru: 'Создать подключение',
    cn: '添加一个链接',
  },
  'create a new list': {
    eng: 'add a list of phone numbers first',
    ru: 'создать новый список',
    cn: '先添加一个电话号码列表',
  },
  'create a voice channel': {
    eng: 'create a phone channel',
    ru: 'создать телефонный канал',
    cn: '创建电话通道',
  },
  'Create available from anywhere in the script': {
    eng: 'Make the block available from any script point',
    ru: 'Сделать доступным из любой точки сценария',
    cn: '在任何场景节点均可使用本板块',
  },
  Show: {
    eng: 'Show',
    ru: 'Показать',
    cn: '展示',
  },
  Hide: {
    eng: 'Hide',
    ru: 'Скрыть',
    cn: '隐藏',
  },
  Publish: {
    eng: 'Deploy',
    ru: 'Опубликовать',
    cn: '发布',
  },
  'Error loading widget': {
    eng: 'Error loading widget',
    ru: 'Ошибка при загрузке виджета',
    cn: '加载小部件出错',
  },
  'Error messages': {
    eng: 'Error messages',
    ru: 'Сообщения об ошибках',
    cn: '报错信息',
  },
  'Error message': {
    eng: 'Error message',
    ru: 'Сообщение об ошибке',
    cn: '报错信息',
  },
  'Loading...': {
    eng: 'Loading...',
    ru: 'Загрузка...',
    cn: '加载中...',
  },
  'Check fields value': {
    eng: 'Check fields value',
    ru: 'Проверьте правильность заполнения полей',
    cn: '检查字段值',
  },
  'Check connection': {
    eng: 'Check the connection',
    ru: 'Проверять соединение',
    cn: '检查连接',
  },
  'Check connection popover': {
    eng: 'Check the availability of the SIP trunk by sending qualify (OPTIONS) messages within set periods',
    ru: 'Проверять доступность SIP-транка, отправляя qualify (OPTIONS) сообщения с заданной периодичностью',
    cn: '通过在设置的时间段内发送限定（选项）消息来检查sip中继的可用性',
  },
  Save: {
    eng: 'Save',
    ru: 'Сохранить',
    cn: '保存',
  },
  Reload: {
    eng: 'Reload',
    ru: 'Перезагрузить',
    cn: '重新加载',
  },
  Create: {
    eng: 'Create',
    ru: 'Создать',
    cn: '创建',
  },
  Edit: {
    eng: 'Edit',
    ru: 'Редактировать',
    cn: '编辑',
  },
  Cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'API key': {
    eng: 'API key',
    ru: 'Ключ API',
    cn: 'API密钥',
  },
  Manually: {
    eng: 'Manual',
    ru: 'Вручную',
    cn: '手动',
  },
  Auto: {
    eng: 'Automatic',
    ru: 'Автоматически',
    cn: '自动',
  },
  Off: {
    eng: 'Off',
    ru: 'Выкл',
    cn: '关闭',
  },
  On: {
    eng: 'On',
    ru: 'Вкл',
    cn: '打开',
  },
  'Access token': {
    eng: 'Access token',
    ru: 'Токен',
    cn: '访问令牌',
  },
  'Access is denied': {
    eng: 'Access is denied',
    ru: 'В доступе отказано',
    cn: '访问被拒绝',
  },
  'Service Access token': {
    eng: 'Service access token',
    ru: 'Сервисный ключ доступа',
  },
  'Service not available': {
    eng: 'Service is not available',
    ru: 'Сервис временно недоступен',
    cn: '服务不可用',
  },
  'Provider Id': {
    eng: 'Provider ID',
    ru: 'Идентификатор провайдера',
  },
  'If the token is not specified, it will be generated automatically': {
    eng: 'If token is not specified, it will be generated automatically',
    ru: 'Если токен не задан, он будет сгенерирован автоматически',
    cn: '如果令牌未指定，将自动生成',
  },
  'If there are no operators': {
    eng: 'If there are no agents',
    ru: 'Если нет операторов',
    cn: '如果没有人工客服',
  },
  'Insertion code': {
    eng: 'Embed code',
    ru: 'Код для вставки',
    cn: '嵌入代码',
  },
  'Widget position': {
    eng: 'Widget position',
    ru: 'Положение виджета',
    cn: '小部件位置',
  },
  'Widget theme': {
    eng: 'Widget theme',
    ru: 'Тема виджета',
    cn: '小部件主题',
  },
  'Widget config': {
    eng: 'Widget configuration',
    ru: 'Конфигурация виджета',
    cn: '小部件配置',
  },
  'Widget involvement': {
    eng: 'Engagement',
    ru: 'Вовлечение',
    cn: '参与',
  },
  'Widget Active dialogue invitation': {
    eng: 'Active dialog initiation',
    ru: 'Активное приглашение в диалог',
    cn: '启动对话',
  },
  'Widget more': {
    eng: 'more',
    ru: 'больше',
    cn: '更多',
  },
  'Widget Time spent on the website page': {
    eng: 'Time spent on the website page',
    ru: 'Время на странице',
    cn: '在该网页花费的时间',
  },
  'Widget Time spent on the website': {
    eng: 'Time spent on the website',
    ru: 'Время на сайте',
    cn: '在该网站花费的时间',
  },
  'Widget sec': {
    eng: 'sec',
    ru: 'сек',
    cn: '秒',
  },
  'Widget Add event': {
    eng: 'Add event',
    ru: 'Добавить событие',
    cn: '添加事件',
  },
  'Show to % users': {
    eng: 'Show to % of users',
    ru: 'Показывать % пользователей',
    cn: '显示给%的用户',
  },
  'Show answers': {
    eng: 'Show answers',
    ru: 'Выводить ответы',
    cn: '显示答复',
  },
  'Show form': {
    eng: 'Show form',
    ru: 'Выводить форму',
    cn: '显示表格',
  },
  'Show invalid numbers': {
    eng: 'Show list',
    ru: 'Посмотреть список',
    cn: '显示列表',
  },
  'Show report': {
    eng: 'Show report',
    ru: 'Показать отчет',
    cn: '显示报告',
  },
  'Show calls': {
    eng: 'Show',
    ru: 'Показывать звонки',
    cn: '显示',
  },
  'Show replacePrefix': {
    eng: 'Replace/delete the first digit in the number for outbound calls',
    ru: 'Заменять/удалять первую цифру в номере при исходящем вызове',
    cn: '替换/删除呼出电话号码中的第一位数字',
  },
  'GET request example': {
    eng: 'GET request example',
    ru: 'Пример GET запроса',
    cn: 'GET请求示例',
  },
  'Get actions json': {
    eng: 'Download action.json',
    ru: 'Скачать action.json',
    cn: '下载action.json',
  },
  'get yandex oauth': {
    eng: 'Get OAuth token',
    ru: 'Получить OAuth',
    cn: '获取OAuth令牌',
  },
  'Get browser default time': {
    eng: 'Browser default time',
    ru: 'Получать из браузера',
    cn: '浏览器默认时间',
  },
  'POST request example': {
    eng: 'POST request example',
    ru: 'Пример POST запроса',
    cn: 'POST请求示例',
  },
  'Webhook address': {
    eng: 'Webhook address',
    ru: 'Адрес вебхука',
    cn: '网络挂钩地址',
  },
  'Channels:PrompterAPI:ChatWidgetScriptUrl': {
    eng: 'URL of chat widget script',
    ru: 'Ссылка на скрипт чат-виджета',
  },
  'Sender Name': {
    eng: 'Sender Name',
    ru: 'Имя отправителя',
    cn: '发送人姓名',
  },
  'You was blocked. If you think this happened by mistake, contact support.': {
    eng: 'You have been blocked. If you believe this is a mistake, contact support.',
    ru: 'Вы были заблокированы. Если вы считаете, что это произошло по ошибке, обратитесь в службу поддержки.',
    cn: '您已被阻止，如有误，请联系支持。',
  },
  'you can': {
    eng: 'you can',
    ru: 'вы можете',
    cn: '你可以',
  },
  'You can use regular expressions.': {
    eng: 'You can use regular expressions.',
    ru: 'Вы можете использоваться регулярные выражения.',
    cn: '可以使用正则表达式',
  },
  'You have no number lists. First you need to': {
    eng: 'There are no phone numbers lists. Please  ',
    ru: 'У вас нет ни одного списка номеров. Сначала вам нужно',
    cn: '无电话号码列表，请',
  },
  'You have no channels connected. For a mailing to start you need to': {
    eng: 'You have no channels created. To start a campaign,',
    ru: 'У вас нет подключенных каналов. Для обзвона вам нужно',
    cn: '未创建通道，如要开始活动，',
  },
  "You won't be able to restore deleted campaign. Are you sure?": {
    eng: 'You won’t be able to restore deleted campaign. Are you sure?',
    ru: 'Удаление обзвона невозможно отменить. Вы точно хотите удалить обзвон?',
    cn: '将无法还原已删除的活动，是否确定？',
  },
  Field: {
    eng: 'Field',
    ru: 'Поле',
    cn: '字段',
  },
  Value: {
    eng: 'Value',
    ru: 'Значение',
    cn: '值',
  },
  Messenger: {
    eng: 'Messenger',
    ru: 'Мессенджер',
  },
  'Addition time': {
    eng: 'Creation time',
    ru: 'Время добавления',
    cn: '创建时间',
  },
  'Addition date': {
    eng: 'Creation date',
    ru: 'Дата добавления',
    cn: '创建日期',
  },
  'Date from': {
    eng: 'Date from',
    ru: 'Дата с',
    cn: '日期从',
  },
  'Date to': {
    eng: 'Date to',
    ru: 'Дата по',
    cn: '日期到',
  },
  'Date of addition': {
    eng: 'Creation date',
    ru: 'Дата добавления',
    cn: '创建日期',
  },
  'Date of creation': {
    eng: 'Date of creation',
    ru: 'Дата создания',
    cn: '创建日期',
  },
  'date of charge': {
    eng: 'Billing date',
    ru: 'Дата списания',
    cn: '付款日期',
  },
  'Last activity time': {
    eng: 'Last activity time',
    ru: 'Время последней активности',
    cn: '最后一次活动时间',
  },
  'Last activity date': {
    eng: 'Last activity date',
    ru: 'Дата последней активности',
    cn: '最后一次活动日期',
  },
  'Last phrase': {
    eng: 'Last phrase',
    ru: 'Последняя фраза',
  },
  'Last updated at': {
    eng: 'Last updated at',
    ru: 'Последнее обновление в',
    cn: '最后一次更新于',
  },
  Language: {
    eng: 'Language',
    ru: 'Язык',
    cn: '语言',
  },
  Locking: {
    eng: 'Block user',
    ru: 'Блокировка',
    cn: '阻止用户',
  },
  'No permissions': {
    eng: 'No permission',
    ru: 'Недостаточно прав',
    cn: '无权限',
  },
  'No dialogs found': {
    eng: 'No dialogs found',
    ru: 'Диалогов не найдено',
    cn: '未找到对话',
  },
  'No phrases found': {
    eng: 'No phrases found',
    ru: 'Фраз не найдено',
    cn: '未找到短语',
  },
  'No data': {
    eng: 'No data',
    ru: 'Нет данных',
    cn: '没有数据',
  },
  'No channels were created for this project yet': {
    eng: 'No channels created for this project yet',
    ru: 'Для этого проекта еще не создано каналов',
    cn: '该项目还未创建通道',
  },
  'No tags': {
    eng: 'No labels',
    ru: 'Нет меток',
    cn: '没有标签',
  },
  'No tasks found': {
    eng: 'No tasks found',
    ru: 'Задач не найдено',
    cn: '未找到任务',
  },
  'No data specified': {
    eng: 'No data specified',
    ru: 'Данные не указаны',
    cn: '数据未指定',
  },
  'No JAICP projects': {
    eng: 'Do you know that you can manage bots, train them, watch analytics and make newsletters to your clients?',
    ru: 'Вы знаете, что можете управлять ботами, обучать их, смотреть аналитику и делать обзвоны клиентам?',
    cn: '您知道你可以管理机器人，训练它们，观看分析，并为您的客户制作新闻稿吗？',
  },
  'No matches': {
    eng: 'No matches',
    ru: 'Нет совпадений',
    cn: '不匹配',
  },
  'No file/folder selected for removal': {
    eng: 'No file/folder selected for removal',
    ru: 'Не выбран файл/папка для удаления',
    cn: '没有选择要删除的文件/文件夹',
  },
  'No call lists': {
    eng: 'No phone number lists',
    ru: 'Нет списков номеров',
    cn: '无电话号码列表',
  },
  'No connections': {
    eng: 'No connections added',
    ru: 'У вас нет созданных подключений',
    cn: '不允许添加连接',
  },
  'No call tasks': {
    eng: 'No campaings',
    ru: 'У вас нет ни одного обзвона',
    cn: '无活动',
  },
  'No clients listed': {
    eng: 'No clients listed',
    ru: 'В списке нет клиентов',
    cn: '没有列出客户',
  },
  'No account yet': {
    eng: 'Do not have an account?',
    ru: 'У вас нет аккаунта?',
    cn: '没有账号?',
  },
  Comment: {
    eng: 'Comment',
    ru: 'Комментарий',
    cn: '评论',
  },
  'Unblocked by': {
    eng: 'Unblocked by',
    ru: 'Кем разблокирован клиент',
    cn: '解除阻止者',
  },
  'Blocked by': {
    eng: 'Blocked by',
    ru: 'Кем заблокирован клиент',
    cn: '阻止者',
  },
  'Blocked user': {
    eng: 'Blocked',
    ru: 'Полностью отключен',
    cn: '锁定',
  },
  'Unblocking date': {
    eng: 'Unblocking date',
    ru: 'Дата снятия блокировки',
    cn: '解除阻止日期',
  },
  'Blocking date': {
    eng: 'Blocking date',
    ru: 'Дата блокировки',
    cn: '阻止日期',
  },
  Refresh: {
    eng: 'Refresh',
    ru: 'Обновить',
    cn: '更新',
  },
  Right: {
    eng: 'Right',
    ru: 'Справа',
    cn: '右边',
  },
  Left: {
    eng: 'Left',
    ru: 'Слева',
    cn: '左边',
  },
  'Are you sure?': {
    eng: 'Are you sure?',
    ru: 'Вы уверены?',
    cn: '是否确认？',
  },
  'Are you sure you want to delete the user?': {
    eng: 'Are you sure you want to delete this user?',
    ru: 'Вы действительно хотите удалить пользователя?',
    cn: '确认删除用户？',
  },
  'Are you sure you want to remove class $[1]': {
    eng: 'Are you sure you want to remove class $[1]',
    ru: 'Вы действительно хотите удалить класс $[1]',
    cn: '是否确认删除类 $[1]',
  },
  'Are you sure you want to delete the project $[1]': {
    eng: 'Are you sure you want to delete the project $[1]?',
    ru: 'Вы уверены что хотите удалить проект $[1]?',
    cn: '确认删除项目$[1]?',
  },
  Reason: {
    eng: 'Reason',
    ru: 'Причина',
    cn: '原因',
  },
  'Specify the block reason.': {
    eng: 'Specify the block reason.',
    ru: 'Укажите причину блокировки.',
    cn: '指定阻止原因',
  },
  'Specify the transfer method used by the operator': {
    eng: 'Specify the transfer method used by the agent',
    ru: 'Укажите используемый способ перевода на оператора',
    cn: '指定人工客服使用的传输方法',
  },
  'Specify the steats which the user will hit in will be considered an unrecognized request.': {
    eng: 'Specify the states which the user will hit in will be considered an unrecognized request.',
    ru: 'Укажите стейты, попадание пользователя в которые, будет считаться нераспознанным запросом.',
    cn: '指定用户将进入的状态将被视为无法识别的请求。',
  },
  'Message to the client': {
    eng: 'Message to client',
    ru: 'Сообщение клиенту',
    cn: '客户端消息',
  },
  'Message to the user when the chat is closed': {
    eng: 'Message to the user when closing the chat',
    ru: 'Сообщение пользователю при закрытии чата',
    cn: '聊天关闭时发送至用户的信息',
  },
  'Message to the operator': {
    eng: 'Message to the agent',
    ru: 'Сообщение оператору',
    cn: '给人工客服的消息',
  },
  'Message has been deleted': {
    eng: 'Message has been deleted',
    ru: 'Сообщение удалено',
    cn: '消息已被删除',
  },
  Block: {
    eng: 'Block',
    ru: 'Заблокировать',
    cn: '阻止',
  },
  Unlock: {
    eng: 'Unlock',
    ru: 'Разблокировать',
    cn: '解锁',
  },
  Found: {
    eng: 'Found',
    ru: 'Найдено',
    cn: '找到',
  },
  Search: {
    eng: 'Search',
    ru: 'Поиск',
    cn: '搜索',
  },
  'Build history': {
    eng: 'Build history',
    ru: 'История сборок',
    cn: '创建历史记录',
  },
  Build: {
    eng: 'Build',
    ru: 'Сборка',
    cn: '创建',
  },
  Started: {
    eng: 'Started',
    ru: 'Запущена',
    cn: '开启',
  },
  Ended: {
    eng: 'Finished',
    ru: 'Завершена',
    cn: '结束',
  },
  'Initiated by user': {
    eng: 'Initiated by user',
    ru: 'Инициирована пользователем',
    cn: '由用户启动',
  },
  'Initiated automatically': {
    eng: 'Initiated automatically',
    ru: 'Инициирована автоматически',
    cn: '自动启动',
  },
  Revision: {
    eng: 'Revision',
    ru: 'Ревизия',
    cn: '修订',
  },
  'Revision comment': {
    eng: 'Revision comment',
    ru: 'Комментарий ревизии',
    cn: '修订意见',
  },
  'For this channel, there were no deploys yet': {
    eng: 'No deployments for this channel yet.',
    ru: 'Для этого канала еще не было публикаций.',
    cn: '尚未部署此通道。',
  },
  'for all tariffs': {
    eng: 'Included in all plans',
    ru: 'Для всех тарифов',
  },
  Sessions: {
    eng: 'Sessions',
    ru: 'Сессии',
    cn: '会话',
  },
  Phrases: {
    eng: 'Phrases',
    ru: 'Фразы',
    cn: '短语',
  },
  Groups: {
    eng: 'Groups',
    ru: 'Группы',
    cn: '组',
  },
  Graphics: {
    eng: 'Graphics',
    ru: 'Графики',
    cn: '图形',
  },
  Duration: {
    eng: 'Duration',
    ru: 'Длительность',
    cn: '持续时间',
  },
  'First question': {
    eng: 'First question',
    ru: 'Первый вопрос',
    cn: '第一个问题',
  },
  Count: {
    eng: 'Count',
    ru: 'Кол-во',
    cn: '总数',
  },
  Start: {
    eng: 'Start',
    ru: 'Начало',
    cn: '开始',
  },
  'Dialogs log': {
    eng: 'Session log',
    ru: 'Лог сессий',
    cn: '对话日志',
  },
  'Dialogs log by theme': {
    eng: 'Dialogs log by theme',
    ru: 'Лог по тематикам',
    cn: '按主题的对话框日志',
  },
  'Dialogs count': {
    eng: 'Total dialogs',
    ru: 'Всего диалогов',
    cn: '对话框总数',
  },
  'Dialogs tooltip': {
    eng: 'The total number of dialogs for the selected period. A dialog is a session with a client for no more than 5 minutes.',
    ru: 'Количество всех диалогов за выбранный период. Диалог — это сессия с клиентом не более 5 минут.',
    cn: '所选期间的对话框总数。对话是与用户进行不超过5分钟的会话。',
  },
  session: {
    eng: 'Session',
    ru: 'сессии',
    cn: '会话',
  },
  Session: {
    eng: 'Session',
    ru: 'Сессия',
    cn: '会话',
  },
  Question: {
    eng: 'Question',
    ru: 'Вопрос',
    cn: '问题',
  },
  Class: {
    eng: 'Class',
    ru: 'Класс',
    cn: '类',
  },
  'Class assignment': {
    eng: 'Class assignment',
    ru: 'Назначение класса',
    cn: '类分配',
  },
  'Class has successfully saved': {
    eng: 'Class has been successfully saved',
    ru: 'Класс успешно сохранен',
    cn: '类已保存成功',
  },
  Phrase: {
    eng: 'Phrase',
    ru: 'Фраза',
    cn: '短语',
  },
  State: {
    eng: 'State',
    ru: 'Стейт',
    cn: '状态',
  },
  Answer: {
    eng: 'Answer',
    ru: 'Ответ',
    cn: '答复',
  },
  Markup: {
    eng: 'Markup',
    ru: 'Разметка',
    cn: '标记',
  },
  phrase: {
    eng: 'phrases',
    ru: 'фразы',
    cn: '短语',
  },
  'Session marks': {
    eng: 'Session labels',
    ru: 'Метки сессий',
    cn: '会话标记',
  },
  'Session without marks': {
    eng: 'Session with no marks',
    ru: 'Сессии без меток',
    cn: '无标记的会话',
  },
  'Session ID': {
    eng: 'Session ID',
    ru: 'ID сессии',
    cn: '会话ID',
  },
  'Session tags': {
    eng: 'Session labels',
    ru: 'Метки для сессий',
    cn: '会话标签',
  },
  'Session with any label': {
    eng: 'Labeled sessions',
    ru: 'Сессии с метками',
    cn: '标记会话',
  },
  'Session result': {
    eng: 'Session result',
    ru: 'Результат сессии',
  },
  'Phrase marks': {
    eng: 'Phrase labels',
    ru: 'Метки фраз',
    cn: '短语标记',
  },
  'Phrase with any label': {
    eng: 'Labeled phrases',
    ru: 'Фразы с метками',
    cn: '标记短语',
  },
  'Phrase example': {
    eng: 'Example',
    ru: 'Введите пример',
    cn: '示例',
  },
  From: {
    eng: 'From',
    ru: 'С',
    cn: '从',
  },
  To: {
    eng: 'To',
    ru: 'По',
    cn: '到',
  },
  'Incorrect date format.': {
    eng: 'Incorrect date format.',
    ru: 'Некорректный формат даты.',
    cn: '日期格式不正确',
  },
  'Incorrect date interval': {
    eng: 'Incorrect date interval',
    ru: 'Некорректный временной интервал',
    cn: '日期间隔不正确',
  },
  Text: {
    eng: 'Text',
    ru: 'Текст',
    cn: '文本',
  },
  Operator: {
    eng: 'Agent',
    ru: 'Оператор',
    cn: '人工客服',
  },
  Agent: {
    eng: 'Agent',
    ru: 'Оператор',
    cn: '接线员',
  },
  'Phrases marks': {
    eng: 'Phrase labels',
    ru: 'Метки фразы',
    cn: '短语标记',
  },
  'Phrases without marks': {
    eng: 'Phrases with no labels',
    ru: 'Фразы без меток',
    cn: '无标记的短语',
  },
  'Phrases closing chat with the operator': {
    eng: 'Phrases closing chat with the agent',
    ru: 'Фразы, закрывающие чат с оператором',
    cn: '结束与人工客服聊天的短语',
  },
  'Phrases counter': {
    eng: 'The list of active intents $[1] from ',
    ru: 'Список активных интентов — $[1] из ',
    cn: '有效的意图列表$[1]来自 ',
  },
  'Phrases log': {
    eng: 'Message log',
    ru: 'Лог сообщений',
  },
  'Hide empty sessions': {
    eng: 'Hide empty sessions',
    ru: 'Скрыть пустые сессии',
    cn: '隐藏空会话',
  },
  'Hide without headers': {
    eng: 'Hide without headers',
    ru: 'Скрывать без заголовков',
    cn: '无标题隐藏',
  },
  'Hide report': {
    eng: 'Hide report',
    ru: 'Скрыть отчёт',
    cn: '隐藏报告',
  },
  'Hide filters': {
    eng: 'Hide filters',
    ru: 'Свернуть фильтры',
  },
  'Extra filters': {
    eng: 'Extra filters',
    ru: 'Дополнительные фильтры',
    cn: '附加过滤器',
  },
  'Extra settings': {
    eng: 'Advanced settings',
    ru: 'Расширенные настройки',
    cn: '扩展设置',
  },
  'Nested classes': {
    eng: 'Nested classes',
    ru: 'Вложенные классы',
    cn: '嵌套类',
  },
  'Nested states': {
    eng: 'Nested states',
    ru: 'Вложенные стейты',
    cn: '嵌套状态',
  },
  'Short name': {
    eng: 'Short name',
    ru: 'Короткое имя',
    cn: '简称',
  },
  'System features': {
    eng: 'System features',
    ru: 'Системные фичи',
    cn: '系统功能',
  },
  'System button': {
    eng: 'System button',
    ru: 'Системная кнопка',
    cn: '系统按钮',
  },
  'system button': {
    eng: 'system button',
    ru: 'системная кнопка',
    cn: '系统按钮',
  },
  'Default projects features': {
    eng: 'Default project features',
    ru: 'Фичи по умолчанию для проекта',
    cn: '默认项目功能',
  },
  'Repository link': {
    eng: 'Link',
    ru: 'Ссылка',
    cn: '链接',
  },
  'Repository link placeholder': {
    eng: 'Repository link',
    ru: 'Ссылка на репозиторий',
    cn: '存储库链接',
  },
  'Repository login': {
    eng: 'Login',
    ru: 'Логин',
    cn: '登录',
  },
  'Repository login placeholder': {
    eng: 'Login',
    ru: 'Аккаунт в репозитории',
    cn: '登录',
  },
  'ProjectEditForm:ChangePassword:PersonalToken:Title': {
    eng: 'Personal access token',
    ru: 'Персональный токен',
  },
  'Repository password placeholder': {
    eng: 'Password',
    ru: 'Пароль',
    cn: '密码',
  },
  'Repository content folder': {
    eng: 'Folder',
    ru: 'Путь',
    cn: '文件夹',
  },
  'Repository content folder placeholder': {
    eng: 'Folder',
    ru: 'Вставьте ссылку на папку с контеном в репозитории',
    cn: '文件夹',
  },
  Origin: {
    eng: 'Origin',
    ru: 'Происхождение',
    cn: '来源',
  },
  Headline: {
    eng: 'Headline',
    ru: 'Заголовок',
    cn: '标题',
  },
  'Interval of news update': {
    eng: 'News update interval',
    ru: 'Интервал обновления новостей',
    cn: '消息更新时间间隔',
  },
  Immediately: {
    eng: 'Immediately',
    ru: 'Немедленно',
    cn: '马上',
  },
  Delayed: {
    eng: 'Delayed',
    ru: 'Отложено',
    cn: '延迟',
  },
  Accesses: {
    eng: 'User role',
    ru: 'Доступы',
    cn: '用户角色',
  },
  'Save settings': {
    eng: 'Save settings',
    ru: 'Сохранить настройки',
    cn: '保存设置',
  },
  'Save and continue': {
    eng: 'Save and continue',
    ru: 'Сохранить и продолжить',
    cn: '保存并继续',
  },
  'Save directory': {
    eng: 'Save directory',
    ru: 'Сохранить справочник',
    cn: '保存目录',
  },
  'Save password': {
    eng: 'Save password',
    ru: 'Сохранить пароль',
    cn: '保存密码',
  },
  'Save password and login': {
    eng: 'Save password and sign in',
    ru: 'Сохранить пароль и войти',
    cn: '保存密码并退出',
  },
  'NOT SET': {
    eng: 'NOT SET',
    ru: 'НЕ УСТАНОВЛЕН',
    cn: '未设置',
  },
  'Not found': {
    eng: 'Not found',
    ru: 'Не найдено',
    cn: '未找到',
  },
  Content: {
    eng: 'Content',
    ru: 'Содержание',
    cn: '内容',
  },
  Audience: {
    eng: 'Audience',
    ru: 'Аудитория',
    cn: '观众',
  },
  Publication: {
    eng: 'Deployment',
    ru: 'Публикация',
    cn: '发布',
  },
  'Restriction on channels': {
    eng: 'Channel restriction',
    ru: 'Ограничение по каналам',
    cn: '通道限制',
  },
  Selected: {
    eng: 'Selected',
    ru: 'Выбрано',
    cn: '已选',
  },
  Select: {
    eng: 'Select',
    ru: 'Выберите',
    cn: '选择',
  },
  Download: {
    eng: 'Download',
    ru: 'Скачать',
    cn: '下载',
  },
  'Download SME': {
    eng: 'Download SME',
    ru: 'Скачать SME',
    cn: '下载SME',
  },
  'Download Scores': {
    eng: 'Download chat ratings',
    ru: 'Скачать «Оценки чата»',
    cn: '下载聊天评级',
  },
  'Download number report': {
    eng: 'Download number report',
    ru: 'Скачать отчёт по номерам',
    cn: '下载报告',
  },
  'Download attempts report': {
    eng: 'Download attempt report',
    ru: 'Скачать отчёт по попыткам',
    cn: '下载呼叫尝试报告',
  },
  rateLimitExceeded_zero: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунд.',
  },
  rateLimitExceeded_one: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] second.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунду.',
  },
  rateLimitExceeded_two: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунды.',
  },
  rateLimitExceeded_few: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунды.',
  },
  rateLimitExceeded_many: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунд.',
  },
  rateLimitExceeded_other: {
    eng: 'The number of retries has been exceeded. Repeat after $[1] seconds.',
    ru: 'Количество попыток превышено. Повторите через $[1] секунд.',
  },
  'Previous state': {
    eng: 'Previous state',
    ru: 'Предыдущее состояние',
    cn: '上一个状态',
  },
  'From this state user switched to the current state': {
    eng: 'From this state user switched to the current state',
    ru: 'Из этого состояния пользователь перешел в текущее',
    cn: '用户从该状态切换到当前状态',
  },
  'From value': {
    eng: 'From',
    ru: 'От',
    cn: '从',
  },
  'State ': {
    eng: 'State',
    ru: 'Состояние',
    cn: '状态',
  },
  Visits: {
    eng: 'Visits',
    ru: 'Посещения',
    cn: '访问',
  },
  'Current state': {
    eng: 'Current state',
    ru: 'Текущее состояние',
    cn: '当前状态',
  },
  'Current key': {
    eng: 'Current key',
    ru: 'Текущий ключ',
    cn: '当前密钥',
  },
  'Current User': {
    eng: 'Current user',
    ru: 'Текущий пользователь',
    cn: '当前用户',
  },
  'Current ASR balance': {
    eng: 'Available for calls:',
    ru: 'Доступно для звонков:',
  },
  'Return to original': {
    eng: 'Return to original state',
    ru: 'Вернуться в исходное',
    cn: '返回到初始状态',
  },
  'Return to accounts': {
    eng: 'Back to accounts',
    ru: 'Вернуться в аккаунты',
    cn: '返回帐户',
  },
  'Next state': {
    eng: 'Next state',
    ru: 'Следующее состояние',
    cn: '下一个状态',
  },
  'In these states, the user moved from the current state': {
    eng: 'User switched to these states from the current state',
    ru: 'В эти состояния пользователь перешел с текущего',
    cn: '用户从当前状态切换到这些状态',
  },
  'In modal': {
    eng: 'In modal window',
    ru: 'В модальном окне',
    cn: '在模式窗口中',
  },
  Authorization: {
    eng: 'Authorization',
    ru: 'Авторизация',
    cn: '授权',
  },
  'Sign in to your account': {
    eng: 'Sign in to your account',
    ru: 'Войдите в аккаунт',
    cn: '登陆账户',
  },
  'Sign into account': {
    eng: 'Sign in to $[1]',
    ru: 'Вход в аккаунт $[1]',
    cn: '登录',
  },
  'sign up page github button': {
    eng: 'Sign up with GitHub',
    ru: 'Регистрация через GitHub',
  },
  'sign up page google button': {
    eng: 'Sign up with Google',
    ru: 'Регистрация через Google',
  },
  Email: {
    eng: 'Email',
    ru: 'Почтовый адрес',
    cn: '邮箱',
  },
  Password: {
    eng: 'Password',
    ru: 'Пароль',
    cn: '密码',
  },
  Registration: {
    eng: 'Sign up',
    ru: 'Регистрация',
    cn: '注册',
  },
  Enter: {
    eng: 'Sign in',
    ru: 'Войти',
    cn: '登录',
  },
  Forbidden: {
    eng: 'Forbidden',
    ru: 'Запрещено',
    cn: '禁止',
  },
  "Oops! You're lost.": {
    eng: 'Oops! You’re lost.',
    ru: 'Ой! Кажется Вы потерялись.',
    cn: '哎呀！丢失了。',
  },
  'The page you are looking for was not found.': {
    eng: 'The page you are looking for cannot be found.',
    ru: 'Запрашиваемая страница не найдена',
    cn: '找不到您要查找的页面。',
  },
  'The path to the block must begin with /': {
    eng: 'Path to the block must begin with /',
    ru: 'Путь до блока должен начинаться с /',
    cn: '块的路径必须以/开头',
  },
  'The value of the block path must be unique': {
    eng: 'Value of the block path must be unique',
    ru: 'Значение пути блока должно быть уникальным',
    cn: '块路径值必须是唯一的',
  },
  'the operator closed the chat': {
    eng: 'The agent closed the chat',
    ru: 'Оператор завершил диалог',
    cn: '人工客服已关闭对话',
  },
  'ResponseText:CantBeEmpty:Error': {
    eng: 'The text field "Response text" can not be empty',
    ru: 'Поле "Текст в ответе" не может быть пустым',
    cn: '文本字段"反应文本"不能为空',
  },
  'The picture size cannot exceed 10 MB': {
    eng: 'The picture size cannot exceed 10 MB',
    ru: 'Размер изображения не должен превышать 10 Мб',
    cn: '图片大小不能超过10MB',
  },
  'The project will be completely removed, along with all its data, channel settings and clients.': {
    eng: 'The project will be completely removed, along with all its data, channel settings and clients.',
    ru: 'Будет удалён проект и все связанные с ним данные, настройки каналов и клиенты.',
    cn: '该项目连同其所有数据、通设置和客户端将被完全删除。',
  },
  'The client will be disconnected from his partner. Are you sure you want to break this connection?': {
    eng: 'Are you sure? Hulk will come for you in case of an error',
    ru: 'Вы уверены? Халк придет к вам в случае ошибки',
  },
  'The specified client address does not exist or cannot be assigned to this partner': {
    eng: 'The specified client address does not exist or cannot be assigned to this partner',
    ru: 'Указанный адрес клиента не существует или не может быть назначен данному партнеру',
    cn: '指定的客户地址不存在或无法分配给此合作伙伴',
  },
  'Repeat password': {
    eng: 'Repeat password',
    ru: 'Повторите пароль',
    cn: '确认密码',
  },
  'Passwords do not match': {
    eng: 'Passwords do not match',
    ru: 'Пароли не совпадают',
    cn: '密码不匹配',
  },
  'Passwords are different': {
    eng: 'Passwords do not match',
    ru: 'Пароли не совпадают',
  },
  Back: {
    eng: 'Back',
    ru: 'Назад',
    cn: '上一步',
  },
  'Messages per week': {
    eng: 'Messages per week',
    ru: 'Сообщений за неделю',
    cn: '一周信息',
  },
  'Messages tags groups': {
    eng: 'Label groups for messages',
    ru: 'Группы меток для сообщений',
    cn: '消息标签组',
  },
  'Messages count': {
    eng: 'Total messages',
    ru: 'Всего сообщений',
    cn: '消息总数',
  },
  'Messages count tooltip': {
    eng: 'Total number of messages for the selected period.',
    ru: 'Значение суммарного количества всех сообщений за выбранный период.',
    cn: '所选期间的消息总数。',
  },
  Messages: {
    eng: 'Messages',
    ru: 'Сообщения',
    cn: '信息',
  },
  'Unique customers': {
    eng: 'Unique customers',
    ru: 'Уникальные клиенты',
    cn: '唯一客户',
  },
  'Unique users, messages, sessions': {
    eng: 'Unique users, messages, sessions',
    ru: 'Уникальные пользователи, сообщения, сессии',
    cn: '唯一用户，消息，会话',
  },
  'Unique users count': {
    eng: 'Unique users',
    ru: 'Уникальные пользователи',
    cn: '唯一用户',
  },
  'Unique users tooltip': {
    eng: 'Total number of unique users for the selected period. Unique user is a client who has had one or more sessions with a bot for the specified period.',
    ru: 'Значение суммарного количества уникальных пользователей за выбранный период. Под уникальных пользователей подразумеваем клиента, который имел одну или более сессий с ботом за указанный период.',
    cn: '所选期间的唯一用户总数。唯一用户是在指定时间段内与机器人有一个或多个会话的客户端。',
  },
  'Unique users': {
    eng: 'Unique users',
    ru: 'Уникальные пользователи',
    cn: '唯一用户',
  },
  'Unique count': {
    eng: 'Unique users',
    ru: 'Уникальных пользователей',
    cn: '独立用户',
  },
  Commits: {
    eng: 'Commits',
    ru: 'Коммиты',
    cn: '提交',
  },
  'Number of tasks created': {
    eng: 'Number of created tasks',
    ru: 'Кол-во созданных задач',
    cn: '已创建任务总数',
  },
  'Number of completed tasks': {
    eng: 'Number of completed tasks',
    ru: 'Кол-во завершенных задач',
    cn: '已完成任务总数',
  },
  'Number of unique users': {
    eng: 'Number of unique users',
    ru: 'Кол-во уникальных пользователей',
    cn: '唯一用户的数量',
  },
  'Number of messages': {
    eng: 'Number of messages',
    ru: 'Кол-во сообщений',
    cn: '消息数量',
  },
  'Number of sessions': {
    eng: 'Number of sessions',
    ru: 'Кол-во сессий',
    cn: '会话数量',
  },
  'Number of dialogs': {
    eng: 'Number of dialogs',
    ru: 'Кол-во диалогов',
    cn: '对话框数量',
  },
  'Number of tasks added': {
    eng: 'Number of tasks added',
    ru: 'Кол-во добавленных задач',
    cn: '添加的任务数量',
  },
  'Number of tasks finished': {
    eng: 'Number of tasks finished',
    ru: 'Кол-во закрытых задач',
    cn: '结束的任务数量',
  },
  'Number limit exceeded. Only first': {
    eng: 'Limit is exceeded. Only first',
    ru: 'Превышен лимит. Только первые',
    cn: '数量超出限制，仅允许第一个',
  },
  'Number prefix': {
    eng: 'Number prefix',
    ru: 'Префикс номера',
    cn: '数字前缀',
  },
  'Number prefix popover': {
    eng: 'Prefix will be added to the number for outbound calls',
    ru: 'Добавочный префикс будет добавлен перед вызываемым номером при исходящем вызове',
    cn: '向呼出的电话号码添加前缀',
  },
  'Number of lines available': {
    eng: 'Number of lines available',
    ru: 'Максимум одновременных звонков',
    cn: '可用线路数',
  },
  'Number of simultaneously active lines for dialing subscribers': {
    eng: 'The maximum number of concurrent calls for dialing customers',
    ru: 'Количество одновременных вызовов не будет превышать заданное число. Пустое поле — без ограничений',
    cn: '可用的外呼并发线路数',
  },
  'Number of attempts to dial': {
    eng: 'Dial attempts',
    ru: 'Попыток дозвониться',
    cn: '尝试呼叫次数',
  },
  Component: {
    eng: 'Component',
    ru: 'Компонент',
    cn: '组件',
  },
  Date: {
    eng: 'Date',
    ru: 'Дата',
    cn: '日期',
  },
  'Start time': {
    eng: 'Start time',
    ru: 'Время начала',
    cn: '开始时间',
  },
  'Start date': {
    eng: 'Start date',
    ru: 'Начальная дата',
    cn: '开始日期',
  },
  'Start time should be before the end time': {
    eng: 'Start time should be before the end time',
    ru: 'Неправильный промежуток времени',
    cn: '开始时间应早于结束时间',
  },
  'Start call task': {
    eng: 'Start call campaign',
    ru: 'Начать обзвон',
    cn: '开始活动',
  },
  'Start time should be in the future': {
    eng: 'Start date should be in the future',
    ru: 'Время начала обзвона уже прошло',
    cn: '开始日期应在将来',
  },
  'Start mailing': {
    eng: 'Start call campaign',
    ru: 'Начать обзвон',
    cn: '开始活动',
  },
  'Start a dialogue with any user message': {
    eng: 'Start a dialog with any user message',
    ru: 'Начинать диалог с любого сообщения пользователя',
    cn: '开始与任意用户消息的对话',
  },
  'start since $[1]': {
    eng: 'starting on $[1]',
    ru: 'c $[1] числа',
    cn: '于$[1] 开始',
  },
  'start since $[1] every month': {
    eng: 'from day $[1] of each month',
    ru: 'c $[1] числа каждого месяца',
  },
  'h.': {
    eng: 'h',
    ru: 'ч',
    cn: '小时',
  },
  'min.': {
    eng: 'min',
    ru: 'мин',
    cn: '分',
  },
  'sec.': {
    eng: 's',
    ru: 'с',
    cn: '秒',
  },
  'ms.': {
    eng: 'ms',
    ru: 'мс',
    cn: '毫秒',
  },
  Host: {
    eng: 'Host',
    ru: 'Хост',
    cn: '主机',
  },
  'Change task': {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'Change comment': {
    eng: 'Change comment',
    ru: 'Изменить комментарий',
    cn: '修改评价',
  },
  'Change password': {
    eng: 'Change password',
    ru: 'Изменить пароль',
    cn: '更改密码',
  },
  'Change the connection': {
    eng: 'Change the connection',
    ru: 'Изменить связь',
    cn: '更改连接',
  },
  'change plan': {
    eng: 'Change subscription',
    ru: 'Выбрать другой тариф',
    cn: '修改套餐',
  },
  DeleteCard: {
    eng: 'Delete card',
    ru: 'Удалить карту',
  },
  'change card': {
    eng: 'Update card details',
    ru: 'Сменить карту',
    cn: '更新银行卡信息',
  },
  'change card header': {
    eng: 'Change card?',
    ru: 'Сменить карту?',
  },
  'change card due date $[1]': {
    eng: 'Starting from $[1] you will be automatically charged $[2] per month.',
    ru: 'С $[1] будет автоматически списываться оплата $[2] в месяц.',
  },
  'change card warning': {
    eng: 'To turn the autopay on, please add a bank card. <br />We will charge 1 ₽ from your card and then return it later.',
    ru: 'Для включения автоплатежа необходимо привязать карту. <br />С карты будет списан 1 ₽, а потом возвращен обратно.',
  },
  'add card header': {
    eng: 'Turn autopay on?',
    ru: 'Подключить автоплатёж?',
  },
  'add card action button': {
    eng: 'Turn on',
    ru: 'Подключить',
  },
  'change card decline btn': {
    eng: 'Keep the current card',
    ru: 'Оставить текущую карту',
    cn: '继续使用当前绑定的银行卡',
  },
  'change card accept btn': {
    eng: 'Change',
    ru: 'Cменить',
  },
  'Tags addition': {
    eng: 'Adding labels',
    ru: 'Добавление меток',
    cn: '标签添加',
  },
  'Task changing': {
    eng: 'Edit task',
    ru: 'Редактирование задачи',
  },
  'Task creation': {
    eng: 'New task',
    ru: 'Новая задача',
  },
  'Task - priority': {
    eng: 'Task — priority',
    ru: 'Задачи — приоритеты',
    cn: '任务 - 优先级',
  },
  'Task - states': {
    eng: 'Task — states',
    ru: 'Задачи — состояния',
    cn: '任务 - 状态',
  },
  'Task status': {
    eng: 'Task status',
    ru: 'Статус задачи',
    cn: '任务状态',
  },
  'Task name': {
    eng: 'Task name',
    ru: 'Поиск по названию',
    cn: '任务名称',
  },
  'Task description': {
    eng: 'Task description',
    ru: 'Поиск по описанию',
    cn: '任务描述',
  },
  'Task statistics': {
    eng: 'Task statistics',
    ru: 'Статистика задач',
    cn: '任务统计',
  },
  "Task has status 'FINISHED' but expected [IN_PROGRESS]": {
    eng: 'This action cannot be performed because the campaign has been completed. Please refresh the page',
    ru: 'Операция невозможна — обзвон уже завершен. Пожалуйста, обновите страницу',
    cn: '无法执行此操作-活动已结束，请刷新页面',
  },
  "Task has status 'FINISHED' but expected [NEW, PLANNED, PAUSED]": {
    eng: 'This action cannot be performed because the campaign has been completed. Please refresh the page',
    ru: 'Операция невозможна — обзвон уже завершен. Пожалуйста, обновите страницу',
    cn: '无法执行此操作-活动已结束，请刷新页面',
  },
  "Task has status 'IN_PROGRESS' but expected [NEW, PLANNED, PAUSED]": {
    eng: 'This action cannot be performed because the campaign has been started. Please refresh the page',
    ru: 'Операция невозможна — обзвон уже начался. Пожалуйста, обновите страницу',
    cn: '无法执行此操作-活动已开始，请刷新页面',
  },
  'Comment changing': {
    eng: 'Edit comment',
    ru: 'Изменение комментария',
    cn: '编辑评价',
  },
  'Comment addition': {
    eng: 'Add comment',
    ru: 'Добавление комментария',
    cn: '添加评价',
  },
  Priority: {
    eng: 'Priority',
    ru: 'Приоритет',
    cn: '优先级',
  },
  Responsible: {
    eng: 'Responsible',
    ru: 'Ответственный',
    cn: '负责',
  },
  Version: {
    eng: 'Version',
    ru: 'Версия',
    cn: '版本',
  },
  'Creation date': {
    eng: 'Creation date',
    ru: 'Дата создания',
    cn: '创建日期',
  },
  'Delete the task?': {
    eng: 'Delete this task?',
    ru: 'Удалить задачу?',
    cn: '删除此任务？',
  },
  'Delete channel question?': {
    eng: 'Do you want to remove this channel?',
    ru: 'Вы действительно хотите удалить этот канал?',
    cn: '确认要删除此通道吗',
  },
  'Delete class': {
    eng: 'Delete class',
    ru: 'Удалить класс',
    cn: '删除类',
  },
  'Delete file': {
    eng: 'Delete file',
    ru: 'Удалить файл',
    cn: '删除文件',
  },
  'Delete project': {
    eng: 'Delete project',
    ru: 'Удалить проект',
    cn: '删除项目',
  },
  'Delete call list question?': {
    eng: 'This operation will delete the list of phone numbers and it cannot be canceled. Are you sure you want to proceed?',
    ru: 'Данная операция приведет к удалению списка номеров, отменить которую будет невозможно. Вы уверены, что хотите продолжить?',
    cn: '此操作将删除电话号码列表，无法取消。确定要继续吗？',
  },
  'Delete siptrunk question': {
    eng: 'Are you sure you want to delete the connection. This operation cannot be undone',
    ru: 'Вы уверены, что хотите удалить подключение? Данное действие невозможно отменить',
    cn: '是否确认删除连接？此操作无法撤消',
  },
  'Delete campaign': {
    eng: 'Delete campaign',
    ru: 'Удалить обзвон',
    cn: '删除活动',
  },
  'All data associated with this task will be deleted.': {
    eng: 'All data associated with this task will be deleted.',
    ru: 'Вся связанные с этой задачей данные будут удалены.',
    cn: '所有与该任务相关的数据将被删除。',
  },
  'All channels': {
    eng: 'All channels',
    ru: 'Все каналы',
    cn: '所有通道',
  },
  Delete: {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'Changing a task': {
    eng: 'Edit task',
    ru: 'Изменение задачи',
    cn: '编辑任务',
  },
  'Editor theme': {
    eng: 'Editor theme',
    ru: 'Тема редактора',
    cn: '编辑器主题',
  },
  'Editor Restore state title': {
    eng: 'Restore local state',
    ru: 'Восстановление',
    cn: '还原至本地状态',
  },
  'Editor Restore state text': {
    eng: 'There is an unsaved local changes. Restore?',
    ru: 'Найдены не сохраненные изменения.\nЖелаете восстановить?',
    cn: '有未保存的本地更改。是否恢复？',
  },
  'Editor Restore state submit': {
    eng: 'Restore',
    ru: 'Восстановить',
    cn: '恢复',
  },
  'Editor File already exist title': {
    eng: 'File already exists',
    ru: 'Файл уже существует',
    cn: '文件已存在',
  },
  'Editor File already exist text': {
    eng: 'File $[1] already exist in this directory',
    ru: 'Файл $[1] уже существует.',
    cn: '此目录中已存在文件$[1]',
  },
  Light: {
    eng: 'Light',
    ru: 'Светлая',
    cn: '亮的',
  },
  Dark: {
    eng: 'Dark',
    ru: 'Темная',
    cn: '暗的',
  },
  'Active users only': {
    eng: ' Active users only',
    ru: 'Только активные пользователи',
    cn: ' 仅有效用户',
  },
  'Active user': {
    eng: 'Active',
    ru: 'Активный пользователь',
    cn: '有效',
  },
  Role: {
    eng: 'Role',
    ru: 'Роль',
    cn: '角色',
  },
  Add: {
    eng: 'Add',
    ru: 'Добавить',
    cn: '添加',
  },
  Change: {
    eng: 'Change',
    ru: 'Изменить',
    cn: '修改',
  },
  user: {
    eng: 'user',
    ru: 'пользователя',
    cn: '用户',
  },
  'New password': {
    eng: 'New password',
    ru: 'Новый пароль',
    cn: '新密码',
  },
  'New button': {
    eng: 'New button',
    ru: 'Новая кнопка',
    cn: '新按钮',
  },
  'New condition': {
    eng: 'New condition',
    ru: 'Новое условие',
    cn: '新条件',
  },
  'New voice channel': {
    eng: 'New phone channel',
    ru: 'Новый телефонный канал',
    cn: '新的语音通道',
  },
  'New partner email': {
    eng: 'New partner email',
    ru: 'Email нового партнера',
    cn: '新合作伙伴邮箱',
  },
  'New partner login': {
    eng: 'New partner login',
    ru: 'Логин нового партнера',
    cn: '新合作伙伴登录',
  },
  Activity: {
    eng: 'Activity',
    ru: 'Активность',
    cn: '活动',
  },
  Russian: {
    eng: 'Русский',
    ru: 'Русский',
  },
  English: {
    eng: 'English',
    ru: 'English',
  },
  Chinese: {
    eng: '中文',
    ru: '中文',
  },
  Portuguese: {
    eng: 'Português',
    ru: 'Português',
    pt: 'Português',
    cn: 'Português',
  },
  Homepage: {
    eng: 'Homepage',
    ru: 'Главная',
    cn: '首页',
  },
  Yes: {
    eng: 'Yes',
    ru: 'Да',
    cn: '是',
  },
  No: {
    eng: 'No',
    ru: 'Нет',
    cn: '否',
  },
  'Editing manual': {
    eng: 'Manual on editing',
    ru: 'Инструкция по редактированию',
    cn: '编辑手册',
  },
  'Editing class': {
    eng: 'Edit class',
    ru: 'Редактирование класса',
    cn: '编辑类',
  },
  'Outgoing secret': {
    eng: 'Outgoing secret',
    ru: 'Исходящий ключ',
    cn: '呼出密钥',
  },
  'Webim Account Name': {
    eng: 'Webim account name',
    ru: 'Имя аккаунта Webim',
    cn: 'Webim账户名',
  },
  'Integration Token': {
    eng: 'Integration token',
    ru: 'Токен интеграции',
  },
  'Greeting Message': {
    eng: 'Greeting message',
    ru: 'Приветственное сообщение',
  },
  'I-Digital source': {
    eng: 'Account name',
    ru: 'Имя учетной записи',
  },
  'Whatsapp phoneNumberId': {
    eng: 'Phone number id',
    ru: 'ID номера телефона',
  },
  years: {
    eng: 'years',
    ru: 'года',
    cn: '年',
  },
  days: {
    eng: 'days',
    ru: 'дня',
    cn: '天',
  },
  hours: {
    eng: 'hours',
    ru: 'ч',
    cn: '小时.',
  },
  minutes: {
    eng: 'minutes',
    ru: 'мин',
    cn: '分.',
  },
  seconds: {
    eng: 'seconds',
    ru: 'с',
    cn: '秒.',
  },
  Login: {
    eng: 'Login',
    ru: 'Логин',
    cn: '登陆',
  },
  'Record calls': {
    eng: 'Record calls',
    ru: 'Записывать звонки',
    cn: '记录来电',
  },
  Code: {
    eng: 'Code',
    ru: 'Код',
    cn: '代码',
  },
  Visual: {
    eng: 'Visual',
    ru: 'Визуальный',
    cn: '可视化的',
  },
  'To start, select the file on the left.': {
    eng: 'Select file on the left to start.',
    ru: 'Чтобы начать выберите файл слева.',
    cn: '选择左边的文件以开始。',
  },
  'To value': {
    eng: 'To',
    ru: 'До',
    cn: '到',
  },
  'To project page': {
    eng: 'Back to home',
    ru: 'Перейти на страницу с проектами',
    cn: '返回首页',
  },
  'To add': {
    eng: 'Valid phone numbers:',
    ru: 'Номеров будет добавлено:',
    cn: '有效的电话号码',
  },
  'To change the settings, pause the running call task.': {
    eng: 'Please pause the campaign to edit the settings',
    ru: 'Чтобы изменить настройки, поставьте работающий обзвон на паузу',
    cn: '更改设置前，请暂停呼叫活动',
  },
  'To account list': {
    eng: 'To the account list',
    ru: 'К списку аккаунтов',
    cn: '到帐户列表',
  },
  Context: {
    eng: 'Context',
    ru: 'Контекст',
    cn: '上下文',
  },
  Nearest: {
    eng: 'Nearest',
    ru: 'Ближайшие',
    cn: '最近的',
  },
  All: {
    eng: 'All',
    ru: 'Все',
    cn: '所有',
  },
  Pattern: {
    eng: 'Pattern',
    ru: 'Шаблон',
    cn: '模式',
  },
  Classifier: {
    eng: 'Classifier',
    ru: 'Классификатор',
    cn: '分类器',
  },
  'Removing class': {
    eng: 'Remove class',
    ru: 'Удаление класса',
    cn: '删除类',
  },
  'Data has successfully saved': {
    eng: 'Data has been successfully saved',
    ru: 'Данные успешно сохранены',
    cn: '数据已保存成功',
  },
  'Data for the period': {
    eng: 'Data for the period',
    ru: 'Данные за период',
  },
  'Dismiss all alerts': {
    eng: 'Dismiss all alerts',
    ru: 'Скрыть все сообщения',
    cn: '解除所有警报',
  },
  'Block editing': {
    eng: 'Edit block',
    ru: 'Редактирование блока',
    cn: '编辑块',
  },
  'Block name': {
    eng: 'Block name',
    ru: 'Название блока',
    cn: '块名称',
  },
  'Block account': {
    eng: 'Suspend account',
    ru: 'Заблокировать аккаунт',
    cn: '锁定账户',
  },
  'Block type': {
    eng: 'Lock type',
    ru: 'Тип блокировки',
    cn: '锁定类型',
  },
  'Path to block': {
    eng: 'Path to block',
    ru: 'Путь до блока',
    cn: '块路径',
  },
  Message: {
    eng: 'Message to user',
    ru: 'Сообщение пользователю',
    cn: '给用户的信息',
  },
  'Variable name': {
    eng: 'Variable name',
    ru: 'Имя переменной',
    cn: '变量名',
  },
  'Invalid characters in the block path': {
    eng: 'Invalid characters in the block path',
    ru: 'Недопустимые символы в пути блока',
    cn: '块路径中的无效字符',
  },
  'Invalid token error': {
    eng: 'The token for communication with the license server is no longer valid. If the database was restored from a backup, then you need to get a new license key',
    ru: 'Некорректный токен для связи с лицензионным сервером. Если база данных была восстановлена из бэкапа, то необходимо получить новый лицензионный ключ',
    cn: '用于与许可证服务器通信的令牌不再有效。如果数据库是从备份还原的，则需要获取新的许可证密钥',
  },
  'Invalid token': {
    eng: 'Invalid token',
    ru: 'Некорректный токен',
    cn: '无效令牌',
  },
  'Invalid numbers:': {
    eng: 'Invalid phone numbers:',
    ru: 'Номеров, не прошедших проверку:',
    cn: '无效的电话号码',
  },
  'Invalid numbers': {
    eng: 'Invalid phone numbers',
    ru: 'Неверные номера',
    cn: '无效电话号码',
  },
  'Report on the absence of operators': {
    eng: 'Report on the absence of agents',
    ru: 'Сообщать об отсутствии операторов',
    cn: '提示人工客服不在线',
  },
  'Name of the group of operators': {
    eng: 'Name of the agent group',
    ru: 'Название группы операторов',
    cn: '人工客服组名称',
  },
  Key: {
    eng: 'Key',
    ru: 'Ключ',
    cn: '密钥',
  },
  Screen: {
    eng: 'Screen',
    ru: 'Экран',
    cn: '屏幕',
  },
  Entrance: {
    eng: 'Entrance',
    ru: 'Вход',
    cn: '进入',
  },
  'Entering a number': {
    eng: 'Enter number',
    ru: 'Ввод числа',
    cn: '输入数字',
  },
  'Transfer to operator': {
    eng: 'Transfer to the agent',
    ru: 'Перевод на оператора',
    cn: '转到人工客服',
  },
  'Transferred to agent': {
    eng: 'Transferred to agent',
    ru: 'Перевод на оператора',
    cn: '跳转到操作员',
  },
  'Transferred sessions': {
    eng: 'Transfers to the agent',
    ru: 'Переводов на оператора',
    cn: '转到人工客服',
  },
  'Transferred sessions tooltip': {
    eng: 'The total number of sessions transferred to the agent for the selected period. All sessions transferred to agent are counted, including those in which there were no agents available.',
    ru: 'Суммарное количество переведенных на оператора сессий за выбранный период. Учитываются все переведенные на оператора сессии, в том числе те, в которых не было свободных операторов.',
    cn: '在所选期间内，转人工客服的会话总数。转人工客服并不是因为他们处于空闲状态。脚本判断转人工的原因很重要。',
  },
  "I'm translating to the operator": {
    eng: 'Switching to the agent',
    ru: 'Вам ответит первый освободившийся оператор',
    cn: '正在转至人工客服',
  },
  Transition: {
    eng: 'Transition',
    ru: 'Переход',
    cn: '转换',
  },
  Buttons: {
    eng: 'Buttons',
    ru: 'Кнопки',
    cn: '按钮',
  },
  'Random transition': {
    eng: 'Random transition',
    ru: 'Случайный переход',
    cn: '随机转换',
  },
  Condition: {
    eng: 'Condition',
    ru: 'Условие',
    cn: '条件',
  },
  Script: {
    eng: 'Script',
    ru: 'Скрипт',
    cn: '脚本',
  },
  Image: {
    eng: 'Image',
    ru: 'Изображение',
    cn: '图片',
  },
  Global: {
    eng: 'Global',
    ru: 'Глобальный',
    cn: '全球',
  },
  'Subject Pattern': {
    eng: 'Subject Pattern',
    ru: 'Паттерн темы',
    cn: '主题模式',
  },
  Contextual: {
    eng: 'Contextual',
    ru: 'Контекстный',
    cn: '上下文相关的',
  },
  Variable: {
    eng: 'Variable',
    ru: 'Переменная',
    cn: '变量',
  },
  Limits: {
    eng: 'Limits',
    ru: 'Пределы',
    cn: '限定',
  },
  Stimulus: {
    eng: 'Trigger',
    ru: 'Стимул',
    cn: '触发',
  },
  'Pattern ': {
    eng: 'Pattern',
    ru: 'Паттерн',
    cn: '模式',
  },
  Example: {
    eng: 'Example',
    ru: 'Пример',
    cn: '例子',
  },
  Button: {
    eng: 'Button',
    ru: 'Кнопка',
    cn: '按钮',
  },
  'Group of Buttons': {
    eng: 'Group of Buttons',
    ru: 'Группа кнопок',
    cn: '按钮组',
  },
  'By default': {
    eng: 'By default',
    ru: 'По умолчанию',
    cn: '默认',
  },
  'By default, new projects are saved in an external repository': {
    eng: 'By default, new projects are saved in an external repository, but you can use local storage',
    ru: 'По умолчанию новые проекты сохраняются во внешнем репозитории, но вы можете использовать локальное хранилище',
    cn: '默认情况下，新项目保存在外部存储库中，但您可以使用本地存储',
  },
  'Condition group': {
    eng: 'Condition group',
    ru: 'Группа условий',
    cn: '条件组',
  },
  'Insert link': {
    eng: 'Insert link',
    ru: 'Вставить ссылку',
    cn: '插入链接',
  },
  'Insert image link': {
    eng: 'Insert image link',
    ru: 'Вставьте ссылку на изображение',
    cn: '插入图像链接',
  },
  'Image link': {
    eng: 'Image link',
    ru: 'Ссылка на изображение',
    cn: '图像链接',
  },
  Logic: {
    eng: 'Logic',
    ru: 'Логика',
    cn: '逻辑',
  },
  Reaction: {
    eng: 'Reaction',
    ru: 'Реакция',
    cn: '反应',
  },
  from: {
    eng: 'from',
    ru: 'от',
    cn: '从',
  },
  to: {
    eng: 'to',
    ru: 'до',
    cn: '到',
  },
  Event: {
    eng: 'Event',
    ru: 'Событие',
    cn: '事件',
  },
  Reset: {
    eng: 'Reset',
    ru: 'Сбросить',
    cn: '重置',
  },
  "Localization $[1] language title can't be empty": {
    eng: 'Localization title for $[1] can’t be empty',
    ru: 'Значение заголовка для языка $[1] не должно быть пустым.',
    cn: '$[1]的本地化标题不能为空',
  },
  'Verify token': {
    eng: 'Verification token',
    ru: 'Токен верификации',
    cn: '验证令牌',
  },
  'Application token': {
    eng: 'Application token',
    ru: 'Токен приложения',
    cn: '应用程序令牌',
  },
  'Application key': {
    eng: 'Application key',
    ru: 'Ключ приложения',
    cn: '应用程序密钥',
  },
  'Application code': {
    eng: 'Application code',
    ru: 'Код приложения',
    cn: '应用程序代码',
  },
  'Interactive subscriptions request example': {
    eng: 'Interactive subscription request example',
    ru: 'Пример ссылки для подписки на интерактивные события',
    cn: '交互式订阅请求示例',
  },
  'External repository': {
    eng: 'External repository',
    ru: 'Внешний репозиторий',
    cn: '使用外部存储',
  },
  'External repo': {
    eng: 'External repo',
    ru: 'Внешний репозиторий',
    cn: '外部存储库',
  },
  'External git': {
    eng: 'In an external Git repository',
    ru: 'Во внешнем Git-репозитории',
  },
  'External repo warning': {
    eng: 'All contents of the $[1] branch in the external Git repository will be overwritten',
    ru: 'Все содержимое ветки $[1] внешнего Git-репозитория будет перезаписано системным шаблоном',
  },
  'OAuth client id': {
    eng: 'OAuth client ID',
    ru: 'Идентификатор клиента OAuth',
    cn: 'OAuth客户端ID',
  },
  Domain: {
    eng: 'Domain',
    ru: 'Домен',
    cn: '域名',
  },
  'Config is copied on clipboard': {
    eng: 'Configuration copied to clipboard',
    ru: 'Конфигурация скопирована в буфер обмена',
    cn: '配置已复制到剪贴板',
  },
  'Response text': {
    eng: 'Response text',
    ru: 'Текст ответа',
    cn: '响应文本',
  },
  'Dialog markup view': {
    eng: 'Dialog markup view',
    ru: 'Вид разметки диалогов',
    cn: '对话框标记视图',
  },
  'On page': {
    eng: 'On page',
    ru: 'На странице',
    cn: '页码',
  },
  Bot: {
    eng: 'Bot',
    ru: 'Бот',
    cn: '机器人',
  },
  'Sessions:': {
    eng: 'Sessions:',
    ru: 'Сессий:',
    cn: '连接',
  },
  'Edit widget config': {
    eng: 'Edit widget configuration',
    ru: 'Редактировать конфигурацию виджета',
    cn: '编辑小部件配置',
  },
  'Edit channel': {
    eng: 'Edit channel',
    ru: 'Редактировать',
    cn: '编辑通道',
  },
  'Edit siptrunk': {
    eng: 'Edit',
    ru: 'Редактировать',
    cn: '编辑',
  },
  'Export config': {
    eng: 'Export configuration',
    ru: 'Экспорт конфигурации',
    cn: '导出配置',
  },
  'Import config': {
    eng: 'Import configuration',
    ru: 'Импорт конфигурации',
    cn: '导入配置',
  },
  'Import call list info': {
    eng: 'You can cancel the import, correct errors and upload the file again or you can add only the phone numbers that are valid.',
    ru: 'Вы можете отменить импорт, исправить ошибки и загрузить файл заново или добавить только номера, прошедшие проверку',
    cn: '您可以取消导入、更正错误并重新上载文件，也可以仅添加有效的电话号码。',
  },
  'Administrator email': {
    eng: 'Administrator email',
    ru: 'Email администратора',
    cn: '管理员邮箱',
  },
  'Administrator password': {
    eng: 'Administrator password',
    ru: 'Пароль администратора',
    cn: '管理员密码',
  },
  Licenses: {
    eng: 'Licenses',
    ru: 'Лицензии',
    cn: '许可',
  },
  License: {
    eng: 'License',
    ru: 'Лицензия',
    cn: '许可',
  },
  'Used keys': {
    eng: 'Used keys',
    ru: 'Использованные ключи',
    cn: '使用过的密钥',
  },
  'Connection error': {
    eng: 'Failed to activate key. Connection to the licensing server is not available',
    ru: 'Не удалось активировать ключ. Подключение к серверу лицензирования не доступно',
    cn: '无法激活密钥。与授权服务器的连接不可用',
  },
  'Connection params': {
    eng: 'Connection params',
    ru: 'Параметры подключения',
    cn: '连接参数',
  },
  'Connection list': {
    eng: 'Connection list',
    ru: 'Список подключений',
    cn: '连接列表',
  },
  'Connection settings': {
    eng: 'Connection settings',
    ru: 'Настройки подключения',
    cn: '连接设置',
  },
  'Connection changing': {
    eng: 'Connection changing',
    ru: 'Изменение связи',
    cn: '更改连接',
  },
  'Unexpected error': {
    eng: 'Unexpected error',
    ru: 'Непредвиденная ошибка',
    cn: '意外错误',
  },
  'Key not found error': {
    eng: 'Failed to activate key. The specified key is not valid, check the key value entered',
    ru: 'Не удалось активировать ключ. Указанный ключ не действителен, проверьте введенное значение ключа',
    cn: '无法激活密钥。指定的密钥无效，请检查输入的密钥值',
  },
  'Key replaced error': {
    eng: 'Failed to activate key. The specified key has been replaced by a new one',
    ru: 'Не удалось активировать ключ. Указанный ключ был заменен на новый',
    cn: '无法激活密钥。指定的密钥已被新密钥替换',
  },
  'Already activated error': {
    eng: 'Failed to activate key. The specified key is not valid, check the key value entered.',
    ru: 'Не удалось активировать ключ. Указанный ключ уже активирован ранее',
    cn: '无法激活密钥。指定的密钥无效，请检查输入的密钥值。',
  },
  'Already have an account?': {
    eng: 'Already have an account?',
    ru: 'Уже есть аккаунт?',
    cn: '已有账户？',
  },
  'Revoked error': {
    eng: 'Failed to activate key. The specified key is not valid, check the key value entered',
    ru: 'Не удалось активировать ключ. Указанный ключ не действителен, проверьте введенное значение ключа',
    cn: '无法激活密钥。指定的密钥无效，请检查输入的密钥值',
  },
  timeExpiringAt: {
    eng: 'Valid till:',
    ru: 'Действителен до:',
    cn: '有效期至：',
  },
  sessionsLimit: {
    eng: 'Sessions available:',
    ru: 'Доступно сессий:',
    cn: '可用的：',
  },
  sessionsUsed: {
    eng: 'Sessions used:',
    ru: 'Использовано сессий:',
    cn: '已使用的会话：',
  },
  'during the period': {
    eng: 'during the period',
    ru: 'за период',
    cn: '在此期间',
  },
  'This Month': {
    eng: 'This Month',
    ru: 'Последний месяц',
    cn: '本月',
  },
  'This Week': {
    eng: 'This Week',
    ru: 'Последняя неделя',
    cn: '本周',
  },
  'This field is required': {
    eng: 'This field is required',
    ru: 'Это поле необходимо заполнить',
    cn: '此字段必填',
  },
  'This action cannot be performed. Please refresh the page': {
    eng: 'This action cannot be performed. Please refresh the page',
    ru: 'Операция невозможна. Пожалуйста, обновите страницу',
    cn: '无法执行此操作，请刷新页面',
  },
  'This action cannot be performed - the campaign has been already deleted. Please return to campaign list': {
    eng: 'This action cannot be performed because the campaign has been already deleted. Please return to campaign list',
    ru: 'Операция невозможна — обзвон уже был удален. Пожалуйста, вернитесь на страницу обзвонов',
    cn: '无法执行此操作-活动已删除，请返回活动列表',
  },
  'This customer is not a member of the affiliate program': {
    eng: 'This customer is not a member of the affiliate program',
    ru: 'Этот клиент не является участником партнерской программы',
    cn: '此客户不是联盟计划成员',
  },
  Today: {
    eng: 'Today',
    ru: 'Сегодня',
    cn: '本日',
  },
  'Available dialogs count': {
    eng: 'Available dialogs',
    ru: 'Доступных диалогов',
    cn: '可用的对话框',
  },
  'Available dialogs tooltip': {
    eng: 'The available number of dialogs on the plan if the period is less than 1 month. If a period is more than 1 month, the value is increased by a multiple of the number of selected months. For example, if 1 month and 5 days are selected, then the period is considered 2 months, and the number of available dialogs is doubled.',
    ru: 'Количество доступных диалогов по тарифу, если выбран период менее 1 месяца. Если выбран период более месяца, значение увеличивается кратно количеству выбранных месяцев. Например, если выбран 1 месяц и 5 дней, то период рассчитывается как 2 месяца, а количество доступных диалогов удваивается.',
    cn: '计划上的可用对话框数。如果周期小于1个月，则该值将根据费率显示每月可用的对话框数。如果选择一个月以上的期间，则该值将增加所选月数的倍数（例如，如果选择1个月零5天，则将其视为2个月，可用数目x2）。',
  },
  'Available lines count': {
    eng: 'Concurrent calls',
    ru: 'Количество одновременных звонков',
    cn: '可用线路数',
  },
  'available channels link': {
    eng: '#{config.botadmin.helpUrl}/channels',
    ru: '#{config.botadmin.helpUrl}/channels',
  },
  'Added tasks': {
    eng: 'Added tasks',
    ru: 'Добавленные задачи',
    cn: '添加的任务',
  },
  'Added tasks tooltip': {
    eng: 'Total number of added tasks for the selected period.',
    ru: 'Значение суммарного количества добавленных задач за выбранный период.',
    cn: '所选期间添加的任务总数。',
  },
  'Added $[1]': {
    eng: 'Added $[1]',
    ru: 'Добавлены $[1]',
    cn: '添加 $[1]',
  },
  'Finished tasks': {
    eng: 'Finished tasks',
    ru: 'Закрытые задачи',
    cn: '完成的任务',
  },
  'Finished tasks tooltip': {
    eng: 'Total number of closed tasks for the selected period.',
    ru: 'Значение суммарного количества закрытых задач за выбранный период.',
    cn: '所选期间关闭的任务总数。',
  },
  Removed: {
    eng: 'Removed',
    ru: 'Удален',
    cn: '移除',
  },
  'All, except deleted': {
    eng: 'All channels, except deleted',
    ru: 'Все каналы, кроме удаленных',
    cn: '除已删除外的所有通道',
  },
  'Email can not be empty': {
    eng: 'Email can not be empty',
    ru: 'Поле email не может быть пустым',
    cn: '电子邮件不能为空',
  },
  'Email login': {
    eng: 'Email',
    ru: 'Почтовый адрес',
    cn: '邮箱',
  },
  'Email login required': {
    eng: 'Email*',
    ru: 'Email*',
  },
  'Forgot password?': {
    eng: 'Forgot password?',
    ru: 'Забыли пароль?',
    cn: '忘记密码？',
  },
  'Forgot yourself?': {
    eng: 'Forgot account or don’t have an account',
    ru: 'Забыл пароль, забыл логин или новый пользователь',
    cn: '忘记帐户或没有帐户',
  },
  'Password remind': {
    eng: 'Remind password',
    ru: 'Сброс пароля',
    cn: '密码提示',
  },
  'Password required': {
    eng: 'Password*',
    ru: 'Пароль*',
    cn: '密码*',
  },
  'Password change success': {
    eng: 'Password has been changed',
    ru: 'Пароль изменен',
  },
  'Password change error': {
    eng: 'Unable to change your password',
    ru: 'Не удалось изменить пароль',
  },
  'Remind button': {
    eng: 'Send',
    ru: 'Отправить',
    cn: '发送',
  },
  'Reset password': {
    eng: 'Reset password',
    ru: 'Сброс пароля',
    cn: '重置密码',
  },
  'We sent you an email with a link to reset your password': {
    eng: 'We have sent you an email',
    ru: 'Отправляем письмо на почту',
    cn: '我们已给您发送了一封邮件',
  },
  'We work': {
    eng: 'We already know about the problem and are trying to solve it',
    ru: 'Мы знаем о проблеме и уже над ней работаем',
    cn: '我们已知道这个问题，并已在努力解决中。',
  },
  'Request login data': {
    eng: 'Contact your administrator for new login and password',
    ru: 'Обратитесь к вашему администратору. Он выдаст вам логин и пароль',
    cn: '请与管理员联系以获取新的登录名和密码',
  },
  'Request timeout': {
    eng: 'The request failed. Try later.',
    ru: 'Не удалось выполнить запрос. Попробуйте позже.',
    cn: '请求失败。重试',
  },
  "Don't have access": {
    eng: 'Don’t have access',
    ru: 'У меня нет доступа',
    cn: '无法访问',
  },
  'Customize report': {
    eng: 'Customize report',
    ru: 'Настроить отчет',
    cn: '自定义报表',
  },
  Upload: {
    eng: 'Upload',
    ru: 'Загрузить',
    cn: '上传',
  },
  'Empty incoming channels': {
    eng: 'No inbound channels connected to the project',
    ru: 'Здесь будут подключенные к проекту входящие каналы',
    cn: '呼入通道为空',
  },
  'Empty outgoing channels': {
    eng: 'No customer engagement platforms connected to the project',
    ru: 'Здесь будут подключенные к проекту операторские каналы',
    cn: '呼出通道为空',
  },
  'Empty voice channels': {
    eng: 'No phone channels connected to the project',
    ru: 'Здесь будут подключенные к проекту телефонные каналы',
    cn: '未创建通道',
  },
  'IntegrationType GOOGLE': {
    eng: 'Google Sheets',
    ru: 'Google Таблицы',
  },
  'Rollout AUTO': {
    eng: 'Automatic',
    ru: 'Автоматически',
    cn: '自动发布',
  },
  'Rollout MANUAL': {
    eng: 'Manual',
    ru: 'Вручную',
  },
  'Log view': {
    eng: 'Log',
    ru: 'Лог',
    cn: '日志',
  },
  'Log out': {
    eng: 'Log out',
    ru: 'Выйти из системы',
    cn: '登出',
  },
  'Remove channel': {
    eng: 'Remove channel',
    ru: 'Удалить',
    cn: '删除通道',
  },
  'Remove siptrunk': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'Go to bot': {
    eng: 'Go to bot',
    ru: 'Перейти в бота',
    cn: '转到机器人',
  },
  'Confirm delete': {
    eng: 'Confirm delete',
    ru: 'Подтвердите удаление',
    cn: '确认删除',
  },
  'Confirm file/folder removal': {
    eng: 'Confirm file/folder removal',
    ru: 'Подтвердите удаление',
    cn: '确认删除文件/文件夹',
  },
  'Confirm action': {
    eng: 'Confirm action',
    ru: 'Подтвердите действие',
    cn: '确认操作',
  },
  'Confirm new password': {
    eng: 'Confirm new password',
    ru: 'Введите пароль еще раз',
  },
  'Test channel button': {
    eng: 'Test',
    ru: 'Тест',
    cn: '测试',
  },
  'Test mode': {
    eng: 'Test mode',
    ru: 'Тестовый режим',
    cn: '测试模式',
  },
  'Wait to connection': {
    eng: 'Wait to connection',
    ru: 'Ожидает подключения',
    cn: '等待连接',
  },
  'wait call $[1]': {
    eng: 'Please wait. We are calling $[1]',
    ru: 'Ожидайте входящий звонок на номер $[1]',
    cn: '正在呼叫$[1]，请等待',
  },
  'Connected to N channels': {
    eng: 'Connected to $[1] $[2]',
    ru: 'Подключен к $[1] $[2]',
    cn: '连接到$[1] $[2]',
  },
  'Connected to channel name': {
    eng: 'Connected to $[1]',
    ru: 'Подключен к $[1]',
    cn: '连接到$[1]',
  },
  'URL YANDEX webhook is copied to clipboard': {
    eng: 'Alice webhook URL copied to clipboard',
    ru: 'URL вебхука для Алисы скопирован \n в буфер обмена',
    cn: 'Alice的webhook URL被保存到剪贴板',
  },
  'URL ALEXA webhook is copied to clipboard': {
    eng: 'Alexa webhook URL copied to clipboard',
    ru: 'URL вебхука для Алексы скопирован \n в буфер обмена',
    cn: 'Alexa的webhook URL被保存到剪贴板',
  },
  'URL Dialogflow webhook is copied to clipboard': {
    eng: 'Dialogflow webhook URL copied to clipboard',
    ru: 'URL вебхука для Dialogflow скопирован \n в буфер обмена',
    cn: 'Dialogflow的webhook URL被保存到剪贴板',
  },
  'URL ZENBOX webhook is copied to clipboard': {
    eng: 'Aimybox webhook URL copied to clipboard',
    ru: 'URL вебхука для Aimybox скопирован в буфер обмена',
    cn: 'URL Aimybox的webhook被复制到剪贴板',
  },
  'URL FACEBOOK webhook is copied to clipboard': {
    eng: 'Facebook webhook URL copied to clipboard',
    ru: 'URL вебхука для Facebook скопирован в буфер обмена',
    cn: 'Facebook webhook URL已保存到剪贴板',
  },
  'URL INSTAGRAM webhook is copied to clipboard': {
    eng: 'Instagram webhook URL copied to clipboard',
    ru: 'URL вебхука для Instagram скопирован в буфер обмена',
    cn: 'Instagram webhook URL已保存到剪贴板',
  },
  'URL BITRIX webhook is copied to clipboard': {
    eng: 'Bitrix24 webhook URL copied to clipboard',
    ru: 'URL вебхука для Битрикс24 скопирован в буфер обмена',
    cn: 'Bitrix24 webhook URL已保存到剪贴板',
  },
  'URL THREADS webhook is copied to clipboard': {
    eng: 'edna.chatCenter webhook URL copied to clipboard',
    ru: 'URL вебхука для edna.chatCenter скопирован в буфер обмена',
    cn: 'edna.chatCenter webhook URL已保存到剪贴板',
  },
  'URL I_DIGITAL webhook is copied to clipboard': {
    eng: 'i-Digital WhatsApp webhook URL copied to clipboard',
    ru: 'URL вебхука для i-Digital WhatsApp скопирован в буфер обмена',
    cn: 'i-Digital WhatsApp webhook URL已保存到剪贴板',
  },
  'URL INCOMING_JIVOSITE webhook is copied to clipboard': {
    eng: 'JivoChat webhook URL copied to clipboard',
    ru: 'URL вебхука для Jivo скопирован в буфер обмена',
  },
  'URL INCOMING_WEBIM2 webhook is copied to clipboard': {
    eng: 'Webim (External Bot API 2.0) webhook URL copied to clipboard',
    ru: 'URL вебхука для Webim (External Bot API 2.0) скопирован в буфер обмена',
  },
  'URL INBOUND_LIVETEX webhook is copied to clipboard': {
    eng: 'LiveTex webhook URL copied to clipboard',
    ru: 'URL вебхука для LiveTex скопирован в буфер обмена',
  },
  'URL MARUSIA webhook is copied to clipboard': {
    eng: 'Marusia webhook URL copied to clipboard',
    ru: 'URL вебхука для Маруся скопирован в буфер обмена',
    cn: 'Marusia webhook URL已保存到剪贴板',
  },
  'URL WAZZUP webhook is copied to clipboard': {
    eng: 'Wazzup webhook URL copied to clipboard',
    ru: 'URL вебхука для Wazzup скопирован в буфер обмена',
    cn: 'Wazzup webhook URL已保存到剪贴板',
  },
  'URL WHATSAPP webhook is copied to clipboard': {
    eng: 'Whatsapp webhook URL copied to clipboard',
    ru: 'URL вебхука для Whatsapp скопирован в буфер обмена',
    cn: 'Whatsapp webhook URL已保存到剪贴板',
  },
  'URL AZURE webhook is copied to clipboard': {
    eng: 'Azure Bot Service webhook URL copied to clipboard',
    ru: 'URL вебхука для Azure Bot Service скопирован в буфер обмена',
    cn: 'Azure Bot服务的webhook URL已保存到剪贴板',
  },
  'URL CHAT_WIDGET webhook is copied to clipboard': {
    eng: 'Widget insertion code copied to clipboard',
    ru: 'Код для вставки виджета \n скопирован в буфер',
    cn: '小部件插入代码已保存到剪贴板',
  },
  'URL SBER webhook is copied to clipboard': {
    eng: 'Salut webhook URL copied to clipboard',
    ru: 'URL вебхука для Салют скопирован в буфер обмена',
  },
  'URL EDNA_PLATFORM webhook is copied to clipboard': {
    eng: 'edna WhatsApp 2.0 webhook URL copied to clipboard',
    ru: 'URL вебхука для edna WhatsApp 2.0 скопирован в буфер обмена',
  },
  'URL PROMPTER_API webhook is copied to clipboard': {
    eng: 'Prompter API URL copied to clipboard',
    ru: 'URL вебхука для Prompter API скопирован в буфер обмена',
  },
  'Set up transfer to operator': {
    eng: 'Set up transfer to the agent',
    ru: 'Настроить перевод на оператора',
    cn: '设置转移到人工客服',
  },
  'TransferToAgent:SetUp': {
    eng: 'Set up the “Transferred to agent” report',
    ru: 'Настроить перевод на оператора',
    cn: '设置“转移到接线员”报告',
  },
  'Set password': {
    eng: 'Specify a new password',
    ru: 'Придумайте новый пароль',
    cn: '提供一个新密码',
  },
  'GUEST_USER access sub text': {
    eng: 'You need to provide read and write access to the user $[1]',
    ru: 'Вам необходимо предоставить доступ на запись и чтение пользователю $[1]',
    cn: '需要为用户$[1]提供读写访问权限',
  },
  'According to the text in the answer': {
    eng: 'According to the text in the answer',
    ru: 'По тексту в ответе',
    cn: '根据答案中的文字',
  },
  'More about this': {
    eng: 'More about this',
    ru: 'Подробнее об этом',
    cn: '关于这个的更多信息',
  },
  Total: {
    eng: 'Total',
    ru: 'Всего',
    cn: '总数',
  },
  'Total tooltip': {
    eng: 'The value of the total number of sessions for the selected period',
    ru: 'Значение суммарного количества сессий за выбранный период',
    cn: '选定期间的会话总数的值',
  },
  'Total requests tooltip': {
    eng: 'Total requests tooltip',
    ru: 'Общее количество запросов за выбранный период',
    cn: '请求提示总数',
  },
  'PERSONAL_USER access sub text': {
    eng: ' ',
    ru: ' ',
  },
  'Guest access': {
    eng: 'Guest',
    ru: 'Гостевой',
    cn: '访客',
  },
  PERSONAL_USER: {
    eng: 'Personal account',
    ru: 'Собственная учётная запись',
    cn: '个人账户',
  },
  GUEST_USER: {
    eng: 'Guest',
    ru: 'Гостевой',
    cn: '访客',
  },
  'Personal access': {
    eng: 'Personal account',
    ru: 'Своя учётная запись',
    cn: '个人账户',
  },
  ChooseTemplateAndLanguage: {
    eng: 'Select an NLU language and a project template',
    ru: 'Выберите язык проекта и шаблон',
  },
  'Attention on external repo': {
    eng: 'Attention! If you select a template option, all data in your repository will be overwritten.',
    ru: 'Внимание! Если вы выбираете шаблон, то все текущие данные в вашем хранилище будут перезаписаны',
    cn: '注意！如果选择模板选项，存储库中的所有数据都将被覆盖。',
  },
  'Internal repo': {
    eng: 'Internal repo',
    ru: 'Внутреннее',
    cn: '内部存储库',
  },
  Access: {
    eng: 'Access',
    ru: 'Доступ',
    cn: '访问',
  },
  'Voice assistant title': {
    eng: 'VOICE ASSISTANTS',
    ru: 'ГОЛОСОВЫЕ АССИСТЕНТЫ',
    cn: '语音助手',
  },
  'Voice channels title': {
    eng: 'Customer engagement platforms',
    ru: 'Операторские каналы',
    cn: '客户参与平台',
  },
  'voice channel testing': {
    eng: 'Test connection to telephony channel',
    ru: 'Тестирование телефонного канала',
    cn: '电话通道的测试连接',
  },
  'Voice channel': {
    eng: 'Phone channel ',
    ru: 'Телефонный канал',
    cn: '电话通道',
  },
  'Messengers title': {
    eng: 'MESSENGERS AND SOCIAL NETWORKS',
    ru: 'МЕССЕНДЖЕРЫ И СОЦСЕТИ',
    cn: 'MESSENGERS与社交网络',
  },
  'Others title': {
    eng: 'OTHERS',
    ru: 'ДРУГИЕ',
    cn: '其他',
  },
  'Back button': {
    eng: 'Back',
    ru: 'Назад',
    cn: '后退',
  },
  'Back to call task list': {
    eng: 'Back to the list of call campaigns',
    ru: 'Вернуться к обзвонам',
    cn: '返回活动列表',
  },
  'Yandex Oauth token': {
    eng: 'Yandex Oauth token',
    ru: 'OAuth token',
    cn: 'Yandex Oauth令牌',
  },
  'Webhook label': {
    eng: 'Webhook',
    ru: 'Вебхук',
  },
  'paste webhook token': {
    eng: 'Make up a short name or, if you already have a webhook, enter the code as shown in the picture.',
    ru: 'Придумайте короткое имя или если у вас уже есть вебхук, то введите код, как показано на картинке',
    cn: '请创建一个简称，或者，如果您已经有了webhook，请输入如图所示的代码。',
  },
  'connect GOOGLE': {
    eng: 'How to connect Google Assistant?',
    ru: 'Как подключить Google Assistant?',
    cn: '如何连接Google助手',
  },
  'Connect Google link': {
    eng: '#{config.botadmin.helpUrl}/channels/google_assistant/channel_google_assistant',
    ru: '#{config.botadmin.helpUrl}/channels/google_assistant/channel_google_assistant',
  },
  'Connect Google link JAICF': {
    eng: '#{config.botadmin.helpUrl}/channels/google_assistant/jaicf',
    ru: '#{config.botadmin.helpUrl}/channels/google_assistant/jaicf',
  },
  Triggers: {
    eng: 'Triggers',
    ru: 'Триггеры',
    cn: '触发器',
  },
  'Valid token': {
    eng: 'Valid token',
    ru: 'Валидный токен',
    cn: '有效令牌',
  },
  Test: {
    eng: 'Test',
    ru: 'Тестировать',
    cn: '测试',
  },
  Stop: {
    eng: 'Stop',
    ru: 'Остановить',
    cn: '停止',
  },
  Import: {
    eng: 'Import',
    ru: 'Импорт',
    cn: '导入',
  },
  'Into account': {
    eng: 'Into account',
    ru: 'В аккаунт',
    cn: '进入帐户',
  },
  Administrators: {
    eng: 'Administrators',
    ru: 'Администраторы',
    cn: '管理员',
  },
  defaultv2: {
    eng: 'Default',
    ru: 'По умолчанию',
    cn: '默认',
  },
  defaultv3: {
    eng: 'Default (beta)',
    ru: 'По умолчанию (beta)',
  },
  lancomev2: {
    eng: 'Pink',
    ru: 'Розовая',
    cn: '粉色',
  },
  mcdonalds: {
    eng: 'Yellow',
    ru: 'Желтая',
    cn: '黄色',
  },
  aeroexpressv2: {
    eng: 'Red',
    ru: 'Красная',
    cn: '红色',
  },
  'Load all': {
    eng: 'Load all',
    ru: 'Загрузить все',
    cn: '加载全部',
  },
  Interface: {
    eng: 'Interface',
    ru: 'Интерфейс',
    cn: '界面',
  },
  'Dont care': {
    eng: 'Don’t worry, it’s just 404 error. Check the requested page address or return to the main page',
    ru: 'Не переживайте, это всего лишь 404 ошибка, проверьте вводимый адрес или перейдите на страницу с ботами',
    cn: '别担心，这只是404错误。请检查请求的页面地址或返回主页面',
  },
  'Please fill in Comments field': {
    eng: 'Please fill in Comments field',
    ru: 'Поле комментарий обязательно \n для заполнения',
    cn: '请填写评价字段',
  },
  'Please select at least one day': {
    eng: 'Please select at least one day',
    ru: 'Пожалуйста, выберите хотя бы один день',
    cn: '请选择至少一天',
  },
  'Please confirm changes of automatic additional user packages policy': {
    eng: 'Please confirm changes of automatic additional user packages policy',
    ru: 'Изменить режим автоматического подключения дополнительных пакетов уников?',
    cn: '请确认自动用户附加套餐政策的更改',
  },
  'Scenario deployment results': {
    eng: 'Script deployment results',
    ru: 'Результат публикации сценария',
    cn: '方案部署结果',
  },
  'Publishing scenario': {
    eng: 'The script is being deployed',
    ru: 'Сценарий публикуется',
    cn: '脚本发布中',
  },
  'Changes in the scenario': {
    eng: 'Changes in the script',
    ru: 'Изменения в сценарии',
    cn: '方案变化',
  },
  'Some files of the scenario have been changed': {
    eng: 'Some files of the script have been changed. \n Comments: ',
    ru: 'Некоторые файлы сценариев изменены.\n Комментарий изменения: ',
    cn: '方案的某些文件已更改。\n 评价:',
  },
  Unblock: {
    eng: 'Unblock',
    ru: 'Разблокировать',
    cn: '解除阻止',
  },
  'Cut class': {
    eng: 'Cut class',
    ru: 'Вырезать класс',
    cn: '剪切类',
  },
  'Put class': {
    eng: 'Put class',
    ru: 'Вставить класс',
    cn: '插入类',
  },
  Rename: {
    eng: 'Rename',
    ru: 'Переименовать',
    cn: '重命名',
  },
  'Do you really want to remove this file': {
    eng: 'Do you really want to remove this file ',
    ru: 'Вы действительно хотите удалить файл ',
    cn: '确认删除此文件',
  },
  'Do you really want to empty this folder': {
    eng: 'Do you really want to empty this folder ',
    ru: 'Вы действительно хотите удалить все файлы в папке ',
    cn: '确认清空此文件夹',
  },
  'Folder name placeholder': {
    eng: 'Folder name',
    ru: 'Имя папки',
    cn: '文件夹名',
  },
  'File name placeholder': {
    eng: 'File name',
    ru: 'Имя файла',
    cn: '文件名',
  },
  'File size should not exceed 2 MB': {
    eng: 'File size: no more than 2 MB',
    ru: 'Размер файла: не более 2 МБ',
    cn: '文件大小：不超过2MB',
  },
  'File format is not allowed': {
    eng: 'File format is not supported. Please upload a file with XLS, XLSX, CSV extension',
    ru: 'Разрешается загружать только файлы формата XLS, XLSX, CSV',
    cn: '不支持文件格式。请上载扩展名为xls、xlsx、csv的文件',
  },
  'Cancel edit page': {
    eng: 'Cancel',
    ru: 'Отменить',
    cn: '取消',
  },
  'cancel plan': {
    eng: 'Cancel subscription',
    ru: 'Отключить подписку',
    cn: '取消套餐',
  },
  'cancel plan header $[1]': {
    eng: 'Turn autopay off?',
    ru: 'Отключить автоплатёж?',
  },
  'cancel plan due date $[1]': {
    eng: 'Your bots will continue to work until {endTariffDate} Then the payment will be suspended, and the bots will stop working.',
    ru: 'Боты продолжат работать до {endTariffDate} Потом оплата списываться не будет, работа ботов будет приостановлена.',
  },
  'cancel plan decline btn': {
    eng: 'No, do not disable the subscription',
    ru: 'Нет, не отключать подписку',
    cn: '不，不停用套餐',
  },
  'cancel plan accept btn': {
    eng: 'Yes, disable the subscription',
    ru: 'Да, отключить подписку',
    cn: '好的，停用套餐',
  },
  'A folder with this name already exists': {
    eng: 'A folder with this name already exists',
    ru: 'Папка с таким именем уже существует',
    cn: '此文件夹名已经存在',
  },
  'A file with this name already exists': {
    eng: 'A file with this name already exists',
    ru: 'Такой файл уже существует',
    cn: '此文件名已经存在',
  },
  'Filenames can contain ASCII characters only': {
    eng: 'Filenames can contain ASCII characters only',
    ru: 'Название файла может состоять только из латинских символов',
    cn: '文件名只能包含ASCII字符',
  },
  'Filenames cannot contain spaces': {
    eng: 'Filenames cannot contain spaces',
    ru: 'Название файла не может состоять из пробелов',
    cn: '文件名不能包含空格',
  },
  'Files without extensions and with names matching existing directory names are not allowed': {
    eng: 'Files without extensions and with names matching existing directory names are not allowed',
    ru: 'Нельзя создать файлы без расширения совпадающий именем с директорией',
    cn: '不允许没有扩展名以及与现有目录名匹配的文件名。',
  },
  'Files/folders with names matching those of other folders/files are not allowed': {
    eng: 'Files/folders with names matching those of other folders/files are not allowed',
    ru: 'Нельзя создать директорию/файл с совпадением имени с файлом/директорией',
    cn: '不允许名称与其他文件夹/文件的名称匹配的文件/文件夹',
  },
  Time: {
    eng: 'Time',
    ru: 'Время',
    cn: '时间',
  },
  'End date': {
    eng: 'End date',
    ru: 'Конечная дата',
    cn: '结束日期',
  },
  'caseSensitive on': {
    eng: 'Case sensitivity on',
    ru: 'Включить чуствительность к регистру',
    cn: '开启区分大小写功能',
  },
  'caseSensitive off': {
    eng: 'Case sensitivity off',
    ru: 'Выключить чувствительность к регистру',
    cn: '关闭区分大小写功能',
  },
  'wholeWord on': {
    eng: 'Match whole word on',
    ru: 'Включить поиск по слову',
    cn: '开启全字符匹配',
  },
  'wholeWord off': {
    eng: 'Match whole word off',
    ru: 'Выключить поиск по слову',
    cn: '关闭全字符匹配',
  },
  'regExp on': {
    eng: 'Regular Expressions On',
    ru: 'Включить регулярные выражения',
    cn: '开启正则表达式',
  },
  'regExp off': {
    eng: 'Regular Expressions Off',
    ru: 'Выключить регулярные выражения',
    cn: '关闭正则表达式',
  },
  notFoundFileSearch: {
    eng: 'No results have been found for this search',
    ru: 'По вашему запросу совпадений не найдено',
    cn: '未找到此搜索的结果',
  },
  Timezone: {
    eng: 'Timezone',
    ru: 'Часовой пояс',
    cn: '时区',
  },
  'Unrecognized requests': {
    eng: 'Unrecognized requests',
    ru: 'Нераспознанные запросы',
    cn: '无法识别的请求',
  },
  'Unrecognized requests tooltip': {
    eng: 'Unrecognized requests tooltip',
    ru: 'Общее количество нераспознанных запросов за выбранный период',
    cn: '无法识别的请求提示',
  },
  'Unrecognized requests count': {
    eng: 'Unrecognized requests',
    ru: 'Нераспознанных запросов',
    cn: '无法识别的请求',
  },
  'Setting unrecognized requests': {
    eng: 'Setting unrecognized requests',
    ru: 'Настройка нераспознанных запросов',
    cn: '设置无法识别的请求',
  },
  'What is the state?': {
    eng: 'What is the state?',
    ru: 'Что такое стейт?',
    cn: '什么状态？',
  },
  States: {
    eng: 'States',
    ru: 'Стейты',
    cn: '状态',
  },
  '+ Add more': {
    eng: '+ Add more',
    ru: '+ Добавить еще',
    cn: '添加更多',
  },
  Remove: {
    eng: 'Remove',
    ru: 'Удалить',
    cn: '删除',
  },
  'Must be filled with at least one state': {
    eng: 'Must be filled with at least one state',
    ru: 'Должен быть заполнен хотя бы один стейт',
    cn: '必须填写至少一种状态',
  },
  'Requests count': {
    eng: 'Requests count',
    ru: 'Всего запросов',
    cn: '请求计数',
  },
  'Percent unrecognized requests': {
    eng: 'Percent unrecognized requests',
    ru: 'Процент нераспознанных запросов',
    cn: '无法识别的请求比例',
  },
  'Upload call list info': {
    eng: 'For successful import, all phone numbers must be listed in the first column of the first sheet of the table, other columns/lists will be ignored.',
    ru: 'Для успешного импорта все номера должны быть перечислены в первом столбце на первом листе таблицы, другие столбцы/листы будут проигнорированы.',
  },
  'Example of a correct table': {
    eng: 'An example of valid table',
    ru: 'Пример правильной таблицы',
    cn: '有效表单示例',
  },
  'Attach file': {
    eng: 'Attach a file',
    ru: 'Прикрепите файл',
    cn: '添加附件',
  },
  'or drop it here': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
    cn: '或者丢弃于此处',
  },
  'Allowed file formats XLS, XLSX, CSV': {
    eng: 'Allowed file formats: XLS, XLSX, CSV',
    ru: 'Разрешённые форматы: XLS, XLSX, CSV',
    cn: '允许的文件格式: XLS, XLSX, CSV',
  },
  'allowed patterns disabled info': {
    eng: 'Expanding intents with entities is only allowed for the STS classification algorithm. Select it in the project settings to use this feature.',
    ru: 'Расширение интентов сущностями работает только с классификатором STS. Выберите классификатор STS в настройках проекта для использования данной возможности.',
  },
  'numbers will be processed.': {
    eng: 'phone numbers will be processed.',
    ru: 'номеров будут обработаны.',
    cn: '即将处理电话号码。',
  },
  'Numbers processed': {
    eng: 'Numbers processed',
    ru: 'Обработано номеров',
    cn: '已处理的数量',
  },
  'numbers of numbers': {
    eng: 'of',
    ru: 'из',
    cn: '从',
  },
  'Numbers not found': {
    eng: 'No phone numbers found',
    ru: 'Номера не найдены',
    cn: '未找到电话号码',
  },
  'I confirm that the list of numbers was received with the consent of subscribers': {
    eng: 'I confirm that all phone numbers are obtained with the consent of the customers',
    ru: 'Подтверждаю, что список номеров получен с согласия абонентов',
    cn: '我确认所有电话号码都是在订阅者同意的情况下获得的',
  },
  'I agree with': {
    eng: 'I agree with ',
    ru: 'Я согласен(на) c',
    cn: '我同意',
  },
  'Line:': {
    eng: 'Line:',
    ru: 'Строка:',
    cn: '线路',
  },
  'Duplicated line:': {
    eng: 'Duplicated line',
    ru: 'Дублирующая строка',
    cn: '复制线路',
  },
  'Duplicates line': {
    eng: 'Duplicates line',
    ru: 'Дубликат строки',
    cn: '复制线路',
  },
  'Bad format': {
    eng: 'Wrong format',
    ru: 'Неправильный формат',
    cn: '错误格式',
  },
  'Only one file allowed': {
    eng: 'Only one file is allowed for uploading',
    ru: 'Разрешается загружать только один файл',
    cn: '仅允许上传一个文件',
  },
  'Only incoming': {
    eng: 'only inbound calls',
    ru: 'Только входящие',
    cn: '仅呼入',
  },
  'Only outgoing': {
    eng: 'only outbound calls',
    ru: 'Только исходящие',
    cn: '仅呼出',
  },
  'Maximum file size exceeded': {
    eng: 'File size exceeds $[1] Mb',
    ru: 'Размер файла превышает лимит в $[1] Мб',
    cn: '文件大小超过$[1]MB',
  },
  'Login@host': {
    eng: 'Login@host',
    ru: 'Логин@хост',
  },
  'Lines count': {
    eng: 'Number of lines',
    ru: 'Кол-во линий',
    cn: '线路数量',
  },
  'Main telephony settings': {
    eng: 'Main settings',
    ru: 'Основные настройки',
    cn: '主要设置',
  },
  'Take incoming calls': {
    eng: 'Allow inbound calls',
    ru: 'Принимать входящие звонки',
    cn: '允许呼入',
  },
  'Host / IP': {
    eng: 'Host / IP',
    ru: 'Хост / IP',
    cn: '主机 / IP',
  },
  Port: {
    eng: 'Port',
    ru: 'Порт',
    cn: '端口',
  },
  'Registration need': {
    eng: 'Registration is required',
    ru: 'Требуется регистрация',
    cn: '要求注册',
  },
  'Registration need popover': {
    eng: 'Registration might be required for handling inbound calls from the provider if SIP trunk with authentication is being used',
    ru: 'Регистрация может быть необходима для приема входящих вызовов от провайдера, если используется SIP-транк с аутентификацией',
    cn: '可能要求注册，以便于在SIP中继身份验证被占用的情况下处理呼入',
  },
  Protocol: {
    eng: 'Protocol',
    ru: 'Протокол',
    cn: '协议',
  },
  'Sound codecs': {
    eng: 'Sound codecs',
    ru: 'Звуковые кодеки',
    cn: '声音编解码器',
  },
  'Sound codecs text': {
    eng: 'Please note that the order of codecs listed is taken into account',
    ru: 'Имейте в виду, что учитывается порядок выбранных кодеков в списке',
    cn: '请注意，所列编解码器的顺序已考虑在内',
  },
  Healthcheck: {
    eng: 'Period for checking',
    ru: 'Период проверки',
    cn: '检查期',
  },
  Telephony: {
    eng: 'Telephony',
    ru: 'Телефония',
    cn: '电话',
  },
  Integration: {
    eng: 'Integrations',
    ru: 'Интеграции',
  },
  'Name/calls': {
    eng: 'Name / type of calls',
    ru: 'Название / звонки',
    cn: '呼叫名称/类型',
  },
  Lines: {
    eng: 'Lines',
    ru: 'Линии',
    cn: '线路',
  },
  Call: {
    eng: 'Call',
    ru: 'Позвонить',
    cn: '呼叫',
  },
  Close: {
    eng: 'Close',
    ru: 'Закрыть',
    cn: '关闭',
  },
  'siptrunk incoming denied': {
    eng: 'Inbound calls are not allowed for this SIP trunk',
    ru: 'Для данного подключения отключен прием входящих звонков',
    cn: '此SIP中继不允许呼入',
  },
  'SIP trunk connection': {
    eng: 'SIP trunk connection',
    ru: 'Подключение',
    cn: 'SIP中继连接',
  },
  'SIP Headers': {
    eng: 'SIP headers',
    ru: 'SIP-заголовки',
    cn: 'SIP报文头',
  },
  'sip headers must be unique': {
    eng: 'SIP headers should be unique',
    ru: 'SIP-заголовки должны быть уникальны. Измените заголовок или удалите поле',
    cn: 'SIP报头应唯一',
  },
  'sip.trunk.not.unique': {
    eng: 'A connection to this SIP trunk already exists. UUID: $[1]',
    ru: 'Такой SIP транк уже существует. UUID: $[1]',
  },
  'sip.trunk.is.used.by.channels': {
    eng: 'The connection to this SIP trunk cannot be deleted. Make sure that this connection is not used by the following channels: $[1]',
    ru: 'Невозможно удалить подключение. Пожалуйста, убедитесь, что подключение не используется ни в одном из каналов: $[1]',
  },
  'sip.trunk.is.used.by.incoming.channel': {
    eng: 'You cannot suspend inbound calls for this connection as it is being used by the channel',
    ru: 'Невозможно отключить прием входящих звонков, данное подключение уже используется для канала, который может принимать входящие вызовы',
    cn: '您不能挂断该连接的呼入，因为通道正在使用该连接。',
  },
  'sip.trunk.not.found': {
    eng: 'Connection to SIP trunk is not found',
    ru: 'Подключение не найдено',
    cn: '找不到SIP中继的连接',
  },
  'sip.trunk.not.incoming': {
    eng: 'This channel will not handle inbound calls as the selected connection does not allow inbound calls',
    ru: 'Невозможно подключить канал с приемом входящих звонков, т.к. выбранное подключение не поддерживает входящие вызовы',
    cn: '此通道将不会处理呼入，因为所选连接不允许呼入',
  },
  'sip.trunk.incoming.used': {
    eng: 'You cannot use inbound calls in this channel as the selected connection is already being used for another channel',
    ru: 'Невозможно подключить канал с приемом входящих звонков, т.к. выбранное подключение уже используется каналом с приемом входящих звонков',
    cn: '此通道的呼入不可用，因为所选连接已经被其他通道占用。',
  },
  'Update connection': {
    eng: 'Update',
    ru: 'Обновить',
    cn: '更新',
  },
  'Pause call task': {
    eng: 'Pause a campaign',
    ru: 'Приостановить обзвон',
    cn: '暂停活动',
  },
  totalNumbers: {
    eng: 'Total phone numbers',
    ru: 'Всего номеров',
    cn: '电话号码总数',
  },
  'When to call subscribers': {
    eng: 'When to call customers',
    ru: 'Когда можно звонить абонентам',
    cn: '用户呼叫时间',
  },
  'Which time to call subscribers. Calls allowed in working hours on weekdays by default': {
    eng: 'What time voicebot will call customers. By default, calls are allowed during working hours on weekdays',
    ru: 'В какое время можно звонить абонентам. По умолчанию обзвоны разрешены в рабочие часы по будним дням',
    cn: '语音机器人什么时候给用户打电话。默认情况下，允许在工作日的工作时间内拨打电话',
  },
  'Local time': {
    eng: 'Use customer local time',
    ru: 'Учитывать местное время',
    cn: '使用用户当地时间',
  },
  'local time tooltip': {
    eng: 'The calls will be scheduled for the customers according to their time zone depending on their phone number. If not selected, the time zone of your browser will be used',
    ru: 'Время начала и окончания обзвона будет рассчитано с учетом часового пояса абонента. Если опция не выбрана, то будет использован часовой пояс вашего браузера',
    cn: '根据用户电话号码的时区为他们安排通话。如果未选择，则使用浏览器的时区',
  },
  'with delay between calls': {
    eng: 'Pause between calls in minutes',
    ru: 'Пауза между дозвонами (мин)',
  },
  Now: {
    eng: 'Right now',
    ru: 'Сейчас',
    cn: '即刻',
  },
  'Plan to': {
    eng: 'Schedule for',
    ru: 'Запланировать на',
    cn: '计划',
  },
  'Plan mailing': {
    eng: 'Schedule call campaign',
    ru: 'Запланировать обзвон',
    cn: '计划活动',
  },
  'plan cost': {
    eng: 'Cost',
    ru: 'Стоимость',
    cn: '费用',
  },
  Error: {
    eng: 'Error',
    ru: 'Ошибка',
    cn: '错误',
  },
  mon: {
    eng: 'Mon',
    ru: 'Пн',
    cn: '星期一',
  },
  tue: {
    eng: 'Tue',
    ru: 'Вт',
    cn: '星期二',
  },
  wed: {
    eng: 'Wed',
    ru: 'Ср',
    cn: '星期三',
  },
  thu: {
    eng: 'Thu',
    ru: 'Чт',
    cn: '星期四',
  },
  fri: {
    eng: 'Fri',
    ru: 'Пт',
    cn: '星期五',
  },
  sat: {
    eng: 'Sat',
    ru: 'Сб',
    cn: '星期六',
  },
  sun: {
    eng: 'Sun',
    ru: 'Вс',
    cn: '星期日',
  },
  End: {
    eng: 'End',
    ru: 'Окончание',
    cn: '结束',
  },
  'visible only for sadmin': {
    eng: 'Available only for superadmin role',
    ru: 'Видимый только для суперпользователя',
    cn: '仅超级管理员角色可用',
  },
  Metric: {
    eng: 'Metric',
    ru: 'Метрика',
    cn: '公制',
  },
  Quantity: {
    eng: 'Quantity',
    ru: 'Количество',
    cn: '数量',
  },
  'Will be updated in:': {
    eng: 'Will be updated in:',
    ru: 'Обновится через:',
    cn: '更新将会在',
  },
  'report.resultCalls': {
    eng: 'Calls with result',
    ru: 'Звонков с результатом',
    cn: '有结果的呼叫',
  },
  'report.inProcessCalls': {
    eng: 'Calls in progress',
    ru: 'Звонков в процессе',
    cn: '处理中的呼叫',
  },
  'report.noAnswerCalls': {
    eng: 'Calls without answer',
    ru: 'Звонков без ответа абонентов',
    cn: '未应答的呼叫',
  },
  'report.noResultCalls': {
    eng: 'Answered calls without result',
    ru: 'Звонков без результата',
    cn: '已应答无结果的呼叫',
  },
  'report.plannedCalls': {
    eng: 'Planned calls',
    ru: 'Запланированных звонков',
    cn: '计划呼叫',
  },
  'report.notDoneCalls': {
    eng: 'Not made calls',
    ru: 'Несовершённых звонков',
    cn: '未拨打的呼叫',
  },
  'report.processedCalls': {
    eng: 'Answered calls',
    ru: 'Состоявшихся звонков',
    cn: '已应答的呼叫',
  },
  'report.retryCalls': {
    eng: 'Another call attempt scheduled',
    ru: 'В очереди на повторный звонок',
    cn: '计划再次呼叫',
  },
  'report.withLongTalkCalls': {
    eng: 'Answered calls',
    ru: 'Состоявшихся звонков',
    cn: '已应答呼叫',
  },
  'report.callDurationAverage': {
    eng: 'Average call duration',
    ru: 'Средняя длительность разговора',
    cn: '平均通话时长',
  },
  'report.callDurationAverageTooltip': {
    eng: 'Average talk time for all answered calls in the campaign',
    ru: 'Средняя продолжительность разговора для состоявшихся звонков в текущем обзвоне',
    cn: '所有已应答呼叫的平均通话时长',
  },
  'report.asrUsedSeconds': {
    eng: 'ASR used',
    ru: 'Использовано минут ASR',
    cn: '使用ASR',
  },
  'report.usedSeconds': {
    eng: 'NLU minutes used',
    ru: 'Использовано NLU-минут',
  },
  'report.usedSecondsTooltip': {
    eng: 'Total NLU minutes used for all answered calls in the campaign',
    ru: 'Общее количество минут NLU, использованных для состоявшихся звонков',
  },
  'report.asrUsedSecondsTooltip': {
    eng: 'Total number of minutes used for speech recognition and speech synthesis for all answered calls in the campaign',
    ru: 'Общее количество минут распознавания и синтеза речи, использованных для состоявшихся звонков',
    cn: '活动中所有已应答呼叫的语音识别和语音合成所用的总分钟数',
  },
  'report.totalCalls': {
    eng: 'Total calls',
    ru: 'Всего вызовов',
    cn: '呼叫总数',
  },
  'Minutes used': {
    eng: 'ASR used',
    ru: 'Использовано минут',
    cn: '使用ASR',
  },
  'Minutes left': {
    eng: 'Telephony minutes left',
    ru: 'Осталось телефонных минут',
    cn: '通话分钟余额',
  },
  'Minutes package connection': {
    eng: 'Activate minutes package',
    ru: 'Подключение пакета минут',
    cn: '激活通话分钟包',
  },
  'Minutes count': {
    eng: 'Minute count',
    ru: 'Количество минут',
    cn: '分钟数',
  },
  'calltask required fields': {
    eng: 'Define obligatory settings',
    ru: 'Заполните обязательные поля',
    cn: '定义强制设置',
  },
  'Calltask channel warning': {
    eng: 'Important! The project must be deployed to the selected channel',
    ru: 'Важно! Проект должен быть опубликован в выбранный канал',
    cn: '重要！所选通道必须先经过发布',
  },
  Incoming: {
    eng: 'Inbound',
    ru: 'Входящий',
    cn: '呼入',
  },
  Outgoing: {
    eng: 'Outbound',
    ru: 'Исходящий',
    cn: '呼出',
  },
  'Dialer filters': {
    eng: 'Campaign filters',
    ru: 'Фильтры для обзвона',
    cn: '活动筛选',
  },
  'Mailing results': {
    eng: 'Call results',
    ru: 'Результаты звонка',
    cn: '呼叫结果',
  },
  'Mailing result': {
    eng: 'Call result',
    ru: 'Результат звонка',
    cn: '呼叫结果',
  },
  'Include mailings without result': {
    eng: 'Show calls without result',
    ru: 'Показывать звонки без результата',
    cn: '显示无结果的呼叫',
  },
  'Make sure that problem is solved before resuming the campaign': {
    eng: 'Make sure that problem is solved before resuming the campaign',
    ru: 'Пожалуйста, убедитесь, что причина приостановки обзвона устранена, и повторите попытку',
    cn: '确保在活动恢复之前解决问题!',
  },
  'Make partner': {
    eng: 'Make partner',
    ru: 'Сделать партнером',
    cn: '成为合作伙伴',
  },
  'Stop campaign': {
    eng: 'Stop campaign',
    ru: 'Остановить обзвон',
    cn: '停止活动',
  },
  'Campaign report will be deleted along with the campaign. This actions cannot be reverted. Are you sure?': {
    eng: 'Campaign report will be deleted along with the campaign. This actions cannot be reverted. Are you sure?',
    ru: 'При удалении обзвона будет удален также отчет по обзвону, данные действия невозможно отменить. Вы уверены, что хотите удалить обзвон?',
    cn: '活动报告将与活动一起删除。此操作无法还原，是否确定？',
  },
  "Once you stop a campaign, it cannot be started again. You'll have to delete it and create new campaign. Are you sure, you want to stop the campaign?":
    {
      eng: 'Once you stop a campaign, it cannot be started again. You’ll have to delete it and create new campaign. Are you sure, you want to stop the campaign?',
      ru: 'После остановки обзвон нельзя будет запустить повторно. Вам придется удалить текущий и создать обзвон заново. Вы действительно хотите остановить обзвон?',
      cn: '活动一旦停止，将不能重新开始。您必须删除它并创建新的活动。确定要停止该活动吗？',
    },
  "Unable to delete call list. It's used in mailings:": {
    eng: 'Unable to delete number list. It’s used in campaigns:',
    ru: 'Невозможно удалить список номеров. Он используется в обзвонах:',
    cn: '无法删除数字列表。该数字列表被用于活动中',
  },
  Tariff: {
    eng: 'Subscription plan',
    ru: 'Тариф',
    cn: '费率',
  },
  'Automatic package connection': {
    eng: 'Automatic activation of 2 packages',
    ru: 'Автоматическое подключение 2 пакетов',
    cn: '自动激活2个包',
  },
  'Unlock account': {
    eng: 'Unlock account',
    ru: 'Разблокировать аккаунт',
    cn: '解锁账户',
  },
  Administrator: {
    eng: 'Administrator',
    ru: 'Администратор',
    cn: '管理员',
  },
  Billing: {
    eng: 'Subscription plan',
    ru: 'Тариф',
    cn: '费率计划表',
  },
  'intents page': {
    eng: 'Intents',
    ru: 'Интенты',
    cn: '意图',
  },
  'Intents auto extend': {
    eng: 'Automatically expand intents',
    ru: 'Автоматически расширять интенты',
  },
  'entities page': {
    eng: 'Entities',
    ru: 'Сущности',
    cn: '实体',
  },
  'Entities docs link': {
    eng: '/platform_ux/nlu_core/entities',
    ru: '/platform_ux/nlu_core/entities',
  },
  'ClientsList noEmptySessions': {
    eng: 'Hide empty sessions',
    ru: 'Скрыть пустые сессии',
    cn: '隐藏空会话',
  },
  'Retry if hangup before seconds of talking': {
    eng: 'Redial if the call lasted less than (sec)',
    ru: 'Перезванивать, если звонок был менее (сек)',
  },
  'port must be valid': {
    eng: 'The value of the Port field must be from 1 to 65535',
    ru: 'Значение поля порт должно быть от 1 до 65535',
    cn: '端口字段的值必须介于1到65535之间',
  },
  'Description task': {
    eng: 'Description',
    ru: 'Описание',
    cn: '描述',
  },
  'Plans and Keys': {
    eng: 'Pricing and keys',
    ru: 'Тарифы и ключи',
    cn: '定价和密钥',
  },
  'Plans and keys management': {
    eng: 'Manage Pricing and Keys',
    ru: 'Управление тарифом и ключами',
    cn: '管理定价和密钥',
  },
  'JAICP not activated': {
    eng: '$[1] is not activated',
    ru: '$[1] не активирована',
    cn: '$[1] 未激活',
  },
  'JAICP docs': {
    eng: '$[1] documentation',
    ru: 'Документация по $[1]',
    cn: '$[1] 启用手册',
  },
  'JAICP forum': {
    eng: 'JAICP Community at Zendesk',
    ru: 'Сообщество JAICP в Zendesk',
    cn: 'JAICP用户论坛',
  },
  'JAICP community': {
    eng: 'JAICP Slack Community',
    ru: 'Сообщество JAICP в Telegram',
  },
  'JAICP community url': {
    eng: 'https://join.slack.com/t/justaicommunity/shared_invite/zt-xeob7kus-3jXPDLmBMOPnWnQniDFafw',
    ru: 'https://t.me/jaicp',
  },
  Activate: {
    eng: 'Activate',
    ru: 'Активировать',
    cn: '激活',
  },
  lock_FULL: {
    eng: 'Full lock',
    ru: 'Полное отключение',
    cn: '全部锁定',
  },
  lock_NOT_PAYED: {
    eng: 'Blocking for non-payment',
    ru: 'Блокировка за неуплату',
    cn: '未付款冻结',
  },
  lock_NONE: {
    eng: 'Unlocked',
    ru: 'Разблокирован',
    cn: '解锁',
  },
  Active: {
    eng: 'Active',
    ru: 'Активный',
    cn: '有效',
  },
  Inactive: {
    eng: 'Inactive',
    ru: 'Неактивный',
    cn: '无效',
  },
  Blocked: {
    eng: 'Blocked',
    ru: 'Заблокирован',
    cn: '锁定',
  },
  '$[1] unique': {
    eng: '$[1] unique users',
    ru: '$[1] уникальных пользователей',
    cn: '$[1] 独立',
  },
  'Auto add packages enabled': {
    eng: 'Auto add packages enabled',
    ru: 'Включено автоматическое добавление пакетов',
    cn: '已启用自动添加包',
  },
  'after $[1] day': {
    eng: 'after $[1] days',
    ru: 'через $[1] дней',
    cn: '$[1]天后',
  },
  Disabled: {
    eng: 'Disabled',
    ru: 'Отключено',
    cn: '停用',
  },
  Enabled: {
    eng: 'Enabled',
    ru: 'Включено',
    cn: '启用',
  },
  History: {
    eng: 'History',
    ru: 'История',
    cn: '历史',
  },
  'There are no records in history': {
    eng: 'There are no records in history',
    ru: 'В истории нет записей',
    cn: '没有历史记录',
  },
  BILLING_PERIOD_STARTED: {
    eng: 'Billing period started',
    ru: 'Начало тарификационного периода',
    cn: '计费期开始',
  },
  MONTHLY_UNIQUE_USERS_LIMIT_IS_EXCEEDED: {
    eng: 'Monthly unique users limit is exceeded',
    ru: 'Достигнут лимит уникальных пользователей',
    cn: '已超过每月独立用户限制',
  },
  TELEPHONE_MINUTES_PACKAGE_IS_OVER: {
    eng: 'Telephony minutes package expired',
    ru: 'Исчерпан пакет минут телефонии',
    cn: '电话分钟包已用完',
  },
  EXTRA_UNIQUE_USERS_PACKAGE_ENABLED: {
    eng: 'Extra $[1] unique users package enabled',
    ru: 'Подключен дополнительный пакет $[1] уникальных пользователей',
    cn: '已启用额外的$[1]独立用户包',
  },
  TELEPHONE_MINUTES_PACKAGE_ENABLED: {
    eng: '$[1] telephony minutes package enabled',
    ru: 'Подключен пакет $[1] минут телефонии',
    cn: '启用电话分钟包$[1]',
  },
  RUNNING_PROJECTS_ARE_SUSPENDED: {
    eng: 'Running projects are suspended',
    ru: 'Функционирование проектов приостановлено',
    cn: '正在运行的项目已被暂停',
  },
  ACCOUNT_IS_BLOCKED: {
    eng: 'Account is blocked',
    ru: 'Аккаунт заблокирован',
    cn: '帐户被阻止',
  },
  ACCOUNT_IS_UNBLOCKED: {
    eng: 'Account is unblocked',
    ru: 'Аккаунт разблокирован',
    cn: '帐户已解除阻止',
  },
  BILLING_DATE_IS_CHANGED: {
    eng: 'Subscription option changed to “Payment by card”. Billing date: $[1]',
    ru: 'Тип тарификации изменен на «Оплата по карте». Дата списания: $[1]',
  },
  TARIFF_IS_CHANGED: {
    eng: 'Plan changed to $[1]',
    ru: 'Тариф изменен на $[1]',
    cn: '费率更改为',
  },
  EXTRA_UNIQUE_USERS_PACKAGE_IS_CHANGED: {
    eng: 'Extra unique users package enabling mode is set to $[1]',
    ru: 'Изменен режим подключения дополнительных пакетов уникальных пользователей на $[1]',
    cn: '额外独立用户包的启用模式设置为$[1]',
  },
  BILLING_CARD_BINDING_REQUEST: {
    eng: 'Card binding is requested',
    ru: 'Запрошена привязка карты',
    cn: '要求卡绑定',
  },
  TARIFF_CHANGE_REQUEST: {
    eng: 'Plan $[1] is requested',
    ru: 'Запрошено изменение тарифа на $[1]',
    cn: '要求费率$[1]',
  },
  TELEPHONE_MINUTES_PACKAGE_REQUEST: {
    eng: '$[1] package is requested',
    ru: 'Запрошен пакет $[1]',
    cn: '要求电话分钟时长包$[1]',
  },
  PARTNER_ADD_REFERRAL_URL: {
    eng: 'Client $[1] is linked via ref link',
    ru: 'Привязан клиент $[1] по реф. ссылке',
    cn: '客户端$[1]通过ref link链接',
  },
  ACCOUNT_LINK_TO_PARTNER: {
    eng: 'Linked to partner $[1]',
    ru: 'Привязан к партнеру $[1]',
    cn: '链接到合作伙伴$[1]',
  },
  ACCOUNT_UNLINK_FROM_PARTNER: {
    eng: 'Partner $[1] is unlinked',
    ru: 'Отвязан партнер $[1]',
    cn: '未链接到合作伙伴$[1]',
  },
  PARTNER_IS_ACTIVE: {
    eng: 'Partner status set to Active',
    ru: 'Статус партнера изменен на Активный',
    cn: '设置合作伙伴状态为有效',
  },
  PARTNER_IS_BLOCKED: {
    eng: 'Partner status set to Blocked',
    ru: 'Статус партнера изменен на Заблокированный',
    cn: '设置合作伙伴状态为禁用',
  },
  PARTNER_CHANGE_DATA: {
    eng: 'Partner creds are changed to $[1]',
    ru: 'Реквизиты изменены на $[1]',
    cn: '合作伙伴信用等级更改为$[1]',
  },
  PARTNER_REMOVE_REFERRAL: {
    eng: 'Client $[1] is unlinked',
    ru: 'Отвязан клиент $[1]',
    cn: '客户端$[1]未链接',
  },
  PARTNER_ADD_REFERRAL_MANUAL: {
    eng: 'Client $[1] is linked via ref link',
    ru: 'Привязан клиент $[1] вручную',
    cn: '客户端$[1]通过ref link链接',
  },
  'ACCOUNT_UNLINK_FROM_PARTNER ': {
    eng: 'Partner $[1] is unlinked',
    ru: 'Отвязан партнер $[1]',
    cn: '合作伙伴$[1]未链接',
  },
  MANUAL_CONTROL_ENABLED: {
    eng: 'The account was switched to manual billing',
    ru: 'Аккаунт переведен на ручное управление',
  },
  MANUAL_CONTROL_DISABLED: {
    eng: 'The account was switched to automatic billing',
    ru: 'Аккаунт снят с ручного управления',
  },
  'Cleanup dialogs': {
    eng: 'Cleanup dialogs',
    ru: 'Удаление диалогов',
    cn: '清除对话',
  },
  'Cleanup period': {
    eng: 'For dialogs older than: (days)',
    ru: 'Для диалогов старше: (дней)',
    cn: '早于该天数的对话',
  },
  Editor: {
    eng: 'Editor',
    ru: 'Редактор',
    cn: '编辑器',
  },
  'Classification Algorithm': {
    eng: 'Classification algorithm',
    ru: 'Алгоритм классификатора',
    cn: '分类算法',
  },
  Intents: {
    eng: 'Intents',
    ru: 'Интенты',
    cn: '意图',
  },
  'Spelling Correction': {
    eng: 'Spelling correction',
    ru: 'Исправление орфографии',
    cn: '拼写更正',
  },
  'Spelling correction tooltip': {
    eng: 'Enable this setting to correct spelling errors before entity recognition. ',
    ru: 'Включите настройку для исправления опечаток перед распознаванием этой сущности. ',
  },
  'Spelling correction tooltip support': {
    eng: 'Spell checking is only supported for russian language',
    ru: 'Исправление опечаток для языков кроме русского не поддерживается',
  },
  'Spelling correction disabled tooltip': {
    eng: 'Spelling correction is disabled in project settings.',
    ru: 'Исправление опечаток отключено для всего проекта.',
  },
  'Spelling correction link text': {
    eng: 'Read about $[1] in the help center.',
    ru: 'Настройка исправления орфографии описана в $[1]',
  },
  'ContextMenu createIntent': {
    eng: 'Add intent',
    ru: 'Добавить интент',
    cn: '添加意图',
  },
  'ProjectEditForm_Classificator:add_file': {
    eng: 'Add a file',
    ru: 'Прикрепите файл',
    cn: '添加一个文件',
  },
  'ProjectEditForm_Classificator:or_drop_it_here': {
    eng: 'or drag and drop it here',
    ru: 'или перетащите его сюда',
    cn: '或拖拽于此处',
  },
  'Manually invoice': {
    eng: 'Manually invoice',
    ru: 'Выставлять счет вручную',
    cn: '手工发票',
  },
  'Authentication type': {
    eng: 'Authentication type',
    ru: 'Тип аутентификации',
    cn: '身份验证类型',
  },
  'replacePrefix label': {
    eng: 'Replace the first digit with',
    ru: 'Заменять первую цифру на',
    cn: '将第一位数字替换为',
  },
  LogLabeling: {
    eng: 'Log labeling',
    ru: 'Разметка логов',
    cn: '日志标记',
  },
  DataLabeling: {
    eng: 'Data labeling',
    ru: 'Разметка логов',
  },
  'FAQ:FAQBot': {
    eng: 'Knowledge base',
    ru: 'База знаний',
  },
  'Affiliate program': {
    eng: 'Affiliate program',
    ru: 'Партнерская программа',
    cn: '联盟计划',
  },
  'Affiliate payments': {
    eng: 'Affiliate payments',
    ru: 'Партнерские выплаты',
    cn: '关联支付',
  },
  'Snap to partner': {
    eng: 'Snap to partner',
    ru: 'Привязать к партнеру',
    cn: '快速转成合作伙伴',
  },
  'Link Changing': {
    eng: 'Link Changing',
    ru: 'Изменение связи',
    cn: '链接更改',
  },
  'Legal entity or individual entrepreneur': {
    eng: 'Legal entity or individual entrepreneur',
    ru: 'Юридическое лицо или ИП',
    cn: '法人实体或个体企业家',
  },
  TIN: {
    eng: 'TIN',
    ru: 'ИНН',
  },
  'Bik Bank': {
    eng: 'Bank BIC',
    ru: 'БИК банка',
    cn: 'BIC银行',
  },
  'Payment account': {
    eng: 'Payment account',
    ru: 'Расчетный счет',
    cn: '付款帐户',
  },
  'payment blocked': {
    eng: 'Blocked for non-payment',
    ru: 'Заблокирован за неуплату',
    cn: '因未付款，账户已被冻结',
  },
  'Contact phone': {
    eng: 'Contact phone',
    ru: 'Контактный телефон',
    cn: '联系电话',
  },
  'contact manager': {
    eng: 'Contact manager',
    ru: 'Написать менеджеру',
    cn: '联系管理员',
  },
  'contact support': {
    eng: 'Contact support',
    ru: 'Написать в поддержку',
  },
  'Register date': {
    eng: 'Register date',
    ru: 'Дата регистрации',
    cn: '注册日期',
  },
  partner_field_name: {
    eng: 'Legal entity or individual entrepreneur: $[1]',
    ru: 'Юридическое лицо или ИП: $[1]',
    cn: '法人实体或个体企业家：$[1]',
  },
  partner_field_inn: {
    eng: 'TIN: $[1]',
    ru: 'ИНН: $[1]',
  },
  partner_field_bic: {
    eng: 'BIC: $[1]',
    ru: 'БИК: $[1]',
  },
  partner_field_contactPhone: {
    eng: 'Phone: $[1]',
    ru: 'Телефон: $[1]',
    cn: '电话: $[1]',
  },
  'Threads Client URL': {
    eng: 'Client URL',
    ru: 'Адрес аккаунта',
    cn: '客户URL',
  },
  'Threads Token': {
    eng: 'edna.chatCenter token',
    ru: 'edna.chatCenter токен',
    cn: 'edna.chatCenter令牌',
  },
  'direct media toggle': {
    eng: 'Use direct media when transferring the call',
    ru: 'Использовать direct media при переводе вызова',
    cn: '转接电话时使用直接媒体',
  },
  'direct media toggle popover': {
    eng: 'When this option is enabled, during the call transfer, media traffic will be sent directly between customers through the RE-INVITE procedure.',
    ru: 'При включенной опции во время перевода вызова медиатрафик будет отправляться напрямую между абонентами через процедуру RE-INVITE.',
    cn: '启用此选项时，在呼叫转移期间，将通过RE-INVITE在订阅用户之间直接发送媒体流量。',
  },
  'Calls per second': {
    eng: 'Calls per second',
    ru: 'Число звонков в секунду (cps)',
    cn: '每秒的呼叫数',
  },
  'Calls per second tooltip': {
    eng: 'Maximum INVITE requests (calls) per second for outbound call campaigns',
    ru: 'Максимальное число запросов INVITE (вызовов) в секунду для обзвонов',
    cn: '呼出时每秒最大INVITE请求数（呼叫数）',
  },
  'Node CrmIntegration saved': {
    eng: 'Data is saved',
    ru: 'Данные сохранены',
    cn: '数据已保存',
  },
  'Node CrmIntegration not saved': {
    eng: 'Data is not saved',
    ru: 'Данные не сохранены',
    cn: '数据未保存',
  },
  CrmIntegration: {
    eng: 'Save to CRM',
    ru: 'Запись в CRM',
    cn: '保存到CRM',
  },
  'CrmIntegrationEditModal title': {
    eng: 'Save to CRM',
    ru: 'Запись в CRM',
    cn: '保存到CRM',
  },
  Spravka: {
    eng: 'Help',
    ru: 'Справка',
    cn: '帮助',
  },
  'PseudoButtonComponent Add example': {
    eng: 'Add an example',
    ru: 'Добавить пример',
    cn: '添加一个例子',
  },
  'IntentGroupEditModal emptyIntents title': {
    eng: 'The intents list is empty',
    ru: 'Список интентов пуст',
    cn: '意图列表为空',
  },
  'IntentGroupEditModal emptyIntents body': {
    eng: 'Create your first intent or customize one of the built-in intents.',
    ru: 'Создайте первый интент или выберите готовый и дополните его.',
    cn: '创建第一个意图或者选择一个预设模板并进行优化。',
  },
  'IntentGroupEditModal Delete group': {
    eng: 'Delete intent',
    ru: 'Удалить интент',
    cn: '删除意图',
  },
  Intent: {
    eng: 'Create an intent',
    ru: 'Создать интент',
    cn: '创建一个意图',
  },
  Blocks: {
    eng: 'Blocks menu',
    ru: 'Блоки',
    cn: '菜单扳块',
  },
  'Global block': {
    eng: 'Global intents',
    ru: 'Глобальный блок',
    cn: '全局意图',
  },
  example1: {
    eng: 'example',
    ru: 'пример',
    cn: '示例',
  },
  example2: {
    eng: 'examples',
    ru: 'примера',
    cn: '示例',
  },
  example5: {
    eng: 'examples',
    ru: 'примеров',
    cn: '示例',
  },
  include_no_result_unique_label_k2LiQXI32W: {
    eng: 'No result',
    ru: 'Без результата',
    cn: '无结果',
  },
  'Break the connection': {
    eng: 'Break the connection',
    ru: 'Разорвать связь',
    cn: '断开连接',
  },
  'Attention! This client is already tied to the partner $[1]!': {
    eng: 'Attention! This client is already tied to the partner $[1]!',
    ru: 'Внимание! Данный клиент уже привязан к партнеру $[1]!',
    cn: '注意！此客户已绑定到合作伙伴$[1]！',
  },
  'Attention! If you select Continue, then the client will be tied to the current partner': {
    eng: 'Attention! If you select Continue, then the client will be tied to the current partner',
    ru: 'Внимание! Если вы выберите «Продолжить», то клиент будет привязан к текущему партнеру',
    cn: '注意！如果选择继续，该客户将绑定到当前合作伙伴',
  },
  'accountsadmin.partners.account_linked_to_different_partner': {
    eng: 'This client is already tied to a partner $[1]',
    ru: 'Данный клиент уже привязан к партнеру $[1]',
    cn: '此客户已绑定到合作伙伴$[1]',
  },
  'accountsadmin.accounts.account_not_found': {
    eng: 'Client not found',
    ru: 'Клиент не найден',
    cn: '找不到该客户',
  },
  'accountsadmin.partners.account_already_linked': {
    eng: 'Account already linked to partner',
    ru: 'Аккаунт уже привязан к данному партнеру',
    cn: '帐户已链接到合作伙伴',
  },
  'accountsadmin.partners.partner_inn_unique': {
    eng: 'A partner with this TIN already exists',
    ru: 'Партнер с таким ИНН уже существует',
    cn: '已存在此TIN的合作伙伴',
  },
  'accountsadmin.partners.partner_already_registered': {
    eng: 'User have already register as a partner',
    ru: 'Пользователь уже зарегестрирован как партнер',
    cn: '用户已注册为合作伙伴',
  },
  'accountsadmin.partners.partner_blocked': {
    eng: 'Partner blocked',
    ru: 'Партнер заблокирован',
    cn: '合作伙伴被锁定',
  },
  'accountsadmin.accounts.account_blocked': {
    eng: 'Account blocked',
    ru: 'Aккаунт заблокирован',
    cn: '账户已被封锁',
  },
  'accountsadmin.accounts.another_zone': {
    eng: 'This email is already in use. Use another email to register',
    ru: 'Данный email уже занят. Используйте другой email для регистрации',
  },
  Continue: {
    eng: 'Continue',
    ru: 'Продолжить',
    cn: '继续',
  },
  'accountsadmin.partners.partner_adds_himself': {
    eng: 'Unable to link own account',
    ru: 'Невозможно привязать собственный аккаунт',
    cn: '无法链接自己的账户',
  },
  'accountsadmin.partners.partner_not_found': {
    eng: 'Partner not found',
    ru: 'Партнер не найден',
    cn: '无法找到合作伙伴',
  },
  'Month selection': {
    eng: 'Month selection',
    ru: 'Выбор месяца',
    cn: '月份选择',
  },
  'VisualEditor HttpRequest success': {
    eng: 'Success',
    ru: 'Завершен успешно',
    cn: '成功',
  },
  'VisualEditor HttpRequest error': {
    eng: 'Error',
    ru: 'Завершен с ошибкой',
    cn: '出错',
  },
  'VisualEditor TransferToOperator noOperatorsOnlineState': {
    eng: 'No agents available',
    ru: 'Нет свободных операторов',
    cn: '无可用的人工客服',
  },
  'VisualEditor TransferToOperator dialogCompletedState': {
    eng: 'Dialog completed',
    ru: 'Диалог завершен',
    cn: '对话结束',
  },
  'VisualEditor InputPhoneNumber afterVarNameEnter': {
    eng: 'Transition after entering a $$[1]',
    ru: 'Переход после ввода $$[1]',
    cn: '输入 $$[1] 后跳转',
  },
  'VisualEditor CrmIntegration okState': {
    eng: 'Data is saved',
    ru: 'Данные сохранены',
    cn: '数据已保存',
  },
  'VisualEditor CrmIntegration errorState': {
    eng: 'Data is not saved',
    ru: 'Данные не сохранены',
    cn: '数据未保存',
  },
  'VisualEditor fileWasDeletedModal title': {
    eng: 'The file was deleted',
    ru: 'Файл был удален',
  },
  'VisualEditor fileWasDeletedModal body': {
    eng: 'The $[1] file was deleted. Do you want to restore it?',
    ru: 'Файл $[1] был удален. Хотите его восстановить?',
  },
  'VisualEditor fileWasDeletedModal recreate': {
    eng: 'Restore',
    ru: 'Воcстановить',
  },
  'VisualEditor fileWasDeletedModal delete': {
    eng: 'Delete locally',
    ru: 'Удалить локально',
  },
  'VisualEditor ': {
    eng: '',
    ru: '',
  },
  Confirm: {
    eng: 'Confirm',
    ru: 'Подтвердить',
    cn: '确认',
  },
  Disconnected: {
    eng: 'Disconnected',
    ru: 'Отключен',
    cn: '断开',
  },
  'externalRepository tooltip': {
    eng: 'New projects are saved to the local storage by default, but you can use an external repository.',
    ru: 'По умолчанию новые проекты сохраняются в локальном хранилище, но вы можете использовать внешний репозиторий',
    cn: '默认情况下，新项目保存到本地存储，但您可以使用外部存储库。',
  },
  'projectTemplate tooltip': {
    eng: 'The project will contain an example of a working bot. You will need to place it to your service channel.',
    ru: 'Проект будет содержать готовый пример работающего бота. Вам необходимо будет разместить его в канале обслуживания',
    cn: '该项目将包含一个工作机器人的例子。您需要把它放到您的服务通道。',
  },
  'classificationAlgorithm tooltip': {
    eng: 'Intent recognition algorithm (intent tag) based on phrase examples',
    ru: 'Алгоритм распознавания интентов (тег intent) на основе примеров фраз',
    cn: '基于短语实例的意图识别算法（Intent tag）',
  },
  'ProjectEditConfig:NLU:ClassificationAlgorithm:dockLink': {
    eng: 'How to pick the right one?',
    ru: 'Как выбрать подходящий?',
  },
  'ProjectEditConfig:NLU:ClassificationAlgorithm:TransformerHint:Title': {
    eng: 'New algorithm — Transformer',
    ru: 'Новый алгоритм — Transformer',
  },
  'ProjectEditConfig:NLU:ClassificationAlgorithm:TransformerHint:Description1': {
    eng: 'Performs classification based on a massive language model, not only using separate words but also accounting for semantic relationships between them. This allows it to achieve a high quality of classification for different languages.',
    ru: 'Выполняет классификацию на основе большой языковой модели и использует не просто слова, а информацию о смысловой связи между ними. Это позволяет достигать высокого качества классификации на разных языках.',
  },
  'ProjectEditConfig:NLU:ClassificationAlgorithm:TransformerHint:Description2': {
    eng: 'Contact your account manager or send us a message at <a href="mailto:$[1]">$[1]</a> to try the free beta.',
    ru: 'Свяжитесь с вашим аккаунт-менеджером или напишите нам на <a href="mailto:$[1]">$[1]</a>, чтобы бесплатно попробовать бета-версию.',
  },
  'classificationSpellchecker tooltip': {
    eng: 'Russian and Ukrainian are supported',
    ru: 'Поддерживает русский и украинский язык',
  },
  'cailaAccessKey tooltip': {
    eng: 'Use of a trained classifier in third-party applications',
    ru: 'Для использования обученного классификатора в сторонних приложениях',
    cn: '在第三方应用程序中使用预训练的分类器',
  },
  'timezone tooltip': {
    eng: 'Defines the timezone used to parse phrases like “now”, “in 5 minutes”, etc.',
    ru: 'Определяет часовой пояс при распознавании фраз «сейчас», «через 5 минут» и т.д.',
    cn: '定义用于解析像"现在"、"5分钟内"等短语的时区。',
  },
  'nlu extended settings': {
    eng: 'Advanced NLU settings',
    ru: 'Расширенные настройки NLU',
    cn: '高级NLU设置',
  },
  'report.canceledCalls': {
    eng: 'Canceled calls',
    ru: 'Отмененные звонки',
    cn: '已取消的呼叫',
  },
  'Phone channel usage': {
    eng: 'Phone channel usage',
    ru: 'Использование телефонного канала',
    cn: '电话通道的使用',
  },
  '1 minute': {
    eng: 'minute',
    ru: 'минута',
    cn: '分',
  },
  '1 second': {
    eng: 'second',
    ru: 'секунда',
    cn: '秒',
  },
  '1 day': {
    eng: 'day',
    ru: 'день',
    cn: '天',
  },
  '2 minutes': {
    eng: 'minutes',
    ru: 'минуты',
    cn: '分',
  },
  '2 seconds': {
    eng: 'seconds',
    ru: 'секунды',
    cn: '秒',
  },
  '2 days': {
    eng: 'days',
    ru: 'дня',
    cn: '天',
  },
  '5 minutes': {
    eng: 'minutes',
    ru: 'минут',
    cn: '分',
  },
  '5 seconds': {
    eng: 'seconds',
    ru: 'секунд',
    cn: '秒',
  },
  '5 days': {
    eng: 'days',
    ru: 'дней',
    cn: '天',
  },
  'IntentEdit:titleNotSaved': {
    eng: 'Not Saved',
    ru: 'Не сохранено',
    cn: '未保存',
  },
  'IntentEdit:titleSaved': {
    eng: 'Saved',
    ru: 'Сохранено',
    cn: '已保存',
  },
  'IntentItemsEdit:pageTitle': {
    eng: 'Training phrases',
    ru: 'Тренировочные фразы',
    cn: '训练短语',
  },
  'IntentItemsEdit:emptyList': {
    eng: 'The phrase list is empty.',
    ru: 'Список фраз пуст',
    cn: '短语列表为空',
  },
  'IntentItemsEdit:selectedLabel': {
    eng: 'Selected',
    ru: 'Выбрано',
    cn: '选择',
  },
  'IntentItemsEdit:discardLabel': {
    eng: 'Cancel',
    ru: 'Отменить',
    cn: '取消',
  },
  'IntentItemsEdit:deleteLabel': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'IntentItemsEdit:buttonMinimize': {
    eng: 'Minimize',
    ru: 'Свернуть',
    cn: '最小化',
  },
  'IntentItemsEdit:buttonPattern': {
    eng: 'Pattern',
    ru: 'Паттерн',
    cn: '模版',
  },
  'IntentItemsEdit:buttonPhrase': {
    eng: 'Phrase',
    ru: 'Фраза',
    cn: '短语',
  },
  'SummaryEdit:intentNameFieldCaption': {
    eng: 'Name',
    ru: 'Название',
    cn: '名称',
  },
  'SummaryEdit:switchOnLabel': {
    eng: 'On',
    ru: 'Вкл',
    cn: '开',
  },
  'SummaryEdit:switchOffLabel': {
    eng: 'Off',
    ru: 'Выкл',
    cn: '关',
  },
  'SummaryEdit:switchLabel': {
    eng: 'Intent',
    ru: 'Интент',
    cn: '意图',
  },
  'SummaryEdit:intentDescriptionCaption': {
    eng: 'Description',
    ru: 'Комментарий',
    cn: '描述',
  },
  'SummaryEdit:intentAnswerCaption': {
    eng: 'Answer',
    ru: 'Ответ',
    cn: '答案',
  },
  'SummaryEdit:intentFaqAnnouncementHeader': {
    eng: 'How can I view the answer to this intent?',
    ru: 'Как просмотреть ответ на этот интент?',
  },
  'SummaryEdit:intentFaqAnnouncementInfo': {
    eng: 'This is the intent from the <a href="$[1]" target="_blank" rel="noreferrer noopener" tabindex="-1">knowledge base</a>. You can view and edit <a href="$[2]" target="_blank" rel="noreferrer noopener" tabindex="-1">answers</a> to such intents only in the knowledge base interface.',
    ru: 'Это интент из <a href="$[1]" target="_blank" rel="noreferrer noopener" tabindex="-1">базы знаний</a>. Для таких интентов просматривать и редактировать <a href="$[2]" target="_blank" rel="noreferrer noopener" tabindex="-1">ответы</a> можно только в интерфейсе базы знаний.',
  },
  'SummaryEdit:intentFaqAnnouncementBtn': {
    eng: 'View the answer',
    ru: 'Перейти к ответу',
  },
  'IntentsTree:exportButtonTooltip': {
    eng: 'Export',
    ru: 'Экспорт',
    cn: '导出',
  },
  'IntentsTree:treeSearchNotFound': {
    eng: 'Not found',
    ru: 'Не найдено',
    cn: '未找到',
  },
  'IntentsTree:treeEmptyList': {
    eng: 'List of project intents',
    ru: 'Список ваших интентов',
    cn: '项目意图列表',
  },
  'IntentsTree:dropdownCreateNestedIntent': {
    eng: 'Create nested intent',
    ru: 'Создать вложенный интент',
    cn: '创建嵌套意图',
  },
  'IntentsTree:dropdownDeleteIntent': {
    eng: 'Delete intent',
    ru: 'Удалить интент',
    cn: '删除意图',
  },
  'IntentsTree:dropdownRenameIntent': {
    eng: 'Rename intent',
    ru: 'Переименовать интент',
    cn: '重命名意图',
  },
  'IntentsTree:toolbarButtonCreateIntent': {
    eng: 'Create intent',
    ru: 'Создать интент',
    cn: '创建意图',
  },
  'IntentsTree:toolbarButtonImport': {
    eng: 'Import',
    ru: 'Импорт',
    cn: '导入',
  },
  'IntentsTree:intentNotExist': {
    eng: 'Intent «{{name}}» not exist',
    ru: 'Интента «{{name}}» не существует',
    cn: '意图 «{{name}}» 不存在',
  },
  'IntentsTree:defaultNewIntentName': {
    eng: 'New intent',
    ru: 'Новый интент',
    cn: '新意图',
  },
  'NamedEntityEdit:entityNameFieldCaption': {
    eng: 'Name',
    ru: 'Название',
    cn: '名称',
  },
  'NamedEntityEdit:switchOnLabel': {
    eng: 'On',
    ru: 'Вкл',
    cn: '开',
  },
  'NamedEntityEdit:switchOffLabel': {
    eng: 'Off',
    ru: 'Выкл',
    cn: '关',
  },
  'NamedEntityEdit:switchLabel': {
    eng: 'Entity',
    ru: 'Сущность',
    cn: '实体',
  },
  'NamedEntityEdit:compareLabel': {
    eng: 'Matching',
    ru: 'Сравнение',
    cn: '匹配',
  },
  'NamedEntityEdit:compareSoftMatch': {
    eng: 'Soft match',
    ru: 'Мягкое совпадение',
    cn: '模糊匹配',
  },
  'NamedEntityEdit:compareExactMatch': {
    eng: 'Exact match',
    ru: 'Точное совпадение',
    cn: '精确匹配',
  },
  'NamedEntityRecordsEdit:pageTitle': {
    eng: 'Dictionary',
    ru: 'Справочник',
    cn: '字典',
  },
  'NamedEntityRecordsEdit:recordType_synonyms': {
    eng: 'Synonyms',
    ru: 'Синонимы',
    cn: '同义词',
  },
  'NamedEntityRecordsEdit:recordType_regexp': {
    eng: 'Regexp',
    ru: 'Regexp',
    cn: '正则表达式',
  },
  'NamedEntityRecordsEdit:recordType_pattern': {
    eng: 'Pattern',
    ru: 'Паттерн',
    cn: '模版',
  },
  'NamedEntityRecordsEdit:buttonMinimize': {
    eng: 'Minimize',
    ru: 'Свернуть',
    cn: '最小化',
  },
  'NamedEntityRecordsEdit:modalDataActionButton': {
    eng: 'Save',
    ru: 'Сохранить',
    cn: '保存',
  },
  'NamedEntityRecordsEdit:modalDataCancelButton': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'NamedEntityRecordsEdit:addRecordButton': {
    eng: 'Add record',
    ru: 'Добавить запись',
    cn: '添加记录',
  },
  'NamedEntityRecordsEdit:importRecordsButton': {
    eng: 'Import dictionary',
    ru: 'Импорт справочника',
    cn: '导入字典',
  },
  'NamedEntityRecordsEdit:emptyList': {
    eng: 'Dictionary is empty',
    ru: 'Справочник пуст',
    cn: '字典为空',
  },
  'NamedEntityRecordsEdit:loaderCaption': {
    eng: 'Loading...',
    ru: 'Загрузка...',
    cn: '加载中...',
  },
  'NamedEntityRecordsEdit:selectedLabel': {
    eng: 'Selected',
    ru: 'Выбрано',
    cn: '已选择',
  },
  'NamedEntityRecordsEdit:discardLabel': {
    eng: 'Cancel',
    ru: 'Отменить',
    cn: '取消',
  },
  'NamedEntityRecordsEdit:deleteLabel': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'NamedEntityWithRecordEdit:titleNotSaved': {
    eng: 'Not saved',
    ru: 'Не сохранено',
    cn: '未保存',
  },
  'NamedEntityWithRecordEdit:titleSaved': {
    eng: 'Saved',
    ru: 'Сохранено',
    cn: '已保存',
  },
  'SystemNamedEntityView:entityNameCaption': {
    eng: 'Name',
    ru: 'Название',
    cn: '名称',
  },
  'SystemNamedEntityView:entityDescriptionCaption': {
    eng: 'Description',
    ru: 'Описание',
    cn: '描述',
  },
  'SystemNamedEntityView:recognizesFormatsCaption': {
    eng: 'Examples',
    ru: 'Примеры',
  },
  'SystemNamedEntityView:exampleInitialTextCaption': {
    eng: 'Source text',
    ru: 'Исходный текст',
  },
  'SystemNamedEntityView:notSelectedEntity': {
    eng: 'No entity selected',
    ru: 'Сущность не выбрана',
    cn: '未选择实体',
  },
  'AlignmentDialog:closeAlignmentButton': {
    eng: 'Close',
    ru: 'Закрыть',
    cn: '关闭',
  },
  'AlignmentDialog:alignmentTitle': {
    eng: 'Alignment',
    ru: 'Alignment',
    cn: '对齐',
  },
  'LLIntentsSelector:defaultNewIntentName': {
    eng: 'New intent',
    ru: 'Новый интент',
    cn: '新意图',
  },
  'Staging:treeEmptyList': {
    eng: '',
    ru: '',
  },
  'Staging:treeSearchNotFound': {
    eng: 'Not found',
    ru: 'Не найдено',
    cn: '未找到',
  },
  'Staging:treeStagingHeader': {
    eng: 'Intents tree',
    ru: 'Дерево интентов',
    cn: '意图树',
  },
  'Staging:selectIntentsButton': {
    eng: 'Select intent',
    ru: 'Назначить интент',
    cn: '删除意图',
  },
  'Staging:deleteButton': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'Staging:modalDeleteIntentTitle': {
    eng: 'Delete',
    ru: 'Удаление',
    cn: '删除',
  },
  'Staging:modalDeleteIntentActionButton': {
    eng: 'Delete',
    ru: 'Удалить',
    cn: '删除',
  },
  'Staging:modalDeleteIntentCancelButton': {
    eng: 'Cancel',
    ru: 'Отмена',
    cn: '取消',
  },
  'Staging:modalDeleteIntentMessage': {
    eng: 'Do you want to delete selected phrases?',
    ru: 'Удалить выбранные фразы?',
    cn: '确认要删除选中的短语吗？',
  },
  'LogPanel:panelButton': {
    eng: 'Logs',
    ru: 'Логи',
    cn: '日志',
  },
  'LogPanel:panelTitle': {
    eng: 'Server logs',
    ru: 'Логи сервера',
    cn: '服务器日志',
  },
  'LogPanel:loggersSelectPlaceholder': {
    eng: 'All loggers',
    ru: 'Все логгеры',
    cn: '所有记录器',
  },
  'LogPanel:levelsSelectPlaceholder': {
    eng: 'All events',
    ru: 'Все события',
    cn: '所有事件',
  },
  'LogPanel:searchInputPlaceholder': {
    eng: 'Search',
    ru: 'Поиск',
    cn: '搜索',
  },
  'LogPanel:clearButtonTitle': {
    eng: 'Clear',
    ru: 'Очистить',
    cn: '清除',
  },
  'LogPanel:logAreaEmpty': {
    eng: 'Empty',
    ru: 'Пусто',
    cn: '空',
  },
  'DeployInfo:Unexpected server response': {
    eng: 'Unexpected server response',
    ru: 'Непредвиденный ответ сервера',
    cn: '服务器返回异常',
  },
  'accountsadmin.accounts.account_short_name_already_taken': {
    eng: 'A user with the same name already exists',
    ru: 'Пользователь с таким именем уже существует',
    cn: '存在重名用户',
  },
  'Accounts Download xsl': {
    eng: 'Download xsl',
    ru: 'Скачать xsl',
    cn: '下载xsl',
  },
  'Accounts no accounts': {
    eng: 'Sorry, but we couldn’t find anything...',
    ru: 'Мы искали, но ничего не нашли...',
  },
  'Accounts feature placeholder': {
    eng: 'Feature name',
    ru: 'Название фичи',
  },
  'Accounts tariff placeholder': {
    eng: 'Plan name',
    ru: 'Название тарифа',
  },
  'Accounts bulk change': {
    eng: 'Edit',
    ru: 'Изменить',
  },
  'Utterance download audio': {
    eng: 'Download',
    ru: 'Скачать',
    cn: '下载',
  },
  'Utterance meta data': {
    eng: 'Meta information',
    ru: 'Мета-информация',
    cn: '元信息',
  },
  'SearchResultsBrowser.Editor:noSearchMatches': {
    eng: 'No search results found',
    ru: 'По вашему запросу совпадения не найдены',
  },
  'Just AI Conversational Platform': {
    eng: '<span>Just AI<br/> Conversational Platform</span>',
    ru: '<span>Just AI<br/> Conversational Platform</span>',
  },
  'Tovie Platform span': {
    eng: '<span>Tovie Platform</span>',
    ru: '<span>Tovie Platform</span>',
  },
  'Privacy Policy': {
    eng: 'Privacy Policy',
    ru: 'Политика конфиденциальности',
    cn: '隐私政策',
  },
  'privacy policy link': {
    eng: 'https://app.jaicp.com/documents/privacy-policy-ru/',
    ru: 'https://app.jaicp.com/documents/privacy-policy-ru/',
  },
  'Signup into account': {
    eng: 'Register in $[1]',
    ru: 'Регистрация аккаунта $[1]',
    cn: '注册为 $[1] 用户',
  },
  'Full Name': {
    eng: 'Your full name',
    ru: 'Ваше имя и фамилия',
    cn: '您的全名',
  },
  'Full Name placeholder': {
    eng: 'E.g. John Doe',
    ru: 'Например, Иван Иванов',
    cn: '例如张三',
  },
  'Terms of Service': {
    eng: 'Terms of Service',
    ru: 'условиями использования',
    cn: '服务条款',
  },
  'Minimum 10 characters': {
    eng: 'The password must be more than 9 characters long',
    ru: 'Пароль должен быть длиннее 9 символов',
    cn: '密码长度必须超过9个字符',
  },
  'Restore password': {
    eng: 'Reset password',
    ru: 'Сбросить пароль',
    cn: '重设密码',
  },
  'ProjectItemOverviewOptions download detail': {
    eng: 'Download detail',
    ru: 'Скачать детализацию',
    cn: '下载详细信息',
  },
  'details download audio': {
    eng: 'Download Audio',
    ru: 'Скачать запись',
    cn: '下载音频',
  },
  'details meta inforamtion': {
    eng: 'Metadata',
    ru: 'Мета-информация',
    cn: '元数据',
  },
  'details full record title': {
    eng: 'Full audio',
    ru: 'Полная запись разговора',
    cn: '全段音频',
  },
  'details phrase not recognized': {
    eng: 'Not recognized',
    ru: 'Не распознано',
    cn: '未识别',
  },
  'details audio interval tooltip': {
    eng: 'Phrase timeframe in the conversation',
    ru: 'Хронометраж фразы в разговоре',
    cn: '对话中的短语时间帧',
  },
  'details copy meta': {
    eng: 'Copy',
    ru: 'Скопировать',
    cn: '复制',
  },
  'CallTaskItem inProcessCalls': {
    eng: 'Calls in progress',
    ru: 'Звонков в процессе',
    cn: '处理中的呼叫',
  },
  'CallTaskItem retryCalls': {
    eng: 'Another call attempt scheduled',
    ru: 'Повторные попытки',
    cn: '计划再次呼叫',
  },
  'CallTaskItem maxSimultaneousCalls': {
    eng: 'Number of lines available',
    ru: 'Максимум одновременных звонков',
    cn: '可用线路数',
  },
  Tariffs: {
    eng: 'Plans',
    ru: 'Тарифы',
  },
  Developer: {
    eng: 'Developer',
    ru: 'Developer',
  },
  'developer desc': {
    eng: 'Ideal for development and prototyping',
    ru: 'Для разработки и прототипирования',
  },
  'developer users': {
    eng: '25 unique users',
    ru: '25 уникальных пользователей',
  },
  'jaicf_master users': {
    eng: '100 unique users',
    ru: '100 уникальных пользователей',
  },
  'developer minutes': {
    eng: '25 minutes',
    ru: '25 минут',
  },
  'developer bots': {
    eng: 'Bots for any channel',
    ru: 'Боты для всех каналов',
  },
  'developer calls': {
    eng: 'Inbound and outbound calls',
    ru: 'Исходящие и входящие звонки',
  },
  'developer apis': {
    eng: 'API access (Calls, NLU, Chat, Operator, Model)',
    ru: 'Calls API',
  },
  'developer apis_tovie': {
    eng: 'API access and advanced project analytics management',
  },
  'developer support': {
    eng: 'Community support',
    ru: 'Поддержка сообщества',
  },
  'developer rewards': {
    eng: 'Partner rewards',
    ru: 'Партнерские вознаграждения',
  },
  'developer price $[1]': {
    eng: '$[1] per month',
    ru: '$[1] в месяц',
  },
  'developer start': {
    eng: 'Start now',
    ru: 'Оплатить сейчас',
  },
  'jaicf_master minutes': {
    eng: '100 minutes',
    ru: '100 минут',
  },
  'community support link': {
    eng: 'https://just-ai-global.zendesk.com/hc/en-us',
    ru: 'https://just-ai-global.zendesk.com/hc/ru',
  },
  'support requests link': {
    eng: 'https://justai.helpdeskeddy.com',
    ru: 'https://justai.helpdeskeddy.com',
  },
  Enterprise: {
    eng: 'Enterprise',
    ru: 'Enterprise',
  },
  'enterprise desc': {
    eng: 'Ideal for enterprise-grade projects',
    ru: 'Для коммерческого использования',
  },
  'enterprise users': {
    eng: '∞ unique users',
    ru: '∞ уникальных пользователей',
  },
  'enterprise minutes': {
    eng: 'up to 300,000 minutes',
    ru: 'до 300 000 минут',
  },
  'enterprise bots': {
    eng: 'Bots for any channel',
    ru: 'Боты для всех каналов',
  },
  'enterprise calls': {
    eng: 'Inbound and outbound calls',
    ru: 'Исходящие и входящие звонки',
  },
  'enterprise apis': {
    eng: 'Calls API',
    ru: 'Calls API',
  },
  'enterprise apis_tovie': {
    eng: 'API access and advanced project analytics management',
    ru: 'API access and advanced project analytics management',
  },
  'enterprise premise': {
    eng: 'Available On-Premise',
    ru: 'Возможность установки в контур',
  },
  'enterprise security': {
    eng: 'Security, data protection, scalability',
    ru: 'Безопасность, защита данных, масштабирование',
  },
  'enterprise support': {
    eng: 'Priority technical and content support',
    ru: 'Приоритетная техническая и контентная поддержка',
  },
  'enterprise price $[1]': {
    eng: 'From $[1] / month',
    ru: 'От $[1] в месяц',
  },
  'enterprise start': {
    eng: 'Ask for the price',
    ru: 'Запросить стоимость',
  },
  'visual builder': {
    eng: 'Visual bot builder',
    ru: 'Визуальный редактор',
  },
  'visual builder info': {
    eng: 'No coding skills needed to manage content',
    ru: 'Доработка чат-ботов без программирования',
  },
  'tooltip bots': {
    eng: 'Answer user queries via more than 20 messengers and communication systems.',
    ru: 'Обслуживайте пользователей в более чем 20 мессенджерах и системах коммуникаций.',
  },
  'tooltip bots button': {
    eng: 'Available channels',
    ru: 'Список поддерживаемых каналов',
  },
  'tooltip calls': {
    eng: 'Connect a bot to a direct line to start call campaigns and process inbound calls.',
    ru: 'Подключите бота к телефонному каналу, чтобы делать обзвоны и обрабатывать входящие звонки.',
  },
  'tooltip calls button': {
    eng: 'Telephone services in $[1]',
    ru: 'Телефония в $[1]',
  },
  'tooltip callsApi': {
    eng: 'Integrate calls into your system with $[1] to enable telephone services.',
    ru: 'Интегрируйте звонки в свою систему с помощью $[1] для работы с телефонией.',
  },
  'tooltip nluApi': {
    eng: 'Enhance your app with natural language processing using $[1]',
    ru: 'Добавьте в свое приложение обработку запросов на естественном языке с помощью $[1]',
  },
  'tooltip rewards': {
    eng: 'Get major AI orders and earn with our partner program.',
    ru: 'Получайте крупные заказы на разработку AI-систем и зарабатывайте на партнерской программе.',
  },
  'tooltip rewards button': {
    eng: 'Partner program',
    ru: 'Партнерская программа',
  },
  timezoneBadFormat: {
    eng: 'Wrong timezone format',
    ru: 'Неверный формат таймзоны',
    cn: '时区格式错误',
  },
  'become partner': {
    eng: 'Become a partner',
    ru: 'Стать партнером',
    cn: '成为合作伙伴',
  },
  'partnership link': {
    eng: 'https://just-ai.com/en/partners.php',
    ru: 'https://just-ai.com/partnerstvo',
  },
  'partnership link Tovie AI': {
    eng: 'https://tovie.ai/contacts',
    ru: 'https://tovie.ai/contacts',
  },
  'status blocked': {
    eng: 'Account blocked',
    ru: 'Заблокировано',
    cn: '账户已冻结',
  },
  'status not paid': {
    eng: 'Subscription expired',
    ru: 'Подписка не оплачена',
    cn: '套餐已过期',
  },
  'status paid': {
    eng: 'Next billing date $[1]',
    ru: 'Оплачено до $[1]',
    cn: '下一个付款日期$[1]',
  },
  'status demo': {
    eng: 'Demo period till $[1]',
    ru: 'Демо до $[1]',
    cn: '演示期到$[1]',
  },
  'status blocked not paid': {
    eng: 'Not paid and disabled',
    ru: 'Отключено за неуплату',
    cn: '未支付并禁用',
  },
  'status agreement': {
    eng: 'Request an invoice',
    ru: 'Оплата по договору',
    cn: '索取发票',
  },
  'pay plan': {
    eng: 'Purchase subscription',
    ru: 'Оплатить подписку',
    cn: '购买套餐',
  },
  card: {
    eng: 'Card',
    ru: 'Карта',
    cn: '银行卡',
  },
  'accept plan header $[1]': {
    eng: 'Subscription purchase "$[1]"',
    ru: 'Оплата подписки "$[1]"',
    cn: '套餐 $[1]',
  },
  'accept plan due date $[1] $[2]': {
    eng: 'Your card will be charged the monthly subscription price of $[2] on $[1]',
    ru: '$[1] с вашей платежной карты спишется $[2] за месяц подписки',
    cn: '$[1] 您的信用卡将按$ $[2]的套餐价格扣取月费',
  },
  'accept plan warning': {
    eng: 'To turn the autopay on, please add a bank card. <br />We will charge 1 ₽ from your card and then return it later.',
    ru: 'Для включения автоплатежа необходимо привязать карту. <br />С карты будет списан 1 ₽, а потом возвращен обратно.',
  },
  'accept plan decline btn': {
    eng: 'Pay later',
    ru: 'Оплатить позже',
    cn: '稍后支付',
  },
  'accept plan accept btn': {
    eng: 'Start now',
    ru: 'Оплатить сейчас',
  },
  'AccountManager packages add success': {
    eng: 'Successfully added: ',
    ru: 'Успешно добавлены: ',
    cn: '已添加成功: ',
  },
  'AccountManager packages add fail': {
    eng: 'Failed to expand your limits. Please try to expand the limits again.',
    ru: 'Произошла ошибка во время добавления лимитов. Попробуйте добавить лимиты еще раз.',
    cn: '增加额度失败。请再次尝试增加。',
  },
  'AccountManager packages deduct success': {
    eng: 'Successfully deducted: ',
    ru: 'Успешно списаны: ',
  },
  'AccountManager packages deduct fail': {
    eng: 'Failed to deduct your limits. Please try to deduct the limits again.',
    ru: 'Произошла ошибка во время списания лимитов. Попробуйте списать лимиты еще раз.',
  },
  EXTRA_PACKAGES_ENABLED: {
    eng: 'Packages added: $[1]',
    ru: 'Добавлены пакеты: $[1]',
    cn: ': 已添加的套餐$[1]',
  },
  UNIQUE_USERS_ADDITIONAL_PACKAGE_DECREMENT: {
    eng: 'Package deducted: $[1] unique users',
    ru: 'Списан пакет: $[1] уникальных пользователей',
  },
  NLU_MINUTES_ADDITIONAL_PACKAGE_DECREMENT: {
    eng: 'Package deducted: $[1] NLU minutes',
    ru: 'Списан пакет: $[1] минут NLU',
  },
  ASR_MINUTES_ADDITIONAL_PACKAGE_DECREMENT: {
    eng: 'Package deducted: $[1] ASR #{config.botadmin.companyName} minutes',
    ru: 'Списан пакет: $[1] минут ASR #{config.botadmin.companyName}',
  },
  INTERNAL_TELEPHONY_MINUTES_ADDITIONAL_PACKAGE_DECREMENT: {
    eng: 'Package deducted: $[1] Just AI call minutes,',
    ru: 'Списан пакет: $[1] минут телефонии Just AI,',
  },
  SMS_ADDITIONAL_PACKAGE_DECREMENT: {
    eng: 'Package deducted: $[1] SMS',
    ru: 'Списан пакет: $[1] SMS',
  },
  'ToS text': {
    eng: 'Terms of Use',
    ru: 'Соглашение об использовании платформы',
    cn: '使用条款',
  },
  'logger time MINUTES5': {
    eng: 'last 5 minutes',
    ru: 'за последние 5 минут',
    cn: '最后的5分钟',
  },
  'logger time MINUTES30': {
    eng: 'last 30 minutes',
    ru: 'за последние 30 минут',
    cn: '最后的30分钟',
  },
  'logger time HOUR': {
    eng: 'last hour',
    ru: 'за последний час',
    cn: '最后的1小时',
  },
  'logger time HOURS12': {
    eng: 'last 12 hours',
    ru: 'за последние 12 часов',
    cn: '最后的12小时',
  },
  'logger time DAY': {
    eng: 'last 24 hours',
    ru: 'за последние сутки',
    cn: '最后的24小时',
  },
  'GoogleBotEditForm:applicationUrl': {
    eng: 'Web app URL',
    ru: 'Web app URL',
    cn: '网站应用程序地址',
  },
  'Master plan heading': {
    eng: 'Use free $[1] services for $[2] bots',
    ru: 'Воспользуйтесь бесплатными сервисами $[1] для ботов на $[2]',
  },
  'Master plan deployment': {
    eng: 'Posting to messengers, social media and customer engagement platforms',
    ru: 'Размещение в мессенджерах, соцсетях и операторских каналах',
  },
  'Master plan telephony': {
    eng: 'Telephony and outbound communications',
    ru: 'Телефония и исходящие коммуникации',
  },
  'Master plan analysis': {
    eng: 'Analysis of conversations and clients, log labeling',
    ru: 'Анализ диалогов, клиентов и разметка логов',
  },
  'Master plan NLU': {
    eng: 'Multi-language NLU',
    ru: 'Многоязычный NLU',
  },
  'Master plan button label': {
    eng: 'Enable the free Framework Master plan',
    ru: 'Подключить бесплатный тариф Framework Master',
  },
  'Master plan modal text': {
    eng: 'Please note! You are enabling the free Framework Master plan to create JAICF-based bots. You will need to pay for your current $[1] plan again if you wish to return to it.',
    ru: 'Внимание! Вы подключаете бесплатный тариф Framework Master для создания ботов на JAICF. Вернуться на свой текущий тариф $[1] вы сможете только после повторной оплаты этого тарифа.',
  },
  'Master plan modal decline text': {
    eng: 'Please contact your account administrator to switch to the free Framework Master plan',
    ru: 'Для переключения на бесплатный тариф Framework Master обратитесь к администратору аккаунта',
  },
  'Master plan modal agree btn': {
    eng: 'Yes, I agree',
    ru: 'Да, согласен',
  },
  'Master from developer plan modal text': {
    eng: 'Attention! You are trying to enable the free $[2] Master subscription plan, which is suitable for developing bots in $[2]. You will need to contact us if you need to switch back to your current $[1] plan.',
    ru: 'Внимание! Вы подключаете бесплатный тариф $[2] Master для создания ботов на $[2]. Чтобы вернуться на свой текущий тариф $[1], вам необходимо написать обращение команде Just AI.',
  },
  Read: {
    eng: 'Read $[1]',
    ru: 'Прочитайте $[1]',
  },
  'Open project settings': {
    eng: 'Open project properties?',
    ru: 'Открыть свойства проекта?',
  },
  'Open session': {
    eng: 'Open session',
    ru: 'Открыть сессию',
  },
  'Additional settings': {
    eng: 'Additional settings',
    ru: 'Дополнительные настройки',
  },
  'Additional Information': {
    eng: 'Additional Information',
    ru: 'Дополнительная информация',
    cn: '附加信息',
  },
  'Use morph words': {
    eng: 'Normalize words',
    ru: 'Приводить слова к нормальной форме',
  },
  'Use morph words sub': {
    eng: 'All the words entered by the user will be $[1]',
    ru: 'Перед началом поиска все слова введённой пользователем фразы будут приведены $[1]',
  },
  'Use morph words link': {
    eng: 'normalized before the search',
    ru: 'к нормальной форме',
  },
  'Use morph words tooltip': {
    eng: 'Lemmatization is not supported for selected language at the moment',
    ru: 'Лемматизация для выбранного языка не поддерживается',
  },
  'Use phonetic search': {
    eng: 'Use phonetic search',
    ru: 'Использовать фонетический поиск',
  },
  'Use phonetic search sub': {
    eng: 'Entities will be searched for $[1]',
    ru: 'Поиск сущностей будет происходить $[1]',
  },
  'Use phonetic search link': {
    eng: 'based on phonetic similarity of words',
    ru: 'с учетом близости звучания слов',
  },
  'Use fuzzy search': {
    eng: 'Use fuzzy/substring search',
    ru: 'Использовать нечеткий поиск / поиск по подстроке',
  },
  Article: {
    eng: 'spelling correction ',
    ru: 'статье.',
  },
  'Fuzzy search tooltip': {
    eng: 'Fuzzy search for Portugeese language is not supported at the moment',
    ru: 'Нечеткий поиск для португальского языка не поддерживается',
  },
  'Sidebar minimize menu item': {
    eng: 'Collapse side panel',
    ru: 'Свернуть боковую панель',
  },
  'Sidebar expand menu item': {
    eng: 'Expand side panel',
    ru: 'Развернуть боковую панель',
  },
  'Sidebar nlu menu item': {
    eng: '#{config.nluProductName}',
    ru: '#{config.nluProductName}',
  },
  'Sidebar experiments menu item': {
    eng: 'A/B testing',
    ru: 'А/В-тестирование',
  },
  'login page github button': {
    eng: 'Sign in with GitHub',
    ru: 'Войти через GitHub',
  },
  'login page google button': {
    eng: 'Sign in with Google',
    ru: 'Войти через Google',
  },
  'login page or': {
    eng: 'or',
    ru: 'или',
  },
  'report.technicalErrorCalls': {
    eng: 'Technical error',
    ru: 'Техническая ошибка',
  },
  'healthcheck must be valid': {
    eng: 'The value of the healthcheck period field must be from 1 to 86400',
    ru: 'Значение поля период проверки должно быть от 1 до 86400',
  },
  'Mail verification required': {
    eng: 'Email verification is required',
    ru: 'Требуется подтверждение почты',
  },
  'free of charge': {
    eng: 'Free of charge',
    ru: 'Бесплатно',
  },
  'TelephonyChannelEditModal tabs common': {
    eng: 'General',
    ru: 'Основное',
  },
  'TelephonyChannelEditModal tabs asr': {
    eng: 'ASR',
    ru: 'ASR',
  },
  'TelephonyChannelEditModal tabs tts': {
    eng: 'TTS',
    ru: 'TTS',
  },
  'TelephonyChannelEditModal required fields': {
    eng: 'Define obligatory settings',
    ru: 'Заполните обязательные поля',
    cn: '定义强制设置',
  },
  'TelephonyChannelEditModal:sber.access.token.exchange.fail': {
    eng: 'Incorrect Client ID or Client Secret',
    ru: 'Client ID или Client Secret указаны с ошибкой',
  },
  'CustomProvider-Sber link docs': {
    eng: 'https://developers.sber.ru/docs/ru/salutespeech/authentication',
    ru: 'https://developers.sber.ru/docs/ru/salutespeech/authentication',
  },
  'CustomProvider-Sber text link': {
    eng: 'How to enable SaluteSpeech authentication?',
    ru: 'Как подключить аутентификацию SaluteSpeech?',
  },
  'TelephonyAsrSettings select label': {
    eng: 'Connection',
    ru: 'Подключение',
  },
  'TelephonyAsrSettings default asr': {
    eng: 'Default',
    ru: 'По умолчанию',
  },
  'TelephonyAsrSettings asr title': {
    eng: 'Recognition',
    ru: 'Распознавание',
  },
  'TelephonyAsrSettings asr language': {
    eng: 'Language',
    ru: 'Язык',
  },
  'TelephonyAsrSettings asr model': {
    eng: 'Model',
    ru: 'Модель',
  },
  'TelephonyAsrSettings add new': {
    eng: 'Create connection…',
    ru: 'Создать подключение…',
  },
  'TelephonyAsrSettings numbersAsWords label': {
    eng: 'Number recognition',
    ru: 'Распознавание чисел',
  },
  'TelephonyAsrSettings numbersAsWords text': {
    eng: 'If the setting is enabled, the recognized text will contain numerals, not numbers. This setting affects the final and intermediate recognition results.',
    ru: 'Если включен, то в распознанном тексте указываются числительные, а не числа. Настройка влияет на финальные и на промежуточные результаты распознавания.',
  },
  'TelephonyAsrSettings sensitivityReduction label': {
    eng: 'Reduced sensitivity to noise',
    ru: 'Сниженная чувствительность к шуму',
  },
  'TelephonyAsrSettings sensitivityReduction text': {
    eng: 'Reduces recognition sensitivity to background noise. May adversely affect the quality of quiet speech recognition.',
    ru: 'Уменьшает чувствительность распознавания к фоновому шуму. Может отрицательно сказаться на качестве распознавания тихой речи.',
  },
  'Send start in the start of dialogue': {
    eng: 'Whether to send /start at the start of a conversation',
    ru: 'Oтправлять ли /start в начале диалога',
  },
  'ChatwidgetEditorPage.SoundNotifications option label': {
    eng: 'Sound effect',
    ru: 'Звуковой эффект',
  },
  'ChatwidgetEditorPage.SoundNotifications option none': {
    eng: 'Mute all sounds',
    ru: 'Выключить все звуки',
  },
  'ChatwidgetEditorPage.SoundNotifications option client': {
    eng: 'Client messages',
    ru: 'Сообщения клиента',
  },
  'ChatwidgetEditorPage.SoundNotifications option bot': {
    eng: 'Bot messages',
    ru: 'Сообщения бота',
  },
  'ChatwidgetEditorPage.SoundNotifications option client_bot': {
    eng: 'Client and bot messages',
    ru: 'Сообщения клиента и бота',
  },
  'ChatwidgetEditorPage.SoundNotifications option sound': {
    eng: 'Sound',
    ru: 'Звук',
  },
  'ChatwidgetEditorPage.SoundNotifications option choose sound': {
    eng: 'Select sound',
    ru: 'Выберите звук',
  },
  'ChatWidgetEditor.embedmentContainer': {
    eng: 'Embedding the widget in a container',
    ru: 'Встраивание виджета в контейнер на странице',
  },
  'ChatWidgetEditor.embedmentContainer.id': {
    eng: 'Embedding ID',
    ru: 'ID для встраивания',
  },
  'Header:testActionLabel': {
    eng: 'Test the bot',
    ru: 'Тестировать бота',
  },
  'Header:startAutoTestsActionLabel': {
    eng: 'Run tests first',
    ru: 'Сначала запустить тесты',
  },
  'Header:startAutoTestsInBgActionLabel': {
    eng: 'Run tests in the background',
    ru: 'Запустить тесты фоном',
  },
  'Header:doNotRunAutotestsActionLabel': {
    eng: 'Do not run tests',
    ru: 'Не запускать тесты',
  },
  'Header:publishActionLabel': {
    eng: 'Deploy to all channels',
    ru: 'Опубликовать во все каналы',
  },
  'Header:trainActionLabel': {
    eng: 'Train intents and entities',
    ru: 'Тестировать интенты и сущности',
  },
  'Header:GithubTokenBanner.text': {
    eng: 'GitHub will soon disable access to repositories with a login/password. Use a personal access token instead of a password in your projects',
    ru: 'GitHub отменяет доступ к репозиториям через логин/пароль. Замените пароль на персональный токен во всех проектах',
  },
  'Header:GithubTokenBanner.linkLabel': {
    eng: 'How to get a personal access token',
    ru: 'Инструкция по получению',
  },
  'Header: logout': {
    eng: 'Sign out',
    ru: 'Выйти',
  },
  'linesAvailable must be valid': {
    eng: 'The number of concurrent calls must be greater than zero',
    ru: 'Количество одновременных звонков должно быть больше 0',
  },
  'codecs must be valid': {
    eng: 'Select at least one codec',
    ru: 'Нужно выбрать хотя бы один кодек',
  },
  BILLING_FREE_PERIOD_IS_CHANGED: {
    eng: 'Free period assigned under the $[1] plan from $[2] to $[3]',
    ru: 'Назначен бесплатный период на тарифе $[1] с $[2] по $[3]',
  },
  'enable contact center noise': {
    eng: 'Use contact center noise',
    ru: 'Использовать шум контактного центра',
  },
  'enable asr tts diagnostic mode': {
    eng: 'Activate the ASR and TTS error diagnostics feature',
    ru: 'Включить функцию диагностики ошибок для ASR и TTS',
  },
  'report.nonexistentNumberCalls': {
    eng: 'Calls to a non-existent number',
    ru: 'Звонков на несуществующий номер',
  },
  Filters: {
    eng: 'Filters',
    ru: 'Фильтры',
  },
  Between: {
    eng: 'Between',
    ru: 'Между',
  },
  Any: {
    eng: 'Any',
    ru: 'Любое',
  },
  After: {
    eng: 'After',
    ru: 'После',
  },
  Before: {
    eng: 'Before',
    ru: 'До',
  },
  'NumericRangeModal:clear': {
    eng: 'Clear',
    ru: 'Очистить',
  },
  TreeSelectionAvailableWithAlt: {
    eng: 'Alt + Click to select an element without sub-elements',
    ru: 'Alt + Клик для выбора элемента без вложенных',
  },
  TreeSelectionCount: {
    eng: 'Sub-elements selected',
    ru: 'Выбрано вложенных элементов',
  },
  'TreeComparisonOptions:LOG_LABELS': {
    eng: 'Select labeled conversations from the list:',
    ru: 'Выбрать диалоги с метками из списка:',
  },
  'TreeModal:nothingFound': {
    eng: 'Nothing found',
    ru: 'Ничего не найдено',
  },
  'TreeModal:isEmpty': {
    eng: 'No data',
    ru: 'Нет данных',
  },
  'change.tariff.exception.in_progress': {
    eng: 'Impossible to apply the settings of the new period until the current one expires.',
    ru: 'Невозможно применить настройки нового периода, пока не закончился действующий.',
  },
  'change.tariff.invalid.date.range': {
    eng: 'Invalid date interval. The end date must be later than the start date, the start date must not be in the past.',
    ru: 'Некорректный интервал дат. Дата окончания должна быть позже даты начала, дата начала и дата окончания не должны быть в прошлом.',
  },
  PAYMENT_SUCCESSFULLY_DONE: {
    eng: 'Transaction successful: $[1]',
    ru: 'Успешное списание средств: $[1]',
  },
  PAYMENT_FAILED: {
    eng: 'Transaction failed. Response from the payment service: $[1]',
    ru: 'Неудачное списание средств. Ответ платежного сервиса: $[1]',
  },
  BILLING_PERIOD_STARTED_BECAUSE_OF_PAYMENT: {
    eng: 'Billing period started after a successful payment',
    ru: 'Начало тарификационного периода после оплаты',
  },
  BILLING_PERIOD_STARTED_OF_MANUAL_CONTROL: {
    eng: 'Billing period started in manual billing mode',
    ru: 'Начало тарификационного периода в режиме ручного управления',
  },
  AUTO_PAYMENT_ENABLED: {
    eng: 'Automatic subscription renewal was enabled',
    ru: 'Включено автоматическое списание средств за тариф',
  },
  AUTO_PAYMENT_DISABLED: {
    eng: 'Automatic subscription renewal was disabled',
    ru: 'Отключено автоматическое списание средств за тариф',
  },
  'Inside jaicp': {
    eng: 'Inside $[1]',
    ru: 'Внутри $[1]',
  },
  'Inside bot platform': {
    eng: 'Inside Bot Platform',
    ru: 'Внутри Bot Platform',
  },
  'Inside tovie platform': {
    eng: 'Inside Tovie Platform',
    ru: 'Внутри Tovie Platform',
  },
  'reporter forming start popover': {
    eng: 'Report generation is in progress',
    ru: 'Отчет формируется',
  },
  And: {
    eng: 'and',
    ru: 'и',
  },
  SMS: {
    eng: 'SMS',
    ru: 'SMS',
  },
  SMS_PACKAGE_REQUEST: {
    eng: '$[1] SMS package requested',
    ru: 'Запрошен пакет $[1] SMS',
  },
  SMS_PACKAGE_ENABLED: {
    eng: '$[1] SMS package enabled',
    ru: 'Подключен пакет $[1] SMS',
  },
  SMS_PACKAGE_IS_OVER: {
    eng: 'SMS package expired',
    ru: 'Исчерпан пакет SMS',
  },
  MLP_TOKENS_PACKAGE_IS_OVER: {
    eng: 'Caila tokens package expired',
    ru: 'Исчерпан пакет Caila-токенов',
  },
  'report.resultCallJobs': {
    eng: 'Phone numbers with result',
    ru: 'Номеров с результатом',
  },
  'report.noResultCallJobs': {
    eng: 'Phone numbers without result',
    ru: 'Номеров без результата',
  },
  'report.noAnswerCallJobs': {
    eng: 'Phone numbers without answer',
    ru: 'Номеров без ответа',
  },
  'report.technicalErrorCallJobs': {
    eng: 'Phone numbers with a technical error',
    ru: 'Номеров с технической ошибкой',
  },
  'report.nonexistentNumberCallJobs': {
    eng: 'Non-existent phone numbers',
    ru: 'Несуществующих номеров',
  },
  'report.plannedCallJobs': {
    eng: 'Planned phone numbers',
    ru: 'Запланированных номеров',
  },
  'report.inProcessCallJobs': {
    eng: 'Phone numbers in progress',
    ru: 'Номеров в процессе',
  },
  'report.completedCallJobs': {
    eng: 'Finished phone numbers',
    ru: 'Завершённых номеров',
  },
  'report.notDoneCallJobs': {
    eng: 'Phone numbers not dialed',
    ru: 'Несовершённых номеров',
  },
  'CallTaskEdit start attempt count': {
    eng: 'Initial attempts count',
    ru: 'Начальное количество',
  },
  'CallTaskEdit max attempt count': {
    eng: 'Max attempts count',
    ru: 'Максимальное количество',
  },
  'CallTaskEdit max attempt count tooltip': {
    eng: 'The max attempts count must be greater than or equal to the initial attempts count',
    ru: 'Максимальное количество попыток должно быть больше или равно начальному количеству попыток',
  },
  'CallTaskEdit attempt count tooltip': {
    eng: 'The bot will try dialing each phone number as many times as specified in the initial attempts count. If additional dial attempts are planned from the bot script (e.g. when the customer asks to call them back later), the platform will limit the total number of dial attempts not to exceed the max attempts count.',
    ru: 'Бот попробует дозвониться по каждому номеру телефона указанное начальное количество раз. Если в сценарии бота планируются дополнительные попытки дозвониться на номер (например, если абонент просит перезвонить ему позже), то система ограничит общее число попыток — их может быть не больше, чем максимальное число попыток.',
  },
  'CallTaskEdit test failed': {
    eng: 'automated tests have failed',
    ru: 'автоматические тесты не прошли',
  },
  'CallTaskEdit channel not published': {
    eng: 'the bot has not been deployed',
    ru: 'бот не опубликован',
  },
  'format.callAttempts.contradiction': {
    eng: 'The initial attempts count cannot be greater than the max attempts count',
    ru: 'Начальное количество попыток не может быть больше максимального количества попыток',
  },
  EMAIL_CHANGED: {
    eng: 'Email changed to $[1]',
    ru: 'Почта изменена на $[1]',
  },
  'PartnerBlock relatedPartner title': {
    eng: 'This client is attached to the following partner:',
    ru: 'Этот клиент привязан к следующему партнеру:',
  },
  'PartnerBlock relatedPartner legal entity': {
    eng: 'Legal entity or sole proprietor',
    ru: 'Юридическое лицо или ИП',
  },
  'PartnerBlock relatedPartner phone': {
    eng: 'Phone number',
    ru: 'Контактный номер',
  },
  'PartnerBlock relatedPartner related date': {
    eng: 'Attachment date',
    ru: 'Дата привязки',
  },
  'PartnerBlock relatedPartner snap new partner': {
    eng: 'Attach to another partner',
    ru: 'Привязать другого партнера',
  },
  'PartnerBlock relatedPartner break': {
    eng: 'Break',
    ru: 'Разорвать',
  },
  'SnapToPartnerModal title': {
    eng: 'Attach to a partner',
    ru: 'Привязать партнера',
  },
  'SnapToPartnerModal acception text': {
    eng: 'Account <b>$[1]</b> is already attached to a partner. Do you want to change this partnership?',
    ru: 'Аккаунт <b>$[1]</b> уже привязан к партнеру. Вы хотите изменить привязку?',
  },
  'SnapToPartnerModal acception button text': {
    eng: 'Yes',
    ru: 'Да, изменить',
  },
  'SnapToPartnerModal error': {
    eng: 'The specified partner address does not exist or cannot be attached to this account',
    ru: 'Указанный адрес партнера не существует или не может быть назначен данному аккаунту',
  },
  'AccountsBulkEditModal title': {
    eng: 'Edit accounts',
    ru: 'Изменение аккаунтов',
  },
  'AccountsBulkEditModal add features': {
    eng: 'Enable features',
    ru: 'Подключить фичи',
  },
  'AccountsBulkEditModal remove features': {
    eng: 'Disable features',
    ru: 'Отключить фичи',
  },
  'AccountsBulkEditModal features added message': {
    eng: 'Selected features have been enabled',
    ru: 'Выбранные фичи включены',
  },
  'AccountsBulkEditModal features removed message': {
    eng: 'Selected features have been disabled',
    ru: 'Выбранные фичи отключены',
  },
  'AccountsBulkEditModal added': {
    eng: 'Enabled',
    ru: 'Подключено',
  },
  'AccountsBulkEditModal removed': {
    eng: 'Disabled',
    ru: 'Отключено',
  },
  'AccountsBulkEditModal same features error': {
    eng: 'You cannot enable and disable the same features at the same time',
    ru: 'Нельзя подключить и отключить одни и те же фичи одновременно',
  },
  FEATURES_ADDED: {
    eng: 'Enabled features: $[1]',
    ru: 'Подключены фичи: $[1]',
  },
  FEATURES_REMOVED: {
    eng: 'Disabled features: $[1]',
    ru: 'Отключены фичи: $[1]',
  },
  'HelpMenu: video course title': {
    eng: 'JAICP online course',
    ru: 'Учебный центр Just AI',
  },
  'HelpMenu: video course title Platform': {
    eng: 'Tovie Platform online course',
    ru: 'Видеокурс по Tovie Platform',
  },
  'HelpMenu: video course link': {
    eng: '',
    ru: 'https://knowledge.just-ai.com/docs/ru/products/jaicp?utm_source=editor',
  },
  EXTRA_UNIQUE_USERS_PACKAGE_REQUEST: {
    eng: '$[1] package is requested',
    ru: 'Запрошен пакет $[1]',
  },
  'Old password': {
    eng: 'Enter old password',
    ru: 'Старый пароль',
  },
  'Business Jivo Brazil': {
    eng: 'Business JivoChat',
    ru: 'Business Jivo',
  },
  'AL Business Jivo Brazil': {
    eng: 'Business JivoChat',
    ru: 'Business Jivo',
  },
  'EditPartnerDataModal: status UNVERIFIED': {
    eng: 'Unverified',
    ru: 'Неверифицированный',
  },
  'EditPartnerDataModal: status ACTIVE': {
    eng: 'Active',
    ru: 'Активный',
  },
  'EditPartnerDataModal: status BLOCKED': {
    eng: 'Blocked',
    ru: 'Заблокирован',
  },
  'EditPartnerDataModal: edit title': {
    eng: 'Edit partner information',
    ru: 'Изменение данных партнера',
  },
  'EditPartnerDataModal: partner category': {
    eng: 'Partner category',
    ru: 'Категория партнера',
  },
  'EditPartnerDataModal: vip false': {
    eng: 'Basic',
    ru: 'Обычный',
  },
  'EditPartnerDataModal: vip true': {
    eng: 'VIP',
    ru: 'VIP',
  },
  partner_field_partnerState: {
    eng: 'Partner status: $[1]',
    ru: 'Статус партнера: $[1]',
  },
  PARTNER_IS_UNVERIFIED: {
    eng: 'Partner status set to Unverified',
    ru: 'Статус партнера изменен на Неверифицированный',
  },
  Unverified: {
    eng: 'Unverified',
    ru: 'Неверифицированный',
  },
  partner_field_vip: {
    eng: 'Partner category: $[1]',
    ru: 'Категория партнера: $[1]',
  },
  partner_field_type: {
    eng: 'Partner type: $[1]',
    ru: 'Тип партнера: $[1]',
  },
  partner_field_settlementAccount: {
    eng: 'Payment account: $[1]',
    ru: 'Расчетный счет: $[1]',
  },
  'UserEditModal: login error': {
    eng: 'Login field must not be empty',
    ru: 'Поле «Логин» не должно быть пустым',
  },
  'TestWidget: restart title': {
    eng: 'Restart test',
    ru: 'Начать тест заново',
  },
  'Your Email has been successfully confirmed.': {
    eng: 'Your email has been confirmed',
    ru: 'Ваш email успешно подтвержден',
  },
  'CallTaskReport: title quantity calls ficha off': {
    eng: 'The number of calls and percentage relative to the total number of calls',
    ru: 'Количество звонков и процент от общего числа вызовов',
  },
  'CallTaskReport: title quantity calls ficha on': {
    eng: 'The number of phone numbers and percentage relative to the total number of answered calls',
    ru: 'Количество номеров и процент от общего числа состоявшихся номеров',
  },
  'CallTaskReport: Other results': {
    eng: 'Other results',
    ru: 'Другие результаты',
  },
  'CallTaskReport: numbers in calls': {
    eng: 'Numbers in call campaign',
    ru: 'Номеров в обзвоне',
  },
  'CallTaskReport: percent': {
    eng: 'Percentage',
    ru: 'Процент',
  },
  SYSTEM_CHANGE_TO_BILLING_PERIOD: {
    eng: 'Subscription option changed. $[1]$[2] from $[3] to $[4]. At the end of $[5]',
    ru: 'Изменен тип тарификации. $[1]$[2] с $[3] по $[4]. По окончании $[5]',
  },
  'ChangeUserModal: tariff type': {
    eng: 'Subscription option',
    ru: 'Тип тарификации',
  },
  'ChangeUserModal: tariff type manual control': {
    eng: 'Under contract: ',
    ru: 'По договору: ',
  },
  'ChangeUserModal: tariff type free TariffSystemChangeFreePeriod': {
    eng: 'Free period: ',
    ru: 'Бесплатный период: ',
  },
  'ChangeUserModal: tariff type free TariffSystemChangeFreePeriodWeek': {
    eng: 'Free week: ',
    ru: 'Бесплатная неделя: ',
  },
  'ChangeUserModal: tariff type free TariffSystemChangeFreePeriodMonth': {
    eng: 'Free month: ',
    ru: 'Бесплатный месяц: ',
  },
  'ChangeUserModal: stayOnTariff manual control': {
    eng: 'Keep on the current plan (payment by card)',
    ru: 'Оставить на текущем тарифе (оплата по карте)',
  },
  'change.tariff.invalid.free.period.intersect': {
    eng: 'You cannot assign a free period during the current period under contract',
    ru: 'Нельзя назначить бесплатный период во время действующего периода по договору.',
  },
  'ProjectOverview: JAICP docs': {
    eng: '$[1] documentation',
    ru: 'Документации $[1]',
  },
  'TelephonySettings: outboundProxy': {
    eng: 'SIP outbound proxy',
    ru: 'Исходящий SIP-прокси',
  },
  'TelephonySettings: use outboundProxy': {
    eng: 'Use in the dialog',
    ru: 'Использовать в диалоге',
  },
  'TelephonySettings: mapping by ip/port': {
    eng: 'Port and IP mapping',
    ru: 'Сопоставление по IP и порту',
  },
  'TelephonySettings: address': {
    eng: 'Address',
    ru: 'Адрес',
  },
  'TelephonySettings: Informer Map by ip and port': {
    eng: '\n      For inbound calls, allows handling calls to multiple DNIDs in one connection.\n      The SIP trunk is mapped using the IP and port of the INVITE request origin. The From, To, and Request-URI headers are ignored.\n      When using a SIP outbound proxy, the IP address of the SIP proxy will be matched.\n      ',
    ru: '\n      При входящих вызовах позволяет обрабатывать в одном подключении вызовы на несколько DNID.\n      Используется сопоставление SIP-транка по IP-адресу и порту источника запроса INVITE. Заголовки From, To и Request-URI игнорируются.\n      При использовании исходящего SIP-прокси будет сопоставляться IP-адрес SIP-прокси.\n    ',
    cn: '\n      For inbound calls, allows handling calls to multiple DNIDs in one connection.\n      The SIP trunk is mapped using the IP and port of the INVITE request origin. The From, To, and Request-URI headers are ignored.\n      When using a SIP outbound proxy, the IP address of the SIP proxy will be matched.\n    ',
  },
  'TelephonySettings: Informer use local domain FROM': {
    eng: '\n      Domain substitution can only be used for SIP login/password authentication.',
    ru: '\n      Замена домена может быть использована только при аутентификации по паре SIP-логин/пароль.',
  },
  'TelephonySettings: Informer Use outbound': {
    eng: '\n      Force all requests in the dialog to be sent through the SIP proxy, ignoring the Contact and Record-Route headers.\n      Request examples: ACK, BYE\n    ',
    ru: '\n      Принудительно отправлять все запросы в диалоге через SIP-прокси, игнорируя заголовки Contact и Record-Route.\n      Примеры запросов: ACK, BYE\n    ',
  },
  'TariffPage: tariff connected': {
    eng: 'Enabled',
    ru: 'Подключено',
  },
  'TariffPage: switch to developer': {
    eng: 'Enable',
    ru: 'Подключить',
  },
  BILLING_PERIOD_STARTED_BECAUSE_OF_FREE_TARIFF: {
    eng: 'Billing period started on free plan $[1]',
    ru: 'Начало тарификационного периода на бесплатном тарифе $[1]',
  },
  CARD_DATA_WAS_REMOVED: {
    eng: 'Card details were deleted on the $[1] plan',
    ru: 'Данные карты были удалены на тарифе $[1]',
  },
  'LabelEmptyList-title': {
    eng: 'You haven’t created any tags yet',
    ru: 'Вы еще не создавали теги',
  },
  'LabelEmptyList-text': {
    eng: 'Enter the new tag name and press Enter',
    ru: 'Введите название нового тега и нажмите Enter',
  },
  TagsModalSelectPlaceholder: {
    eng: 'Search for tags or add new ones',
    ru: 'Найдите или добавьте новые теги',
  },
  'TagsModal bot tags': {
    eng: 'Bot tags',
    ru: 'Теги бота',
  },
  'LabelComponent:Create element': {
    eng: 'Create element',
    ru: 'Создать элемент',
  },
  'LabelComponent:Press Enter': {
    eng: 'Press Enter',
    ru: 'Нажмите Enter',
  },
  'CreateProjectItem:EMPTY_title': {
    eng: 'Create from scratch',
    ru: 'Создать с нуля',
  },
  'CreateProjectItem:CAILA_title': {
    eng: 'From system template',
    ru: 'Из системного шаблона',
  },
  'CreateProjectItem:CAILA_description': {
    eng: 'With configuration for CAILA',
    ru: 'С конфигурацией для CAILA',
  },
  'CreateProjectItem:TEMPLATE_title': {
    eng: 'Use template',
    ru: 'Использовать шаблон',
  },
  'CreateProjectItem:TEMPLATE_description': {
    eng: 'Choose from our library',
    ru: 'Выберите из нашей библиотеки',
  },
  'CreateProjectItem:GIT_title': {
    eng: 'Connect to Git project',
    ru: 'Подключиться к Git-проекту',
  },
  'CreateProjectItem:GIT_description': {
    eng: 'To an existing project from a Git repository',
    ru: 'К существующему проекту из Git-репозитория',
  },
  'CreateProjectItem:ZIP_title': {
    eng: 'Upload existing project',
    ru: 'Загрузить существующий проект',
  },
  'CreateProjectItem:ZIP_description': {
    eng: 'Upload archive from your device',
    ru: 'Загрузите архив с вашего устройства',
  },
  'CreateProjectItem:CLONE_title': {
    eng: 'Clone project',
    ru: 'Клонировать проект',
  },
  'CreateProjectItem:CLONE_description': {
    eng: 'From external repository',
    ru: 'Из внешнего репозитория',
  },
  'CreateProjectItem:JAICP-cloud-description': {
    eng: 'The project source code is stored in $[1] or in an external Git repository',
    ru: 'Исходный код проекта хранится внутри $[1] либо во внешнем Git-репозитории',
  },
  'CreateProjectJAICFWay:JAICP-cloud': {
    eng: '$[1] Cloud',
    ru: 'Облако $[1]',
  },
  'CreateProjectJAICFWay:external': {
    eng: 'External hosting service',
    ru: 'Внешний хостинг',
  },
  'CreateProjectJAICFWay:external-description': {
    eng: 'Store bot in an external hosting service. Read more about available hosting options in the <a href="https://help.jaicf.com/Environments" target="_blank">JAICF documentation</a>',
    ru: 'Размещение бота на внешнем хостинге. Варианты описаны в <a href="https://help.jaicf.com/Environments" target="_blank">документации JAICF</a>',
  },
  'CreateProject.messageText-repositoryPassword': {
    eng: 'All contents of the $[1] branch in the external Git repository will be overwritten',
    ru: 'Все содержимое ветки $[1] внешнего Git-репозитория будет перезаписано системным шаблоном',
  },
  'CreateProject_fileUploader-upload': {
    eng: 'Attach ZIP file',
    ru: 'Прикрепите ZIP-файл',
  },
  'CreateProject_fileUploader-upload-subtitle': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
  },
  'CreateProject_fileUploader-fileChosen': {
    eng: 'File attached',
    ru: 'Выбран файл',
  },
  'JaicfSettings-step-one': {
    eng: '1. Clone a bot from',
    ru: '1. Склонируйте бота из',
  },
  'JaicfSettings-step-one-repo': {
    eng: 'the JAICF repository',
    ru: 'репозитория JAICF',
  },
  'JaicfSettings-step-two': {
    eng: '2. Copy the API token and paste it into your bot',
    ru: '2. Скопируйте API-токен и вставьте его в код бота',
  },
  'JaicfSettings-step-three': {
    eng: '3. Select the platform connection method',
    ru: '3. Выберите способ подключения к платформе',
  },
  'editorbe.validation.branch_does_not_exist': {
    eng: 'The specified branch does not exist',
    ru: 'Указанная ветка не существует',
  },
  'SecondSimpleCard Deleting': {
    eng: 'Deleting…',
    ru: 'Удаляем…',
  },
  'BotControls:orderOptions:lastModified': {
    eng: 'Recently updated first',
    ru: 'Сначала последние измененные',
  },
  'BotControls:orderOptions:alphabet': {
    eng: 'Sort by name',
    ru: 'Сортировать по названию',
  },
  'BotControls:orderOptions:new_desc': {
    eng: 'Newest first',
    ru: 'Сначала новые',
  },
  'BotControls:orderOptions:old_desc': {
    eng: 'Oldest first',
    ru: 'Сначала старые',
  },
  'BotControls:not_found': {
    eng: 'Not found',
    ru: 'Ничего не найдено',
  },
  'BotControls:orderOptions:dialog_counts': {
    eng: 'Sort by the number of dialogs per day',
    ru: 'По количеству диалогов за сутки',
  },
  'Dashboard: searchByName input label': {
    eng: 'Project name...',
    ru: 'Название проекта...',
  },
  'BotMenu ChangeTags': {
    eng: 'Change tags',
    ru: 'Изменить теги',
  },
  ADDITIONAL_PACKAGE_REQUEST: {
    eng: '$[1] package is requested',
    ru: 'Запрошен пакет $[1]',
  },
  ADDITIONAL_PACKAGE_ENABLED: {
    eng: 'Package $[1] enabled',
    ru: 'Подключен пакет $[1]',
  },
  'EditSelectedAccountItem: phone': {
    eng: 'Phone number',
    ru: 'Телефон',
  },
  'botadmin.common.weak_auth_token': {
    eng: 'The access token is weak. Use the following mask: \n Ax8:Bx40 (where A is a Latin character in lower- or uppercase, and B is a digit or a Latin character in lower- or uppercase)',
    ru: 'Токен недостаточно сложный. Используйте следующую маску: \n Ax8:Bx40 (А — латинская буква в любом регистре, В — цифра или латинская буква в любом регистре)',
  },
  'ErrorModal: title': {
    eng: 'Error log',
    ru: 'Лог ошибки',
  },
  'ChannelsConfig: force reply jivo label': {
    eng: 'Disable text input when using buttons',
    ru: 'Блокировать ввод текста при использовании кнопок',
  },
  'User surname': {
    eng: 'User surname',
    ru: 'Фамилия пользователя',
    cn: '用户姓氏',
  },
  'User login': {
    eng: 'User surname',
    ru: 'Логин пользователя',
    cn: '用户姓氏',
  },
  'User name': {
    eng: 'User name',
    ru: 'Имя пользователя',
    cn: '用户名',
  },
  'User can again write a bot and communicate with the operator.': {
    eng: 'User can again talk to the bot and chat with the agent.',
    ru: 'Пользователь снова сможет писать боту и общаться с оператором.',
    cn: '用户可以再次与机器人通话并与人工客服交流',
  },
  'User deleting': {
    eng: 'Delete user',
    ru: 'Удаление пользователя',
    cn: '删除用户',
  },
  'User data sent to the operator': {
    eng: 'User data sent to the agent',
    ru: 'Данные пользователя передаваемые оператору',
    cn: '发送至人工客服的用户数据',
  },
  'User password': {
    eng: 'User password',
    ru: 'Пароль пользователя',
    cn: '用户密码',
  },
  'User email': {
    eng: 'User email',
    ru: 'Email пользователя',
    cn: '用户邮件',
  },
  'User id': {
    eng: 'ID',
    ru: 'ID',
  },
  'User log': {
    eng: 'User actions',
    ru: 'Действия пользователей',
    cn: '用户操作历史',
  },
  'User ID / Type': {
    eng: 'User ID / Call type',
    ru: 'User ID / Тип звонка',
    cn: '用户ID/呼叫类型',
  },
  'User count': {
    eng: 'User count',
    ru: 'Количество пользователей',
    cn: '用户数',
  },
  'User package connection': {
    eng: 'Connect user package',
    ru: 'Подключение пакета пользователей',
    cn: '连接用户包',
  },
  'User package info': {
    eng: 'Activate automatically not more than 2 unique user packages when the limit is reached',
    ru: 'Автоматически подключать не более 2 пакетов уникальных пользователей при достижении лимита',
    cn: '当达到限制时自动激活不超过2个独立用户包',
  },
  'User Packs': {
    eng: 'User Packs',
    ru: 'Пакеты пользователей',
    cn: '用户包',
  },
  'user profile': {
    eng: 'Profile',
    ru: 'Настройки профиля',
    cn: '我的用户简介',
  },
  'Event subscriptions request example': {
    eng: 'Event request example',
    ru: 'Пример ссылки для подписки на события',
    cn: '事件订阅请求示例',
  },
  'Select project': {
    eng: 'Select project',
    ru: 'Выберите проект',
    cn: '选择项目',
  },
  'Search:Projects:Placeholder': {
    eng: 'Project search',
    ru: 'Поиск по проектам',
  },
  'Search:Projects:Placeholder:NoFound': {
    eng: 'There are no projects with this name',
    ru: 'Нет проектов с таким названием',
  },
  'Select git source': {
    eng: 'Branch',
    ru: 'Ветка',
    cn: '分支',
  },
  'Select channel type': {
    eng: 'Select channel type',
    ru: 'Выберите тип канала',
    cn: '选择通道类型',
  },
  'Select a date': {
    eng: 'Select date',
    ru: 'Выберите дату',
    cn: '选择日期',
  },
  'Select a time': {
    eng: 'Select time',
    ru: 'Выберите время',
    cn: '选择时间',
  },
  'Select date and time': {
    eng: 'Select date and time',
    ru: 'Выберите дату и время',
    cn: '选择日期与时间',
  },
  'Select the publication date': {
    eng: 'Select publication date',
    ru: 'Выберите дату публикации',
    cn: '选择发布日期',
  },
  'Select a user role': {
    eng: 'Select user role',
    ru: 'Выберите роль пользователя',
    cn: '选择用户角色',
  },
  'Select image': {
    eng: 'Select image',
    ru: 'Выбрать изображение',
    cn: '选择图片',
  },
  'Select a bot': {
    eng: 'Select bot',
    ru: 'Выберите бота',
    cn: '选择机器人',
  },
  'Select a channel': {
    eng: 'Select channel',
    ru: 'Выберите канал',
    cn: '选择通道',
  },
  'Select status': {
    eng: 'Select status',
    ru: 'Выберите статус',
    cn: '选择状态',
  },
  'Select language': {
    eng: 'Select language',
    ru: 'Выберите язык',
    cn: '选择语言',
  },
  'Select channel': {
    eng: 'Select channel',
    ru: 'Выберите канал',
    cn: '选择通道',
  },
  'Select channels': {
    eng: 'Select channels',
    ru: 'Выберите каналы',
    cn: '选择通道',
  },
  'Select a channel from list': {
    eng: 'Select a channel',
    ru: 'Выберите канал из списка',
    cn: '选择通道',
  },
  'Select event type': {
    eng: 'Select event type',
    ru: 'Выберите тип события',
    cn: '选择事件类型',
  },
  'Select a month to generate and download calculations for the affiliate program': {
    eng: 'Select a month to generate and download calculations for the affiliate program',
    ru: 'Выберите месяц, чтобы сформировать и скачать расчеты по партнерской программе',
    cn: '选择生成和下载关联计划的月份',
  },
  'Choose incoming channel': {
    eng: 'Choose inbound channel',
    ru: 'Выберите входящий канал',
    cn: '选择呼入通道',
  },
  'Choose operators channel': {
    eng: 'Choose customer engagement platform',
    ru: 'Выберите операторский канал',
    cn: '选择客户服务平台',
  },
  'Choose widget theme': {
    eng: 'Choose widget theme',
    ru: 'Выберите тему виджета',
    cn: '选择小部件主题',
  },
  'Choose messenger': {
    eng: 'Choose messenger',
    ru: 'Выберите мессенджер',
    cn: '选择Messenger',
  },
  'Choose tags': {
    eng: 'Choose labels',
    ru: 'Выберите метки',
    cn: '选择标签',
  },
  'Choose nlp class': {
    eng: 'Choose nlp class',
    ru: 'Выберите nlp класс',
    cn: '选择NLP类',
  },
  'Choose nlp state': {
    eng: 'Choose nlp state',
    ru: 'Выберите nlp стейт',
    cn: '选择NLP状态',
  },
  'Choose template': {
    eng: 'Choose template',
    ru: 'Выберите шаблон',
    cn: '选择模板',
  },
  'Choose SIP trunk': {
    eng: 'Select a connection',
    ru: 'Выберите подключение',
    cn: '选择一个连接',
  },
  'Choose call list': {
    eng: 'Select a call list',
    ru: 'Выберите список номеров',
    cn: '选择一个呼叫列表',
  },
  'Choose ready intent': {
    eng: 'Choose a canned intent',
    ru: 'Выбрать готовый интент',
    cn: '选择一个预设的意图',
  },
  'Choose ready intent title': {
    eng: 'Choose the intent',
    ru: 'Выбор готовых интентов',
    cn: '选择意图',
  },
  'Choose ready intent body p': {
    eng: 'Choose one or more of the canned intents',
    ru: 'Выберите один или более интентов',
    cn: '选择一个或多个预设的意图',
  },
  'Choose ready intent choose': {
    eng: 'Choose the intent',
    ru: 'Выбрать',
    cn: '选择意图',
  },
  'Choose state from dropdown': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'Enter API key': {
    eng: 'Enter API key',
    ru: 'Введите ключ API',
    cn: '输入API密钥',
  },
  'Channels:LivetexVisitor:Department': {
    eng: 'Agent group',
    ru: 'Группа операторов',
  },
  'Channels:LivetexVisitor:InvalidToken': {
    eng: 'Invalid token',
    ru: 'Недействительный токен',
  },
  'Channels:LivetexVisitor:Department:SupText': {
    eng: 'Specify a token to display the group list',
    ru: 'Укажите токен, чтобы появился список групп',
  },
  'Channels:LivetexVisitor:NoDepartmentsAvailable': {
    eng: 'There are no agent groups available',
    ru: 'Нет доступных групп операторов',
  },
  'Channels:LivetexVisitor:DefaultDepartment': {
    eng: 'Default agent group',
    ru: 'Группа операторов по умолчанию',
  },
  'Channels:LivetexVisitor:DownloadDepartments': {
    eng: 'The group list is loading…',
    ru: 'Загрузка групп…',
  },
  'ChannelSaveError:chatadapter.messaging.livetex_visitor_invalid_response_body': {
    eng: 'Invalid token',
    ru: 'Недействительный токен',
  },
  'Enter captcha': {
    eng: 'Enter captcha',
    ru: 'Введите капчу',
    cn: '输入验证码',
  },
  'Enter token': {
    eng: 'Enter token',
    ru: 'Введите токен',
    cn: '输入令牌',
  },
  'Enter URL': {
    eng: 'Enter URL',
    ru: 'Введите URL',
    cn: '输入网址',
  },
  'Enter sender Name': {
    eng: 'Enter sender Name',
    ru: 'Введите имя отправителя',
    cn: '输入发送人姓名',
  },
  'Enter to the account': {
    eng: 'Sign in',
    ru: 'Войти в аккаунт',
    cn: '进入帐户',
  },
  'Enter user name': {
    eng: 'Enter user name',
    ru: 'Введите имя пользователя',
    cn: '输入用户名',
  },
  'Enter user surname': {
    eng: 'Enter user last name',
    ru: 'Введите фамилию пользователя',
    cn: '输入用户姓',
  },
  'Enter user email': {
    eng: 'Enter user email',
    ru: 'Введите email пользователя',
    cn: '输入用户邮箱',
  },
  'Enter user login': {
    eng: 'Enter user login',
    ru: 'Введите логин пользователя',
    cn: '输入用户名',
  },
  'Enter channel ID': {
    eng: 'Enter channel ID',
    ru: 'Введите ID канала',
  },
  'Enter integration token': {
    eng: 'Enter integration token',
    ru: 'Введите токен интеграции',
  },
  'Enter greeting message': {
    eng: 'Enter greeting message',
    ru: 'Введите приветственное сообщение',
  },
  'Enter i-digital source': {
    eng: 'Enter the account name',
    ru: 'Введите имя учетной записи',
  },
  'Enter whatsapp phoneNumberId': {
    eng: 'Enter the phone number id',
    ru: 'Введите id номера телефона',
  },
  'Enter login in format name@host': {
    eng: 'Enter login in name@host format',
    ru: 'Введите логин формата name@host',
    cn: '以name@host格式输入登陆名',
  },
  'Enter text': {
    eng: 'Enter text',
    ru: 'Введите текст',
    cn: '输入文本',
  },
  'Enter a number between 1 and 5': {
    eng: 'Enter a number between 1 and 5',
    ru: 'Введите число от 1 до 5',
    cn: '输入一个1到5之间的数字',
  },
  'Enter verify token': {
    eng: 'Enter verification token',
    ru: 'Введите токен верификации',
    cn: '输入验证令牌',
  },
  'Enter application token': {
    eng: 'Enter application token',
    ru: 'Введите токен приложения',
    cn: '输入应用程序令牌',
  },
  'Enter OAuth client id': {
    eng: 'Enter OAuth client ID',
    ru: 'Введите идентификатор клиента OAuth',
    cn: '输入OAuth客户端ID',
  },
  'Enter domain': {
    eng: 'Enter domain',
    ru: 'Введите домен',
    cn: '输入域名',
  },
  'Enter user password': {
    eng: 'Enter user password',
    ru: 'Введите пароль пользователя',
    cn: '输入用户密码',
  },
  'Enter provider id': {
    eng: 'Enter provider ID',
    ru: 'Введите идентификатор провайдера',
  },
  'Enter widget config JSON': {
    eng: 'Enter widget configuration JSON',
    ru: 'Вставьте скопированные параметры виджета',
    cn: '输入小部件配置JSON',
  },
  'Enter OAuth token': {
    eng: 'Enter OAuth token',
    ru: 'Введите OAuth токен',
    cn: '输入OAuth令牌',
  },
  'Enter text to search': {
    eng: 'Enter the text that you want to search for...',
    ru: 'Введите текст для поиска',
    cn: '输入要搜索的文本…',
  },
  'Enter host or ip': {
    eng: 'Specify host or IP address',
    ru: 'Введите хост или IP-адрес',
    cn: '指定主机或IP地址',
  },
  'Enter call task name': {
    eng: 'Define campaign name',
    ru: 'Введите название обзвона',
    cn: '定义活动名称',
  },
  'Enter Email': {
    eng: 'Enter Email',
    ru: 'Введите email',
    cn: '输入邮箱',
  },
  'Enter appID': {
    eng: 'Enter your appID',
    ru: 'Введите appID',
    cn: '输入您的appID',
  },
  'Enter appsecret': {
    eng: 'Enter your appsecret',
    ru: 'Введите appsecret',
    cn: '输入您的appsecret',
  },
  'Enter login': {
    eng: 'Enter login',
    ru: 'Ведите логин',
    cn: '输入登录名',
  },
  'Enter old password error': {
    eng: 'Your old and new passwords must not match',
    ru: 'Старый и новый пароли не должны совпадать',
  },
  'Copy token popover': {
    eng: 'Copy API token',
    ru: 'Скопировать API-токен',
    cn: '复制API令牌',
  },
  'Channel $[1] editing': {
    eng: 'Edit channel: $[1]',
    ru: 'Редактирование канала: $[1]',
    cn: '编辑通道：$[1]',
  },
  'Channel creation': {
    eng: 'Connect channel',
    ru: 'Подключение канала',
    cn: '创建通道',
  },
  'Channel type': {
    eng: 'Channel type',
    ru: 'Тип канала',
    cn: '通道类型',
  },
  'Channel type not selected': {
    eng: 'Channel type not selected',
    ru: 'Не выбран тип канала',
    cn: '未选择通道类型',
  },
  'Channel ID': {
    eng: 'Channel ID',
    ru: 'ID канала',
    cn: '通道ID',
  },
  'Channel name': {
    eng: 'Channel name',
    ru: 'Название канала',
    cn: '通道名称',
  },
  'Channel status OK': {
    eng: 'Works',
    ru: 'Работает',
    cn: '工作',
  },
  'Channel status TEST_FAILED': {
    eng: 'Tests failed',
    ru: 'Тесты провалены',
    cn: '测试失败',
  },
  'Channel status DEPLOY_FAILED': {
    eng: 'Deployment failed',
    ru: 'Публикация провалена',
    cn: '部署失败',
  },
  'Channel status UNAVAILABLE': {
    eng: 'Unavailable',
    ru: 'Недоступно',
    cn: '不可用',
  },
  'Channel status DEPLOYED_MANUALLY': {
    eng: 'Works',
    ru: 'Работает',
    cn: '工作',
  },
  'Channel status IN_PROGRESS': {
    eng: 'In progress',
    ru: 'Публикуется',
    cn: '进行中',
  },
  'channel 1': {
    eng: 'channel',
    ru: 'каналу',
    cn: '通道',
  },
  'channel 2': {
    eng: 'channels',
    ru: 'каналам',
    cn: '通道',
  },
  'channel 5': {
    eng: 'channels',
    ru: 'каналам',
    cn: '通道',
  },
  'channel page Select a channel': {
    eng: 'Select a channel',
    ru: 'Выберите канал для подключения',
    cn: '选择一个通道',
  },
  'Channel not found!': {
    eng: 'Channel not found!',
    ru: 'Канал не найден!',
    cn: '找不到通道！',
  },
  'Call list': {
    eng: 'List of phone numbers',
    ru: 'Список номеров',
    cn: '电话号码列表',
  },
  'Call lists': {
    eng: 'Phone numbers',
    ru: 'Списки номеров',
    cn: '电话号码',
  },
  'Client lists': {
    eng: 'Client lists',
    ru: 'Списки клиентов',
  },
  'Call list name': {
    eng: 'List name',
    ru: 'Название',
    cn: '列表名称',
  },
  'Call list size': {
    eng: 'Numbers in the list',
    ru: 'Номеров',
    cn: '列表中的数量',
  },
  'Call list creation': {
    eng: 'Create a list of phone numbers',
    ru: 'Создание списка номеров',
    cn: '创建电话号码列表',
  },
  'call number': {
    eng: 'Enter your phone number to receive a test call',
    ru: 'Позвонить на номер',
    cn: '输入您的电话号码以便接收测试呼叫',
  },
  'Telephony title': {
    eng: 'PROMPTERS',
    ru: 'СУФЛЕРЫ',
    cn: '提示器',
  },
  'Telephony settings': {
    eng: 'SIP server connection',
    ru: 'Настройка телефонии',
    cn: '连接SIP服务器',
  },
  'telephony channel used': {
    eng: 'This connection is already being used for inbound calls in another project. For inbound calls, select another connection or add a new one in SIP server connection section',
    ru: 'Входящий канал уже используется в другом проекте. Чтобы принимать входящие звонки выберите другой SIP-транк или подключите новый в настройках телефонии',
    cn: '此连接已用于其他项目中的呼入。要呼入，请在SIP服务器连接部分中选择另一个连接或添加新连接“，',
  },
  'Telephony test channel info': {
    eng: 'To test inbound calls, dial the phone number provided by your SIP server',
    ru: 'Для тестирования входящего звонка позвоните с мобильного телефона на номер, предоставленный SIP сервером',
    cn: '要测试呼入，请拨打为您的SIP服务器提供的电话号码',
  },
  'telephony settings required fields': {
    eng: 'Define obligatory settings',
    ru: 'Заполните обязательные поля',
    cn: '定义强制设置',
  },
  'telephony channel outgoing and incoming': {
    eng: 'Inbound and outbound calls',
    ru: 'Входящие и исходящие',
    cn: '呼入和呼出',
  },
  'telephony channel outgoing': {
    eng: 'Outbound calls',
    ru: 'Исходящие',
    cn: '呼出',
  },
  'telephony report telephonyBillingTimeCount': {
    eng: 'Billing scope',
    ru: 'Объем тарификации',
    cn: '计费范围',
  },
  'telephony report telephonyOutgoingCallsCount': {
    eng: 'Number of outbound calls',
    ru: 'Количество исходящих звонков',
    cn: '呼出号码',
  },
  'telephony report telephonyIncomingCallsCount': {
    eng: 'Number of inbound calls',
    ru: 'Количество входящих звонков',
    cn: '呼入号码',
  },
  'telephony report telephonyAverageCallDurationInSeconds': {
    eng: 'Average call duration',
    ru: 'Средняя длительность звонка',
    cn: '平均通话时长',
  },
  'telephony report telephonyBillingTimeCount label': {
    eng: 'Minute consumption',
    ru: 'Расход минут',
    cn: '通话分钟消耗量',
  },
  'telephony report telephonyOutgoingCallsCount label': {
    eng: 'Number of outbound calls',
    ru: 'Число исходящих звонков',
    cn: '呼出号码',
  },
  'telephony report telephonyIncomingCallsCount label': {
    eng: 'Number of inbound calls',
    ru: 'Число входящих звонков',
    cn: '呼入号码',
  },
  'telephony report telephonyOutgoingCallsCount tooltip': {
    eng: 'The number of calls is calculated for the calls accepted by a user',
    ru: 'Количество звонков рассчитывается для звонков, в которых пользователь снял трубку',
    cn: '呼叫数从用户接听数得出',
  },
  'telephony report telephonyIncomingCallsCount tooltip': {
    eng: 'The number of calls is calculated for the calls accepted by a bot',
    ru: 'Количество звонков рассчитывается для звонков, в которых бот снял трубку',
    cn: '呼叫数从机器人接听数得出',
  },
  'ChannelSaveError:chatadapter.common.invalid_url_format': {
    eng: 'Invalid URL format',
    ru: 'Некорректный формат URL',
  },
  'Imputer: sidebar label': {
    eng: 'Hybrid speech synthesis',
    ru: 'Гибридный синтез речи',
  },
  'account details': {
    eng: 'Account',
    ru: 'Личный кабинет',
    cn: '套餐详情',
  },
  'Call tasks': {
    eng: 'Outbound call campaigns',
    ru: 'Обзвоны',
    cn: '呼出活动',
  },
  'Mailing tasks': {
    eng: 'Text campaigns',
    ru: 'Рассылки',
  },
  'Call task creation': {
    eng: 'Creating a new campaign',
    ru: 'Создание нового обзвона',
    cn: '创建一个活动',
  },
  'Sidebar: tariff and limits': {
    eng: 'My plan and limits',
    ru: 'Мой тариф и лимиты',
  },
  'Sidebar: until time': {
    eng: 'until',
    ru: 'до',
  },
  'Sidebar: all plans': {
    eng: 'All subscription plans',
    ru: 'Все тарифы',
  },
  'Account managing': {
    eng: 'Account management $[1]',
    ru: 'Управление аккаунтом $[1]',
    cn: '账户管理$[1]',
  },
  'Account event date': {
    eng: 'Date',
    ru: 'Дата',
    cn: '日期',
  },
  'Account event': {
    eng: 'Event',
    ru: 'Событие',
    cn: '事件',
  },
  'Account event author': {
    eng: 'Modified by',
    ru: 'Автор изменения',
    cn: '修改者',
  },
  'Use button label': {
    eng: 'Disable text input when using buttons',
    ru: 'Блокировать ввод текста при использовании кнопок',
  },
  'htmlToMdEnable button label': {
    eng: 'Convert HTML to Markdown',
    ru: 'Конвертировать HTML в Markdown',
  },
  'TokensPage:Tokens': {
    eng: 'Secrets',
    ru: 'Токены',
  },
  'Register: phone': {
    eng: 'Phone number',
    ru: 'Телефон',
  },
  'Register: phone helper': {
    eng: 'Optional field',
    ru: 'Необязательное поле',
  },
  'Register: phone error': {
    eng: 'Enter the full phone number',
    ru: 'Введите номер телефона полностью',
  },
  'TelephonySettings authUsername': {
    eng: 'Use username for authorization',
    ru: 'Использовать Username для авторизации',
  },
  'TelephonySettings ignorePort': {
    eng: 'Ignore port',
    ru: 'Игнорировать порт',
  },
  'TelephonySettings use locale domain toggle': {
    eng: 'Use local server domain as SIP FROM header',
    ru: 'Передавать локальный домен в SIP-заголовке FROM',
  },
  'KeysAndPlans unique users': {
    eng: 'Unique users',
    ru: 'Уникальные пользователи',
    cn: '独立用户',
  },
  'KeysAndPlans balance': {
    eng: 'Left',
    ru: 'Остаток',
    cn: '剩余',
  },
  'KeysAndPlans tooltip sessions': {
    eng: 'Your subscription plan is limited to a number of sessions. A session is a dialog between user and bot constrained by duration or specific signs of an ending.',
    ru: 'Использование платформы тарифицируется по количеству сессий. Тарификационная сессия — текстовое или голосовое общение бота на естественном языке с одним клиентом, ограниченное временем или явным признаком окончания общения.',
    cn: '您的订阅计划仅限于数个会话。会话即用户和机器人之间的对话，受持续时间或特定结束符号的限制。',
  },
  'KeysAndPlans tooltip uniques': {
    eng: 'Your subscription plan is limited to a number of unique users served by all bots. Users are considered to be different on the basis of unique identifier provided by each channel.',
    ru: 'Использование платформы тарифицируется по числу пользователей, которые могут вступить в диалог с ботом в течение периода. Пользователи различаются по идентификатору, который присваивается им каналом общения.',
    cn: '您的订阅计划仅限于由所有机器人提供服务的多个独立用户。每个通道提供的独立标识符不同，则用户也被认为是不同的。',
  },
  'KeysAndPlans sessions title': {
    eng: 'Sessions',
    ru: 'Сессии',
    cn: '会话',
  },
  'Integration: CreateModalLabel': {
    eng: 'Create integration',
    ru: 'Создание интеграции',
  },
  'Integration: Info': {
    eng: 'No integrations created',
    ru: 'Здесь будут отображаться текущие интеграции',
  },
  'Integration: create button': {
    eng: 'Create integration',
    ru: 'Создать интеграцию',
  },
  'Integration: Delete channel question?': {
    eng: 'Do you want to remove this integration?',
    ru: 'Вы действительно хотите удалить эту интеграцию?',
  },
  'Integration: Remove integration': {
    eng: 'Remove',
    ru: 'Удалить',
  },
  'Integration: IntegrationId': {
    eng: 'IntegrationId:',
    ru: 'IntegrationId:',
  },
  'UserSettings: user full name': {
    eng: 'Full name',
    ru: 'Имя и фамилия',
  },
  'Copy YANDEX': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'Copy CHAT_WIDGET': {
    eng: 'Get script',
    ru: 'Получить скрипт',
    cn: '获取脚本',
  },
  'Copy ZENBOX': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'Copy JAICP_ALEXA': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'Copy DIALOGFLOW': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'Copy FACEBOOK': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'Copy INSTAGRAM': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'Copy THREADS': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy I_DIGITAL': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy INCOMING_JIVOSITE': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy INCOMING_WEBIM2': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy INBOUND_LIVETEX': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy AZURE': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy MARUSIA': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy WAZZUP': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy WHATSAPP': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy SBER': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy EDNA_PLATFORM': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取Webhook',
  },
  'Copy PROMPTER_API': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'CreateProject:location_manual_title': {
    eng: 'Repository settings',
    ru: 'Настройки репозитория',
  },
  'Account page link text': {
    eng: 'Dashboard',
    ru: 'Личном кабинете',
    cn: '仪表板',
  },
  'Imputer: voice setup label': {
    eng: 'Voices for hybrid synthesis (imputer)',
    ru: 'Голоса для гибридного синтеза (импьютера)',
  },
  'Imputer: voices placeholder': {
    eng: 'Voices',
    ru: 'Голоса',
  },
  'Imputer: update token': {
    eng: 'Reissue',
    ru: 'Перевыпустить',
  },
  'Imputer: activate token': {
    eng: 'Resume',
    ru: 'Возобновить',
  },
  'Imputer: deactivate token': {
    eng: 'Stop',
    ru: 'Остановить',
  },
  'Imputer: token copy label': {
    eng: 'Copy token',
    ru: 'Копировать токен',
  },
  'TelephonyTtsSettings token': {
    eng: 'Token',
    ru: 'Токен',
  },
  'PartnerBlock: partner status BLOCKED': {
    eng: 'Blocked',
    ru: 'Заблокирован',
    cn: '锁定',
  },
  'PartnerBlock: partner status ACTIVE': {
    eng: 'Active',
    ru: 'Активный',
    cn: '有效',
  },
  'PartnerBlock: partner status UNVERIFIED': {
    eng: 'Not verified',
    ru: 'Неверифицированный',
  },
  'Channels:getLog': {
    eng: 'Download log',
    ru: 'Скачать лог',
  },
  'Channels:getLogModalTitle': {
    eng: 'Download channel system log',
    ru: 'Скачать системный лог канала',
  },
  'Channels:getLogModalGet': {
    eng: 'Download',
    ru: 'Скачать',
  },
  'Channels:getLogModalPeriod': {
    eng: 'During the period',
    ru: 'За период',
  },
  'Channels:getLogModalAllTime': {
    eng: 'All available',
    ru: 'Все доступные',
  },
  'Copy BITRIX': {
    eng: 'Get webhook',
    ru: 'Получить вебхук',
    cn: '获取webhook',
  },
  'ReactItem no reactions': {
    eng: 'Add the campaign text',
    ru: 'Добавьте текст рассылки',
  },
  'Choose account': {
    eng: 'Select an account',
    ru: 'Выберите аккаунт',
  },
  'Expiration date': {
    eng: 'Expiration date',
    ru: 'Срок годности',
  },
  'Date activation': {
    eng: 'Activation date',
    ru: 'Дата активации',
  },
  Limit: {
    eng: 'Limit',
    ru: 'Лимит',
    cn: 'limit',
  },
  '1 session': {
    eng: 'session',
    ru: 'сессия',
  },
  '2 sessions': {
    eng: 'sessions',
    ru: 'сессии',
  },
  'many sessions': {
    eng: 'sessions',
    ru: 'сессий',
  },
  'GlobalAlert: update news error': {
    eng: 'Error occurred while updating the news',
    ru: 'Ошибка при обновлении новости',
    cn: 'Failed to update the news',
  },
  'GlobalAlert: key life period': {
    eng: 'The key is valid from $[1] \n till $[2]',
    ru: 'Действие ключа с $[1] \n до $[2]',
  },
  'GlobalAlert: unlimited key': {
    eng: 'The key is valid indefinitely',
    ru: 'Действие ключа бессрочно',
  },
  'GlobalAlert: succes activated key': {
    eng: 'The key is activated',
    ru: 'Ключ активирован успешно',
    cn: 'The key has been activated',
  },
  'unics amount': {
    eng: '$[1] unique users',
    ru: '$[1] уников',
  },
  'Link to information': {
    eng: 'Information link',
    ru: 'Ссылка на информацию',
  },
  'Link to application': {
    eng: 'Application link',
    ru: 'Ссылка на заявку',
  },
  'Add credit': {
    eng: 'Add loan',
    ru: 'Добавить кредит',
  },
  'Edit credit': {
    eng: 'Edit loan',
    ru: 'Редактирование кредита',
  },
  'text on button': {
    eng: 'Button text',
    ru: 'Текст на\n кнопке',
  },
  'up to': {
    eng: 'to ',
    ru: 'до ',
  },
  'NewsList:Errors: value should be between 0 and 20': {
    eng: 'The value must be between 0 and 20',
    ru: 'Значение должно быть в пределах от 0 до 20',
  },
  'Error start': {
    eng: 'Error: \n',
    ru: 'Ошибка: \n',
  },
  'Something goes wrong': {
    eng: 'Something went wrong',
    ru: 'Что-то пошло не так',
  },
  'Enter the key': {
    eng: 'Enter a key',
    ru: 'Введите ключ',
  },
  'HttpRequestBody:placeholder': {
    eng: ' ',
    ru: ' ',
  },
  'ChannelType LIVETEX': {
    eng: 'LiveTex (obsolete)',
    ru: 'LiveTex (устарел)',
  },
  'CreateProject:location_github_title': {
    eng: 'GitHub repository settings',
    ru: 'Настройки репозитория GitHub',
  },
  'CreateProject:location_bitbucket_title': {
    eng: 'Bitbucket repository settings',
    ru: 'Настройки репозитория Bitbucket',
  },
  'CloudMenu: aimylogic': {
    eng: 'Aimylogic',
    ru: 'Aimylogic',
  },
  'CloudMenu: Tovie DialogStudio': {
    eng: 'Tovie DialogStudio',
    ru: 'Tovie DialogStudio',
  },
  'CloudMenu: aimyvoice': {
    eng: 'Aimyvoice',
    ru: 'Aimyvoice',
  },
  'CloudMenu: Tovie Voice': {
    eng: 'Tovie Voice',
    ru: 'Tovie Voice',
  },
  'CloudMenu: caila': {
    eng: 'CAILA NLP Platform',
    ru: 'CAILA NLP Platform',
  },
  'CloudMenu: Tovie NLP': {
    eng: 'Tovie NLP Platform',
    ru: 'Tovie NLP Platform',
  },
  'CloudMenu: cc': {
    eng: 'Conversational Cloud',
    ru: 'Conversational Cloud',
  },
  'CloudMenu: Tovie Cloud': {
    eng: 'Tovie Cloud',
    ru: 'Tovie Cloud',
  },
  'CloudMenu: jaicp': {
    eng: 'JAICP',
    ru: 'JAICP',
  },
  'CloudMenu: Tovie Platform': {
    eng: 'Tovie Platform',
    ru: 'Tovie Platform',
  },
  'CloudMenu: aimychat': {
    eng: 'Aimychat',
    ru: 'Aimychat',
  },
  'CloudMenu: Tovie Agent': {
    eng: 'Tovie Agent',
    ru: 'Tovie Agent',
  },
  'StatesGroup:Title': {
    eng: 'Group',
    ru: 'Группа',
  },
  'IncomingOutgoingConnections:incomingTitle': {
    eng: 'Incoming links',
    ru: 'Входящие связи',
  },
  'IncomingOutgoingConnections:outgoingTitle': {
    eng: 'Outgoing links',
    ru: 'Исходящие связи',
  },
  'CreationScreenMenu:inGroupInfoTitle': {
    eng: 'Full state name',
    ru: 'Полное название стейта',
  },
  'Sidebar:CodeEditor': {
    eng: 'Code',
    ru: 'Код',
  },
  'Sidebar:VisualEditor': {
    eng: 'J-Graph',
    ru: 'J-Graph',
  },
  'CreationScreenMenu:parentSelectionTitle': {
    eng: 'Nest into another state',
    ru: 'Вложить в стейт',
  },
  'CreationScreenMenu:parentSelectionPlaceholder': {
    eng: 'Select from the list',
    ru: 'Выберите из списка',
  },
  'Unexpected error with uuid': {
    eng: 'Unexpected error. uuid: $[1]',
    ru: 'Непредвиденная ошибка. uuid: $[1]',
  },
  'EditIntentsModal:title': {
    eng: 'Intent selection',
    ru: 'Выбор интента',
  },
  'VisualEditor:autoplaceButton': {
    eng: 'Autopositioning',
    ru: 'Авторасстановка',
  },
  'Sip trunk display name': {
    eng: 'Connection name',
    ru: 'Название подключения',
    cn: 'Connection name',
  },
  displayName: {
    eng: 'Connection name',
    ru: 'Название подключения',
    cn: 'Connection name',
  },
  'botadmin.validation.inconsistent': {
    eng: 'Field validation failed: ',
    ru: 'Ошибка валидации поля: ',
    cn: 'Field validation failed: ',
  },
  'Required Field': {
    eng: 'The field is required',
    ru: 'Обязательное поле',
  },
  'Sessions count': {
    eng: 'Total sessions',
    ru: 'Всего сессий',
    cn: '会话总数',
  },
  'Sessions count tooltip': {
    eng: 'The total number of sessions for the selected period.',
    ru: 'Суммарное количество всех сессий за выбранный период.',
    cn: '所选期间的会话总数',
  },
  'NLUWidgetModalTitle:valid': {
    eng: 'Intent rules fulfilled',
    ru: 'Правила интента выполнены',
  },
  'NLUWidgetModalTitle:invalid': {
    eng: 'Intent rules not fulfilled',
    ru: 'Правила интента не выполнены',
  },
  'NLUWidgetModalSubtitle:valid': {
    eng: 'The intent will be recognized in the dialog',
    ru: 'Интент будет распознан в диалоге',
  },
  'NLUWidgetModalSubtitle:invalid': {
    eng: 'The intent will not be recognized in the dialog',
    ru: 'Интент не будет распознан в диалоге',
  },
  NLUWidgetModalTableExecute: {
    eng: 'Fulfilled',
    ru: 'Выполнено',
  },
  NLUWidgetRulesNotSpecified: {
    eng: 'No rules are configured',
    ru: 'Правила не настроены',
  },
  JAICP: {
    eng: 'JAICP',
  },
  'Tovie Platform': {
    eng: 'Tovie Platform',
  },
  'Tovie NLP': {
    eng: 'Tovie NLP',
  },
  CAILA: {
    eng: 'CAILA',
  },
  'Just AI': {
    eng: 'Just AI',
  },
  'Tovie AI': {
    eng: 'Tovie AI',
  },
  'Channels:ChatApi:Connection_settings': {
    eng: 'Connection settings',
    ru: 'Настройки подключения',
    cn: 'Connection settings',
  },
  'Channels:ChatApi:Advanced': {
    eng: 'Advanced',
    ru: 'Дополнительно',
    cn: 'Advanced',
  },
  'Channels:ChatApi:Subtitle': {
    eng: 'Subtitle',
    ru: 'Подзаголовок',
    cn: 'Subtitle',
  },
  'Channels:ChatApi:Deeplink': {
    eng: 'Deep link',
    ru: 'Прямая ссылка',
    cn: 'Deep link',
  },
  'Channels:ChatApi:Order': {
    eng: 'Order',
    ru: 'Порядок',
    cn: 'Order',
  },
  'Channels:ChatApi:URL': {
    eng: 'URL',
    ru: 'URL',
    cn: 'URL',
  },
  'Channels:ChatApi:Enter_title': {
    eng: 'Enter title',
    ru: 'Введите заголовок',
    cn: 'Enter title',
  },
  'Channels:ChatApi:Enter_number': {
    eng: 'Enter number',
    ru: 'Введите число',
    cn: 'Enter number',
  },
  'Channels:ChatApi:Disabled': {
    eng: 'Disabled',
    ru: 'Выключен',
    cn: 'Disabled',
  },
  Free: {
    eng: 'Free',
    ru: 'Free',
  },
  'Sidebar:ProjectGroupEdit': {
    eng: 'Group management',
    ru: 'Управление группой',
  },
  'Sidebar:ProjectReports': {
    eng: 'Project reports',
    ru: 'Отчеты по проектам',
  },
  'ProjectGroupUsersPage:BE-error:accountsadmin.user.email.invalid': {
    eng: 'No user with this login was found. Try inviting them by email instead.',
    ru: 'Пользователь с таким логином не найден. Попробуйте пригласить его по email.',
  },
  'ProjectGroupUsersPage:BE-error:accountsadmin.account.too.many.users.added': {
    eng: 'This group has reached the limit on the number of invited members. New users cannot join it.',
    ru: 'Достигнуто максимальное количество приглашенных членов группы. Новые пользователи не cмогут к ней присоединиться.',
  },
  'ProjectGroupUsersPage:BE-error:accountsadmin.account.invitation.internal.user.error': {
    eng: 'This user cannot be invited to join the group.',
    ru: 'Этого пользователя нельзя пригласить в группу.',
  },
  'ProjectGroupUsersPage:BE-error:accountsadmin.account.user.already.invited': {
    eng: 'This user is already invited to the group.',
    ru: 'Этот пользователь уже приглашен в группу.',
  },
  'ProjectGroupUsersPage:BE-error:accountsadmin.account.invitation.not.found.by.user': {
    eng: 'Failed to join the group. The invitation to the group was revoked.',
    ru: 'Не удалось присоединиться к группе. Приглашение в группу было отозвано.',
  },
  'ProjectGroupUsersPage:BE-error:accountsadmin.account.invitation.not.found.by.token': {
    eng: 'Failed to join the group. The invitation link is invalid or has expired.',
    ru: 'Не удалось присоединиться к группе. Ссылка на приглашение некорректна или ее срок действия истек.',
  },
  'ProjectGroupLeaveModal:Title': {
    eng: 'Leave the group',
    ru: 'Выйти из группы',
  },
  'ProjectGroupLeaveModal:Text': {
    eng: 'Are you sure you want to leave the group? Access to this group and its projects will be denied.',
    ru: 'Вы действительно хотите выйти из группы? Доступ к группе и её проектам будет закрыт.',
  },
  'ProjectGroupLeaveModal:Button:Ok': {
    eng: 'Leave',
    ru: 'Выйти',
  },
  'ProjectGroupLeaveModal:Button:Cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  'LabelComponentSelectState:Create_new_item': {
    eng: 'Create new item',
    ru: 'Создать',
  },
  'ProjectGroupUsersPage:BE-error:accountsadmin.user.by.login.not.found': {
    eng: 'The user is not registered yet. Please contact the platform administrator to register them.',
    ru: 'Пользователь еще не зарегистрирован. Свяжитесь с администратором платформы, чтобы зарегистрировать его.',
  },
  loaded: {
    eng: 'Uploaded',
    ru: 'Загружен',
  },
  saveAnyway: {
    eng: 'Save anyway',
    ru: 'Все равно сохранить',
  },
  similarsSettingBtn: {
    eng: 'Set up search for matches',
    ru: 'Настроить поиск совпадений',
  },
  'LicenseRestriction:Title': {
    eng: 'License restriction',
    ru: 'Ограничение лицензии',
  },
  'LicenseRestriction:TelephonyText': {
    eng: 'You can only use inbound channels and customer engagement platforms. To use telephony, please contact the administrator to upgrade the $[1] license.',
    ru: 'Вы можете использовать только входящие и операторские каналы. Чтобы использовать телефонию, обратитесь к администратору для расширения лицензии $[1].',
  },
  'LicenseRestriction:TextChannelText': {
    eng: 'You can only use telephony. To use inbound channels and customer engagement platforms, please contact the administrator to upgrade the $[1] license.',
    ru: 'Вы можете использовать только телефонию. Чтобы использовать входящие и операторские каналы, обратитесь к администратору для расширения лицензии $[1].',
  },
  'VscodeExtensionBanner:Text': {
    eng: 'You can now work on JAICP projects using our extension for Visual Studio Code!',
    ru: 'Теперь вы можете работать над проектами JAICP с помощью нашего расширения для Visual Studio Code!',
  },
  'VscodeExtensionBanner:LeanMore': {
    eng: 'Learn more',
    ru: 'Узнать больше',
  },
  'VscodeExtensionBanner:Reject': {
    eng: 'No thanks',
    ru: 'Нет, спасибо',
  },
  'VscodeExtensionBanner:Link': {
    eng: '/developer-tools/vscode-extension',
    ru: '/developer-tools/vscode-extension',
  },
  'VscodeExtensionModal:Title': {
    eng: 'JAICP Extension for Visual Studio Code',
    ru: 'Расширение JAICP для Visual Studio Code',
  },
  'VscodeExtensionModal:Description': {
    eng:
      'Work with JAICP from VS Code! Using our extension, you can write code on your local computer and use JAICP to run it.<br />' +
      'The extension supports JAICP DSL syntax highlighting and autocompletion. You can use it alongside any other extension and feature of this editor.',
    ru:
      'Работайте с JAICP из VS Code! С помощью нашего расширения вы можете писать код на своем компьютере, а запускать его в JAICP.<br />' +
      'Расширение поддерживает подсветку синтаксиса и автодополнение JAICP DSL. Кроме него вы можете использовать любые другие расширения и возможности этого редактора.',
  },
  'Channel:EdnaWhatsApp:Cascade': {
    eng: 'Cascade',
    ru: 'Каскад',
  },
  'Channel:EdnaWhatsApp:Label:APIkey': {
    eng: 'API key',
    ru: 'Ключ API',
  },
  'Channel:EdnaWhatsApp:Placeholder:APIkey': {
    eng: 'Enter API key',
    ru: 'Введите ключ API',
  },
  'SadminPanel:Button:CC': {
    eng: 'Go to Cloud',
    ru: 'Перейти в Cloud',
  },
  'yandex kassa': {
    eng: 'YooMoney',
    ru: 'ЮKassa',
    pt: 'YooMoney',
  },
};
