import { Ace } from 'ace-builds';
import { BaseCompleter, CompletionType } from './BaseCompleter';
import {
  JS_$Analytics_COMPLETIONS,
  JS_$Caila_COMPLETIONS,
  JS_$Context_COMPLETIONS,
  JS_$Dialer_COMPLETIONS,
  JS_$Faq_COMPLETIONS,
  JS_$Gpt_COMPLETIONS,
  JS_$Http_COMPLETIONS,
  JS_$Imputer_COMPLETIONS,
  JS_$Integration_COMPLETIONS,
  JS_$JsApi_COMPLETIONS,
  JS_$Mail_COMPLETIONS,
  JS_$Nlp_COMPLETIONS,
  JS_$ParseTree_COMPLETIONS,
  JS_$Pushgate_COMPLETIONS,
  JS_$Reactions_COMPLETIONS,
  JS_$Request_COMPLETIONS,
  JS_$Request_data_COMPLETIONS,
  JS_$Request_userFrom_COMPLETIONS,
  JS_$Response_replies_push_COMPLETIONS,
  ES6_UNSUPPORTED_BUILTINS_REGEX,
  JS_$conversationApi_COMPLETIONS,
  JS_$aimychat_COMPLETIONS,
  JS_$textCampaign_COMPLETIONS,
} from './completionConstants';

export class ExtendedJsCompleter extends BaseCompleter {
  completers: Record<string, BaseCompleter>;
  session: Ace.EditSession | null = null;
  position: Ace.Point | null = null;
  context: string = '';

  constructor(completionStrings: string[], KEYWORDS: string[], NAME: string) {
    super(completionStrings, KEYWORDS, NAME);
    this.completers = {};
    this.completers.$Context_COMPLETION = new BaseCompleter(JS_$Context_COMPLETIONS, ['$context.'], '$context');
    this.completers.$ParseTree_COMPLETION = new BaseCompleter(JS_$ParseTree_COMPLETIONS, ['$parseTree.'], '$parseTree');
    this.completers.$Request_COMPLETION = new BaseCompleter(JS_$Request_COMPLETIONS, ['$request.'], '$request');
    this.completers.$Analytic_COMPLETION = new BaseCompleter(JS_$Analytics_COMPLETIONS, ['$analytics.'], '$analytics');
    this.completers.$Caila_COMPLETION = new BaseCompleter(JS_$Caila_COMPLETIONS, ['$caila.'], '$caila');
    this.completers.$Dialer_COMPLETION = new BaseCompleter(JS_$Dialer_COMPLETIONS, ['$dialer.'], '$dialer');
    this.completers.$Faq_COMPLETION = new BaseCompleter(JS_$Faq_COMPLETIONS, ['$faq.'], '$faq');
    this.completers.$Gpt_COMPLETION = new BaseCompleter(JS_$Gpt_COMPLETIONS, ['$gpt.'], '$gpt');
    this.completers.$Imputer_COMPLETION = new BaseCompleter(JS_$Imputer_COMPLETIONS, ['$imputer.'], '$imputer');
    this.completers.$Integration_COMPLETION = new BaseCompleter(
      JS_$Integration_COMPLETIONS,
      ['$integration.'],
      '$integration'
    );
    this.completers.$JsApi_COMPLETION = new BaseCompleter(JS_$JsApi_COMPLETIONS, ['$jsapi.'], '$jsapi');
    this.completers.$Mail_COMPLETION = new BaseCompleter(JS_$Mail_COMPLETIONS, ['$mail.'], '$mail');
    this.completers.$Nlp_COMPLETION = new BaseCompleter(JS_$Nlp_COMPLETIONS, ['$nlp.'], '$nlp');
    this.completers.$Pushgate_COMPLETION = new BaseCompleter(JS_$Pushgate_COMPLETIONS, ['$pushgate.'], '$pushgate');
    this.completers.$Reactions_COMPLETION = new BaseCompleter(JS_$Reactions_COMPLETIONS, ['$reactions.'], '$reactions');
    this.completers.$aimychat_COMPLETION = new BaseCompleter(JS_$aimychat_COMPLETIONS, ['$aimychat.'], '$aimychat');
    this.completers.$textCampaign_COMPLETION = new BaseCompleter(
      JS_$textCampaign_COMPLETIONS,
      ['$textCampaign.'],
      '$textCampaign'
    );
    this.completers.$Http_COMPLETION = new BaseCompleter(JS_$Http_COMPLETIONS, ['$http.'], '$http');
    this.completers.$ConversationApi_COMPLETIONS = new BaseCompleter(
      JS_$conversationApi_COMPLETIONS,
      ['$conversationApi.'],
      ''
    );
    this.completers.$Request_userFrom_COMPLETION = new BaseCompleter(
      JS_$Request_userFrom_COMPLETIONS,
      ['$request.userFrom'],
      '$request.userFrom'
    );
    this.completers.$Request_data_COMPLETION = new BaseCompleter(
      JS_$Request_data_COMPLETIONS,
      ['$request.data'],
      '$request.data'
    );
    this.completers.$Response_replies_push_COMPLETION = new BaseCompleter(
      JS_$Response_replies_push_COMPLETIONS,
      ['$response.replies.push('],
      '$response.replies'
    );

    if (NAME === 'es6') {
      this.completers = Object.fromEntries(
        Object.entries(this.completers).filter(([key]) => !ES6_UNSUPPORTED_BUILTINS_REGEX.test(key))
      );
    }

    for (let completer of Object.values(this.completers)) {
      completer.getCompletions = function (value) {
        return this.completionStrings
          .filter(completionString => completionString.includes(value) && completionString !== value)
          .reverse()
          .map((completionString, index) => {
            (this.KEYWORDS || []).forEach(keyword => {
              completionString.replace(keyword, '');
            });
            return {
              value: completionString,
              name: completionString,
              score: 10000 + index,
              meta: this.NAME,
              completer: {
                insertMatch: function (insertEditor, data) {
                  let insertValue = data.value.replace(value, '');
                  const cursorPosition = insertEditor.getCursorPosition();
                  insertEditor.session.insert(cursorPosition, insertValue);
                },
              },
            };
          });
      };
      completer.hasCompletions = function (tokens) {
        return this.completionStrings.some(
          completionString =>
            completionString.startsWith(tokens[tokens.length - 1]) && completionString !== tokens[tokens.length - 1]
        );
      };
      completer.isApplicable = function (tokens) {
        if (tokens[tokens.length - 1].length === 0) return false;

        return this.KEYWORDS.length > 0
          ? this.KEYWORDS.includes(tokens[tokens.length - 1]) || this.hasCompletions(tokens)
          : this.hasCompletions(tokens);
      };
    }
  }

  setSessionAndPoint = (session: Ace.EditSession, position: Ace.Point) => {
    this.session = session;
    this.position = position;
  };

  getCompletions = (value: string): CompletionType => {
    return this.completers[this.context].getCompletions(value);
  };

  isApplicable = (tokens: string[]) => {
    for (let [name, completer] of Object.entries(this.completers)) {
      if (completer.isApplicable(tokens)) {
        this.context = name;
        break;
      }
    }
    return Boolean(this.context);
  };
}
